import SmartCityApi from './../../services/SmartCityApi';
import L from 'leaflet';
import BaseParams from './../../services/BaseParams';
import CityMapLogic from './../citymap/CityMapLogic';

class ProblemsLogic {

    app = null;

    constructor(_app) {
        this.app = _app;

        SmartCityApi.mapStyles()
            .then(response => {
                this.mapStyles = response.styles;
            });
    }

    updateShowingMarkers = (problemsList) => {        
        SmartCityApi.problemsCategories()
            .then(response => {

                let categories = {};
                for (let rawCategory of response.categories) {
                    categories[rawCategory.id] = rawCategory;
                }
                let points = [];

                for (let problem of problemsList) {

                    let styleName = categories[problem.category].styles[problem.status];
                    let style = this.mapStyles[styleName];

                    let object = {
                        id: problem.objectId,
                        name: problem.placeLabel,
                        text: problem.text,
                        links: [],
                        images: problem.images,
                        audios: [],
                        videos: [],
                        reestrId: problem.id,
                        style: style,
                        geometry: problem.place
                    }

                    if ((style) && (style.type === "Point")) {
                        if (!style.leafletIcon) {
                            if (style.icon.needPlate) {
                                style.leafletIcon = new L.Icon({
                                    iconUrl: BaseParams.STYLE_PREVIEW_URL + '?styleName=' + style.id,
                                    iconAnchor: new L.Point(12, 30),
                                    iconSize: new L.Point(27, 32)
                                });
                            }
                            else {
                                style.leafletIcon = new L.Icon({
                                    iconUrl: style.icon.url,
                                    iconAnchor: new L.Point(-style.icon.left, -style.icon.top),
                                    iconSize: new L.Point(style.icon.width, style.icon.height),
                                });
                            }
                        }
                        object.leafletIcon = style.leafletIcon;
                    }

                    if ((style) && (problem.place.type === "Point")) {
                        points.push(object);
                    }
                }

                this.app.setState({
                    points: points,
                    lines: [],
                    polygons: [],
                    progressVisible: false
                });
            });
    }

    showProblemMarker = (problem) => {
        this.app.setState({
            mapPosition: [problem.geometry.coordinates[1], problem.geometry.coordinates[0]],
            mapZoom: 16
        });
    }

    handleMapPress = (event, foundMarkerFunction) => {
        this.app.setState({
            problems_selectedMarker: {
                latitude: event.latlng.lat,
                longitude: event.latlng.lng
            },
            problems_selectedMarkerAddress: "определяется место..."
        });

        SmartCityApi.universalPointSearch([event.latlng.lng, event.latlng.lat])
            .then(response => {
                if (response.results && (response.results.length > 0)) {
                    let address = response.results[0].name;
                    if (response.results[0].type === "building") {
                        address = response.results[0].name;
                    }
                    if (response.results[0].type === "transportStop") {
                        address = "остановка " + response.results[0].name;
                    }

                    //this.mapServices.navigateToPoint(response.results[0].place, -0.2);
                    this.app.setState({
                        problems_selectedMarkerAddress: address,
                        mapPosition: [response.results[0].place.coordinates[1], response.results[0].place.coordinates[0]]
                    });

                    let mainText = "";
                    let subText = "";
                    if (response.results[0].type === "building") {
                        mainText = response.results[0].name;
                        subText = response.results[0].text;
                    }
                    if (response.results[0].type === "transportStop") {
                        let parts = response.results[0].name.split(" на ");
                        mainText = parts[0];
                        subText = parts[1] || "";
                    }
                    subText = subText.replace('<br/>', ' ').replace('<br>', ' ');

                    let item = {
                        id: response.results[0].id,
                        name: mainText,
                        text: subText,
                        type: response.results[0].type,
                        geometry: response.results[0].place
                    };

                    if (!item.style) {
                        let style = this.mapStyles['simplepoint'];
                        if (style) item.style = style;
                        if (!item.style) return;

                        item = CityMapLogic.createStyleLeafletIcon(style, item);
                    }

                    this.app.setState({
                        foundMarker: item,
                        progressVisible: false
                    });
                }
                else {
                    this.app.setState({
                        problems_selectedMarkerAddress: "адрес не определен"
                    });
                }
            });
    }


}

export default ProblemsLogic;