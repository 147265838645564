import React from 'react';

import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";
import { createHashHistory } from 'history';
import { Map, Marker, Polygon, Polyline, Popup, TileLayer, Tooltip } from 'react-leaflet';
import L, { layerGroup } from 'leaflet';
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import firebase from 'firebase/app';
import 'firebase/messaging';

import BaseParams from './services/BaseParams';
import RotatedMarker from './screens/transport/RotatedMarker';
import SmartCityApi from './services/SmartCityApi';

import MapServices from './services/MapServices';

import PhotoGallery from './components/PhotoGallery';
import PreloaderMini from './components/PreloaderMini';

import HomeScreen from './screens/HomeScreen';
import TransportHomeScreen from './screens/transport/TransportHomeScreen';
import CityMapLayersListScreen from './screens/citymap/CityMapLayersListScreen';
import ProblemsHomeScreen from './screens/problems/ProblemsHomeScreen';
import ProblemsAddNewStep1CategoriesScreen from './screens/problems/ProblemsAddNewStep1CategoriesScreen';
import ProblemsAddNewStep2PlaceScreen from './screens/problems/ProblemsAddNewStep2PlaceScreen';
import ProblemsAddNewStep3PhotosScreen from './screens/problems/ProblemsAddNewStep3PhotosScreen';
import ProblemsAddNewStep4CommentsScreen from './screens/problems/ProblemsAddNewStep4CommentsScreen';
import ProblemsAddNewStep5CheckScreen from './screens/problems/ProblemsAddNewStep5CheckScreen';
import ProblemsAddNewStep6ReadyScreen from './screens/problems/ProblemsAddNewStep6ReadyScreen';
import ReminderSubscriptionsListScreen from './screens/reminder/ReminderSubscriptionsListScreen';
import commonStyles from './screens/Common.styles';

import VotingHomeScreen from './screens/voting/VotingHomeScreen';

import TransportLogic from './screens/transport/TransportLogic';
import CityMapLogic from './screens/citymap/CityMapLogic';
import ProblemsLogic from './screens/problems/ProblemsLogic';
import ReminderLogic from './screens/reminder/ReminderLogic';



var history = createHashHistory();

class App extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      mapPosition: null,
      mapZoom: null,
      menuSector: null,
      points: [],
      lines: [],
      polygons: [],
      wmsLayers: [],
      defaultWmsLayers: [],
      cityMapPlaceInfo: null,
      cityMapPlaceMessages: null,

      problems_defaultTab: null,
      problems_category: null,
      problems_selectedCategory: null,
      problems_place: null,
      problems_selectedMarker: null,
      problems_selectedMarkerAddress: null,
      problems_selectedPhotos: null,
      problems_comment: null,
      problems_contacts: null,
      problems_audio: null,

      transport_submode: TransportLogic.BASE_MODE,
      transport_selectedStop: null,
      transport_stops: [],
      transport_stopForecasts: [],
      transport_selectedTransport: null,
      transport_transports: [],
      transport_transportForecasts: [],
      transport_routeGeometry: [],

      reminder_subscribes: [],
      reminder_selectedSubscribe: null,

      foundResults: null,
      foundMarker: null,
      currentMapRegion: null,
      progressVisible: false
    }

    this.mapServices = new MapServices(this);
    this.transportLogic = new TransportLogic(this);
    this.cityMapLogic = new CityMapLogic(this, this.mapServices);
    this.problemsLogic = new ProblemsLogic(this);
    this.reminderLogic = new ReminderLogic(this);

    SmartCityApi.mapStyles()
      .then(response => {
        this.mapStyles = response.styles;
      });

    var firebaseConfig = {
      apiKey: "AIzaSyA5NSXmNz9_qXYf5ooC7Vg3E_Fgp-P20Ug",
      authDomain: "gosamara-mgis.firebaseapp.com",
      projectId: "gosamara-mgis",
      storageBucket: "gosamara-mgis.appspot.com",
      messagingSenderId: "1098005770746",
      appId: "1:1098005770746:web:bf391d804a307dd56b7443",
      measurementId: "G-X1D3LP8TW1"
    };

    try {
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      } else {
        firebase.app();
      }

      const messaging = firebase.messaging();
      this.getToken(messaging, (token) => {
        SmartCityApi.deviceId = token;
      });
    }
    catch (e) {
      console.log("Браузер не поддерживает пуши");
    }

    SmartCityApi.performGetUsername((result) => {
      let topMenu = document.getElementById("topMenu");
      if ((result) && (result.status === "statusOk") && (result.authorized)) {
        topMenu.innerHTML = '<li class="list__item"><a class="list__link" href="/manual/">Справка</a></li>' +
          '<li class="list__item login"><a href="/user/" class="list__link">Кабинет (' + (result.nickname || result.username) + ')</a></li>' +
          '<li class="list__item login logged"><a href="/auth/logout" class="list__link">Выйти</a></li>';
      }
      else {
        topMenu.innerHTML = '<li class="list__item"><a class="list__link" href="/manual/">Справка</a></li>' +
          '<li class="list__item login"><a href="/user/?returnTo=' + window.location.pathname + window.location.hash + '" class="list__link">Войти</a></li>';
      }
    });

  }


  getToken = (messaging, getTokenCompleted) => {
    return messaging.getToken({ vapidKey: 'BCS_bZr1uxfFE3sIKsrmU5e5wL9MAjZvhGRateCwXiuEhoozc2MgtysILGXTsMWLAGKQhDDRSeWkYlbibQxh8Zk' }).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        getTokenCompleted(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }


  componentWillMount() {

    SmartCityApi.commonSettings(false).then(response => {
      this.commonSettings = response.settings;
      let zoomLevel = this.mapServices.initialRegion.zoomLevel;
      this.mapServices.initialRegion = this.commonSettings.global.initialRegion;
      this.mapServices.initialRegion.zoomLevel = zoomLevel;
    });

    SmartCityApi.mapSources().then(response => {
      let defaultWmsLayers = [];
      response.sources.forEach((layer) => {
        if (layer.type == 'WMS') {
          defaultWmsLayers.push(layer);
        }
      });
      this.setState({
        defaultWmsLayers: defaultWmsLayers
      });
    });


    this.transportLogic.checkForViewportChanged(null);

    this.selectMenuItemByPath(window.location.hash.replace("#/", ""));
  }


  componentDidMount() {
    if (!this.state.menuSector) {
      this.cityMapLogic.turnDefaultLayers(["SM_26_98"]);
    }

    this.setState({
      isMobile: isMobile,
      platform: (isAndroid ? "android" : (isIOS ? "ios" : "other"))
    });
  }

  universalFoundMarker = (item) => {

    if (!item.style) {
      let style = this.mapStyles['simplepoint'];
      if (style) item.style = style;
      if (!item.style) return;

      item = CityMapLogic.createStyleLeafletIcon(style, item);
    }

    this.setState({
      foundMarker: item,
      mapPosition: [item.geometry.coordinates[1], item.geometry.coordinates[0]]
    });

    this.handleMarkerPress(item);
  }







  getLeafletGeometry(geometry, type) {
    if ((geometry.length === 1) && (type === "Point")) {
      return [geometry[0].latitude, geometry[0].longitude];
    }
    else {
      let result = [];
      for (let point of geometry) {
        result.push([point.latitude, point.longitude]);
      }
      return result;
    }
  }





  handleMapPress = (event) => {

    if (this.state.mapMode === "problemsSelectPlace") {
      this.problemsLogic.handleMapPress(event, this.universalFoundMarker);
    }
    else if (this.state.mapMode === "transport") {
      if ((this.state.transport_submode === TransportLogic.TRANSPORT_MODE) || (this.state.transport_submode === TransportLogic.STOP_MODE)) {
        this.transportLogic.startBaseMode();
      }
    }
    else {
      if (this.state.foundMarker || this.state.cityMapPlaceInfo || this.state.reminder_selectedSubscribe) {
        this.setState({
          cityMapPlaceInfo: null,
          foundResults: null,
          foundMarker: null,
          reminder_selectedSubscribe: null
        });
      }
    }
  }

  handleMapDoublePress = (event) => {
    if ((this.state.menuSector === "citymap") || (this.state.menuSector === "reminder")) {
      this.cityMapLogic.coordinateSearch([event.latlng.lng, event.latlng.lat], ((this.state.menuSector === "reminder") ? ["building"] : SmartCityApi.ALL_SEARCH_TYPES));
    }
  }

  handleMarkerPress = (item) => {
    if (this.state.menuSector === "reminder") {
      this.reminderLogic.handleMarkerPress(item);
    }
    else
      if ((this.state.menuSector === "citymap") || (!this.state.menuSector)) {
        this.cityMapLogic.markerPress(item);
      } else
        if (this.state.menuSector === "problems") {
          if (this.state.mapMode === "problemsSelectPlace") {
            this.problemsLogic.handleMapPress({
              latlng: {
                lat: item.place[0],
                lng: item.place[1]
              }
            });
          }
        }
  }

  selectMenuItemByPath = (path) => {
    let sector = null;
    let mapMode = null;
    let problemsPlace = null;

    if (path === "transport") {
      sector = "transport";
      mapMode = "transport";
    }
    if (path === "citymap") {
      sector = "citymap";
    }
    if (path === "problems") {
      sector = "problems";
    }
    if (path === "problems_1") {
      sector = "problems";
    }
    if (path === "problems_2") {
      sector = "problems";
      mapMode = "problemsSelectPlace";
      problemsPlace = this.state.problemsPlace;
    }
    if (path === "problems_3") {
      sector = "problems";
      problemsPlace = this.state.problemsPlace;
    }
    if (path === "problems_4") {
      sector = "problems";
      problemsPlace = this.state.problemsPlace;
    }
    if (path === "problems_5") {
      sector = "problems";
      problemsPlace = this.state.problemsPlace;
    }
    if (path === "problems_6") {
      sector = "problems";
      problemsPlace = this.state.problemsPlace;
    }
    if (path === "scouting") {
      sector = "scouting";
    }
    if (path === "reminder") {
      sector = "reminder";
    }
    if (path === "voting") {
      sector = "voting";
    }

    this.setState({
      menuSector: sector,
      mapMode: mapMode,
      points: [],
      lines: [],
      polygons: [],
      cityMapPlaceInfo: null,
      foundMarker: null,
      problemsPlace: problemsPlace,
      reminder_subscribes: []
    }, () => {
      this.transportLogic.checkForViewportChanged(null);
    });
  }


  navigateTo = (path, params = null) => {
    if (path === "BACK") {
      history.goBack();
    }
    else {
      if (path === "") {
        this.setState({
          menuSector: null,
          mapMode: null,
          points: [],
          lines: [],
          polygons: [],
          cityMapPlaceInfo: null,
          foundMarker: null,
          problemsPlace: null,
          reminder_subscribes: []
        });
      }
      else {
        this.selectMenuItemByPath(path);
      }
      if (params) {
        this.setState(params, () => history.push(path));
      }
      else {
        history.push(path);
      }
    }
  }


  render() {

    var placeInfoShortText = "";
    var placeInfoSemantics = null;

    if (this.state.cityMapPlaceInfo) {

      placeInfoShortText = (this.state.cityMapPlaceInfo || "").text.replace('<br/>', ' ').replace('<br>', ' ');

      if (this.state.cityMapPlaceInfo.semanticValues && this.state.cityMapPlaceInfo.fields && this.state.cityMapPlaceInfo.refBooks) {
        placeInfoSemantics = [];

        for (let field of this.state.cityMapPlaceInfo.fields) {
          let value = this.state.cityMapPlaceInfo.semanticValues[field.id];

          if (field.type === "refBook") {
            let refBook = this.state.cityMapPlaceInfo.refBooks[field.refBook];
            let refValue = null;
            for (let refRecords of refBook) {
              if (refRecords.id === value) {
                refValue = refRecords.value;
              }
            }
            value = refValue;
          }
          if (field.type == "datetime") {
            value = ((value && (value.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(value)) : "неизвестно");
          }
          placeInfoSemantics.push({
            "id": field.id,
            "label": field.label,
            "value": value || "",
            "isLink": (value ? (String(value).substr(0, 4) === "http") : false)
          });
        }
      }
    }


    let reminderSelectedObject = null;
    if (this.state.menuSector === "reminder") {
      if (this.state.reminder_selectedSubscribe) {
        reminderSelectedObject = Object.assign({}, this.state.reminder_selectedSubscribe);
        reminderSelectedObject.subscripted = true;
      }
      else
        if (this.state.foundMarker) {
          reminderSelectedObject = Object.assign({}, this.state.foundMarker);
          reminderSelectedObject.subscripted = false;
        }
    }

    let cityMapPlacePreparedMessages = null;
    if (this.state.cityMapPlaceMessages) {
      cityMapPlacePreparedMessages = [];

      this.state.cityMapPlaceMessages.map(item => {
        item.preparedDateStart = ((item.dateStart && (item.dateStart.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateStart)) : "неизвестно");
        item.preparedDateEnd = ((item.dateEnd && (item.dateEnd.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateEnd)) : "неизвестно");
        cityMapPlacePreparedMessages.push(item);
        return null;
      });
    }



    let wmsLayers = this.state.wmsLayers;
    if (wmsLayers.length == 0) {
      wmsLayers = this.state.defaultWmsLayers;
    }
    for (let i = 0; i < wmsLayers.length; i++) {
      wmsLayers[i].layers = wmsLayers[i].layers || wmsLayers[i].id;
      wmsLayers[i].url = wmsLayers[i].url || wmsLayers[i].source;
      wmsLayers[i].format = wmsLayers[i].format || "png";
      wmsLayers[i].transparent = wmsLayers[i].transparent || "true";
    }

    return (
      <HashRouter history={history} hashType={"noslash"}>
        {(this.state.isMobile) &&
          <div style={styles.mobileStub}>
            {((this.state.platform === "android") || (this.state.platform === "ios")) &&
              <div>
                <p style={styles.mobileStubHeader}>Установите мобильное приложение для {((this.state.platform === "android") ? "Android" : ((this.state.platform === "ios") ? "iOS" : "..."))}</p>
                {(this.state.platform === "android") &&
                  <a style={styles.mobileStubInstallButton} href={"https://play.google.com/store/apps/details?id=ru.samadm.map"} target="_blank" rel="noopener noreferrer">
                    <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_images/installAndroid.png`} />
                  </a>
                }
                {(this.state.platform === "ios") &&
                  <a style={styles.mobileStubInstallButton} href={"https://apps.apple.com/ru/app/%D1%81%D0%B0%D0%BC%D0%B0%D1%80a/id1495019057"} target="_blank" rel="noopener noreferrer">
                    <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_images/installIos.png`} />
                  </a>
                }
                <p style={styles.mobileStubSubHeader}>Оно умеет всё то же самое, что и сайт, и даже больше.</p>
                <p style={styles.mobileStubHint}>А сайт удобнее на больших экранах.</p>
                <div style={styles.mobileStubLinkBlock}>
                  <span style={styles.mobileStubLink} onClick={() => { this.setState({ isMobile: false }); }}>Понятно, но всё равно откройте сайт.</span>
                </div>
              </div>
            }
          </div>
        }
        {(!this.state.isMobile) &&
          <div style={styles.fullArea}>
            {this.state.menuSector &&
              <div style={styles.leftMenu}>
                <div onClick={(e) => this.navigateTo("")} style={styles.firstMenuItemIconPlate}>
                  <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_TopMenuIcon.svg`} />
                </div>
                {this.commonSettings && this.commonSettings.transport && this.commonSettings.transport.enabled &&
                  <div onClick={(e) => this.navigateTo("transport")} style={(this.state.menuSector === "transport" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_TransportIcon` + (this.state.menuSector === "transport" ? "_Black" : "") + '.svg'} />
                  </div>
                }
                {this.commonSettings && this.commonSettings.citymap && this.commonSettings.citymap.enabled &&
                  <div onClick={(e) => this.navigateTo("citymap")} style={(this.state.menuSector === "citymap" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_CityMapIcon` + (this.state.menuSector === "citymap" ? "_Black" : "") + '.svg'} />
                  </div>
                }
                {(true || (this.commonSettings && this.commonSettings.problems && this.commonSettings.problems.enabled)) &&
                  <div onClick={(e) => this.navigateTo("problems")} style={(this.state.menuSector === "problems" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ProblemsIcon` + (this.state.menuSector === "problems" ? "_Black" : "") + '.svg'} />
                  </div>
                }
                {false && this.commonSettings && this.commonSettings.scouting && this.commonSettings.scouting.enabled &&
                  <div onClick={(e) => this.navigateTo("#scouting")} style={(this.state.menuSector === "scouting" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ScoutingIcon` + (this.state.menuSector === "scouting" ? "_Black" : "") + '.svg'} />
                  </div>
                }
                {this.commonSettings && this.commonSettings.reminder && this.commonSettings.reminder.enabled &&
                  <div onClick={(e) => this.navigateTo("reminder")} style={(this.state.menuSector === "reminder" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ReminderIcon` + (this.state.menuSector === "reminder" ? "_Black" : "") + '.svg'} />
                  </div>
                }
                {this.commonSettings && this.commonSettings.voting && this.commonSettings.voting.enabled &&
                  <div onClick={(e) => this.navigateTo("voting")} style={(this.state.menuSector === "voting" ? styles.menuItemIconPlate_selected : styles.menuItemIconPlate)}>
                    <img alt="" style={styles.menuItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_VotingIcon` + (this.state.menuSector === "voting" ? "_Black" : "") + '.svg'} />
                  </div>
                }
              </div>
            }
            <div style={styles.leftColumn}>
              <Switch>
                <Route path="*citymap">
                  <CityMapLayersListScreen
                    updateLayersFunction={this.cityMapLogic.updateLayers}
                    foundMarkerFunction={this.universalFoundMarker}
                    placeInfo={this.state.cityMapPlaceInfo}
                    mapServices={this.mapServices}
                    defaultLayers={this.cityMapLogic.layers}
                  />
                </Route>
                <Route path="*problems">
                  <ProblemsHomeScreen
                    updateShowingMarkers={this.problemsLogic.updateShowingMarkers}
                    showProblemMarker={this.problemsLogic.showProblemMarker}
                    mapServices={this.mapServices}
                    navigateTo={this.navigateTo}
                    defaultTab={this.state.problems_defaultTab}
                    selectedCategory={this.state.problems_selectedCategory}
                  />
                </Route>
                <Route path="*problems_1">
                  <ProblemsAddNewStep1CategoriesScreen
                    navigateTo={this.navigateTo}
                  />
                </Route>
                <Route path="*problems_2">
                  <ProblemsAddNewStep2PlaceScreen
                    navigateTo={this.navigateTo}
                    category={this.state.problems_category}
                    place={this.state.problems_place}
                    selectedMarker={this.state.problems_selectedMarker}
                    selectedMarkerAddress={this.state.problems_selectedMarkerAddress}
                    foundMarkerFunction={this.universalFoundMarker}
                  />
                </Route>
                <Route path="*problems_3">
                  <ProblemsAddNewStep3PhotosScreen
                    navigateTo={this.navigateTo}
                    category={this.state.problems_category}
                    place={this.state.problems_place}
                    selectedPhotos={this.state.problems_selectedPhotos}
                  />
                </Route>
                <Route path="*problems_4">
                  <ProblemsAddNewStep4CommentsScreen
                    navigateTo={this.navigateTo}
                    category={this.state.problems_category}
                    place={this.state.problems_place}
                    selectedPhotos={this.state.problems_selectedPhotos}
                  />
                </Route>
                <Route path="*problems_5">
                  <ProblemsAddNewStep5CheckScreen
                    navigateTo={this.navigateTo}
                    category={this.state.problems_category}
                    place={this.state.problems_place}
                    selectedPhotos={this.state.problems_selectedPhotos}
                    comment={this.state.problems_comment}
                    contacts={this.state.problems_contacts}
                    audio={this.state.problems_audio}
                  />
                </Route>
                <Route path="*problems_6">
                  <ProblemsAddNewStep6ReadyScreen
                    navigateTo={this.navigateTo}
                  />
                </Route>
                <Route path="*transport">
                  <TransportHomeScreen
                    submode={this.state.transport_submode}
                    startBaseMode={this.transportLogic.startBaseMode}
                    startStopMode={this.transportLogic.startStopMode}
                    startTransportMode={this.transportLogic.startTransportMode}
                    startRouteMode={this.transportLogic.startRouteMode}
                    stop={this.state.transport_selectedStop}
                    stopForecasts={this.state.transport_stopForecasts}
                    transport={this.state.transport_selectedTransport}
                    transportForecasts={this.state.transport_transportForecasts}
                    routeInfo={this.state.transport_routeInfo}
                    routeGeometry={this.state.transport_routeGeometry}
                    transportLogic={this.transportLogic}
                  />
                </Route>
                <Route path="*reminder">
                  <ReminderSubscriptionsListScreen
                    navigateTo={this.navigateTo}
                    updateShowingSubscriptions={this.reminderLogic.updateShowingSubscriptions}
                    foundMarkerFunction={this.universalFoundMarker}
                    foundMarker={this.state.foundMarker}
                    placeInfo={this.state.cityMapPlaceInfo}
                    showSubscriptionMarker={this.reminderLogic.showSubscriptionMarker}
                  />
                </Route>
                <Route path="*voting">
                  <VotingHomeScreen
                    navigateTo={this.navigateTo}
                  />
                </Route>
                <Route>
                  <HomeScreen
                    navigateTo={this.navigateTo}
                    updateLayersFunction={this.cityMapLogic.updateLayers}
                    foundMarkerFunction={this.universalFoundMarker}
                    transportLogic={this.transportLogic}
                    startStopMode={this.transportLogic.startStopMode}
                    startRouteMode={this.transportLogic.startRouteMode}
                    commonSettings={this.commonSettings}
                  />
                </Route>
              </Switch>


              {this.state.cityMapPlaceInfo &&

                <div style={commonStyles.fullFloatingWhiteBack}>
                  <div
                    onClick={(e) => this.setState({
                      cityMapPlaceInfo: null
                    })}>
                    <img alt="" style={styles.closePlaceButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                  </div>
                  <div >
                    <p style={styles.placeInfoHeaderTextInFull}>{this.state.cityMapPlaceInfo.name}</p>
                    <div style={styles.placeInfoScrollBox}>

                      <p style={styles.placeInfoDescriptionText} dangerouslySetInnerHTML={{ __html: placeInfoShortText }}></p>
                      {this.state.cityMapPlaceInfo.images && (this.state.cityMapPlaceInfo.images.length > 0) &&
                        <PhotoGallery
                          style={styles.photoGallery}
                          photoWidth={'100px'}
                          photoHeight={'100px'}
                          images={this.state.cityMapPlaceInfo.images}
                        ></PhotoGallery>
                      }
                      {placeInfoSemantics &&
                        <div>
                          <div style={styles.grayWideSplitterLine}></div>
                          <p style={styles.placeInfoSubHeaderText}>Подробности</p>
                          {placeInfoSemantics.map(item => (
                            <div key={item.id} style={styles.placeInfoExtraSemanticsRow}>
                              <p style={styles.placeInfoExtraSemanticsLabel}>{item.label}</p>
                              {item.isLink &&
                                <p onClick={() => { window.open(item.value, '_blank').focus(); }} style={styles.placeInfoExtraSemanticsValueLink}>{item.value}</p>
                              }
                              {!item.isLink &&
                                <p style={styles.placeInfoExtraSemanticsValue}>{item.value}</p>
                              }
                              <div style={{ clear: "left" }}></div>
                            </div>
                          ))}
                        </div>
                      }

                      {(this.state.localInfoServices) && (this.state.localInfoServices.length > 0) && (this.state.localInfoServices.map(service => {
                        if ((service.results) && (service.results.length > 0)) {
                          if (service.service == "reminderMessages") {
                            return (
                              <div key={service.label}>
                                <div style={styles.grayWideSplitterLine}></div>
                                <p style={styles.placeInfoSubHeaderText}>Уведомления о событиях</p>
                                {service.results.map(item => (
                                  <div key={item.id} style={styles.commonTabProblemsListItem}>
                                    <div >
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoFullRowSubHeader}>{item.category + ": " + item.type}</p>
                                      </div>
                                      <div style={{ clear: "left" }}></div>
                                    </div>
                                    <div >
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoFullRowValue}>{item.text}</p>
                                      </div>
                                      <div style={{ clear: "left" }}></div>
                                    </div>
                                    <div >
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoExtraSemanticsLabel}>Дата создания</p>
                                      </div>
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoExtraSemanticsValue}>{((item.dateStart && (item.dateStart.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateStart)) : "неизвестно")}</p>
                                      </div>
                                      <div style={{ clear: "left" }}></div>
                                    </div>
                                    <div >
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoExtraSemanticsLabel}>Дата завершения</p>
                                      </div>
                                      <div style={styles.placeInfoExtraSemanticsRow}>
                                        <p style={styles.placeInfoExtraSemanticsValue}>{((item.dateEnd && (item.dateEnd.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateEnd)) : "неизвестно")}</p>
                                      </div>
                                      <div style={{ clear: "left" }}></div>
                                    </div>

                                  </div>
                                ))}
                              </div>
                            )
                          } else {
                            return (
                              <div key={service.label}>
                                <div style={styles.grayWideSplitterLine}></div>
                                <p style={styles.placeInfoSubHeaderText}>{service.label}</p>
                                {service.results.map(item =>
                                  <div key={item.id}>
                                    <div style={styles.placeInfoExtraSemanticsRow}>
                                      <p style={styles.placeInfoFullRowValue}>{item.name}</p>
                                      <p style={styles.placeInfoFullRowSubText} dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                    </div>
                                    <div style={{ clear: "left" }}></div>
                                  </div>
                                )}
                              </div>
                            )
                          }
                        }
                      }))}


                    </div>
                  </div>
                </div>


              }


              {reminderSelectedObject &&

                <div style={commonStyles.fullFloatingWhiteBack}>
                  <div
                    onClick={(e) => this.setState({
                      reminder_selectedSubscribe: null,
                      foundMarker: null
                    })}>
                    <img alt="" style={styles.closePlaceButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                  </div>
                  <div style={{ flex: 1 }}>
                    <p style={styles.placeInfoHeaderTextInFull}>{reminderSelectedObject.name}</p>
                    <p style={styles.placeInfoDescriptionText} dangerouslySetInnerHTML={{ __html: reminderSelectedObject.text }}></p>

                    {cityMapPlacePreparedMessages && cityMapPlacePreparedMessages.length > 0 &&
                      <div style={styles.reminderMessagesScrollBlock}>
                        <p style={styles.placeInfoSubHeaderText}>Уведомления о событиях</p>
                        {cityMapPlacePreparedMessages.map(item => (
                          <div key={item.id} style={styles.commonTabProblemsListItem}>
                            <div >
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoFullRowSubHeader}>{item.category + ": " + item.type}</p>
                              </div>
                              <div style={{ clear: "left" }}></div>
                            </div>
                            <div >
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoFullRowValue}>{item.text}</p>
                              </div>
                              <div style={{ clear: "left" }}></div>
                            </div>
                            <div >
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoExtraSemanticsLabel}>Дата создания</p>
                              </div>
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoExtraSemanticsValue}>{item.preparedDateStart}</p>
                              </div>
                              <div style={{ clear: "left" }}></div>
                            </div>
                            <div >
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoExtraSemanticsLabel}>Дата завершения</p>
                              </div>
                              <div style={styles.placeInfoExtraSemanticsRow}>
                                <p style={styles.placeInfoExtraSemanticsValue}>{item.preparedDateEnd}</p>
                              </div>
                              <div style={{ clear: "left" }}></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    }

                    <div>
                      <div style={styles.grayWideSplitterLine}></div>

                      {reminderSelectedObject.subscripted &&
                        <div
                          onClick={() => { this.reminderLogic.removeSubscription(reminderSelectedObject); }}>
                          <div style={styles.nextStepTextButtonWhite}>
                            <p style={styles.nextStepTextButtonLabelDark}>Отписаться</p>
                          </div>
                        </div>
                      }
                      {!reminderSelectedObject.subscripted &&
                        <div
                          onClick={() => { this.reminderLogic.createSubscription(reminderSelectedObject); }}>
                          <div style={styles.nextStepTextButtonDark}>
                            <p style={styles.nextStepTextButtonLabelWhite}>Подписаться</p>
                          </div>
                        </div>
                      }
                    </div>



                  </div>
                </div>
              }

            </div>
            <div style={styles.rightColumn}>
              <Map
                center={this.state.mapPosition || [this.mapServices.initialRegion.latitude, this.mapServices.initialRegion.longitude]}
                zoom={this.state.mapZoom || this.mapServices.initialRegion.zoomLevel}
                onClick={this.handleMapPress}
                ondblclick={this.handleMapDoublePress}
                onViewportChanged={this.transportLogic.checkForViewportChanged}
                doubleClickZoom={false}
                ref={(map) => { this.mapServices.setMapView(map); }}
              >

                {wmsLayers.map((layer) => (
                  <TileLayer
                    url={BaseParams.TILES_TO_MERCATOR_URL + "?source=" + layer.url + "&layers=" + layer.layers + "&format=" + layer.format + "&transparent=" + layer.transparent + "&x={x}&y={y}&z={z}"}
                    attribution=""
                  />

                ))};


                {this.state.points.map(item => this.mapServices.getPoints(item, this.state.cityMapPlaceInfo, () => this.handleMarkerPress(item)))}

                {this.state.lines.map(item => this.mapServices.getLines(item, this.state.cityMapPlaceInfo, () => this.handleMarkerPress(item)))}

                {this.state.polygons.map(item => this.mapServices.getPolygons(item, this.state.cityMapPlaceInfo, () => this.handleMarkerPress(item)))}

                {/*this.state.points.map(item => (
                  <Marker
                    key={item.id}
                    position={this.getLeafletGeometry(item.geometry, item.style.type)}
                    icon={item.leafletIcon}
                    onClick={(e) => this.handleMarkerPress(item)}
                  >
                    <Popup autoPan={false}>
                      <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    </Popup>
                  </Marker>
                ))*/}

                {/*this.state.lines.map(item => (
                  <Polyline
                    key={item.id}
                    color={item.style.line.color}
                    positions={this.getLeafletGeometry(item.geometry, item.style.type)}
                    onClick={(e) => this.handleMarkerPress(item)}
                  >
                    <Popup autoPan={false}>
                      <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    </Popup>

                  </Polyline>

                  //  strokeColor={item.style.line.color}
                  //   strokeWidth={6/*item.style.line.width
                ))*/}

                {/*this.state.polygons.map(item => (
                  <Polygon
                    key={item.id}
                    color={item.style.line.color}
                    fillColor={item.style.fill.color}
                    fillOpacity={item.style.fill.alpha}
                    positions={this.getLeafletGeometry(item.geometry, item.style.type)}
                    onClick={(e) => this.handleMarkerPress(item)}
                  >
                    <Popup autoPan={false}>
                      <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    </Popup>
                  </Polygon>

                  //  strokeColor={item.style.line.color}
                  //  strokeWidth={item.style.line.width}
                  //   fillColor={item.style.fill.color}

                ))*/}


                {(this.state.mapMode === "transport") && this.state.transport_stops.map(item => (
                  <Marker
                    key={item.KS_ID[0]}
                    position={[item.location.latitude, item.location.longitude]}
                    icon={(this.state.transport_selectedStop) && (item.KS_ID === this.state.transport_selectedStop.KS_ID) ? item.leafletIconSelected : item.leafletIcon}
                    onClick={(e) => this.transportLogic.startStopMode(item)}
                  >
                    <Popup autoPan={false}>{item.title[0] + ' ' + item.adjacentStreet[0] + ' ' + item.direction[0]}</Popup>
                    <Tooltip direction='top' offset={[0, -10]} opacity={1}>
                      <span>{item.title[0] + ' ' + item.adjacentStreet[0] + ' ' + item.direction[0]}</span>
                    </Tooltip>
                  </Marker>
                ))}

                {(this.state.mapMode === "transport") && this.state.transport_transports.map(transport => (
                  <RotatedMarker
                    key={transport.hullNo}
                    position={[parseFloat(transport.latitude), parseFloat(transport.longitude)]}
                    title={transport.type + ' ' + transport.number /*+ ' | ' + transport.stateNumber*/ + (transport.modelTitle ? ' | ' + transport.modelTitle : "")}
                    description={transport.modelTitle /* + ' | ' + transport.stateNumber*/}
                    icon={transport.leafletIcon}
                    rotationAngle={90 - parseFloat(transport.direction)}
                    rotationOrigin={'center'}
                    flat={true}
                    onClick={(e) => {
                      this.transportLogic.startTransportMode(transport);
                    }}
                  >
                    {(transport.number) && (transport.number.length > 0) &&
                      <Tooltip direction='top' offset={[-12, 3]} opacity={1} permanent className={"transportTooltipClass"}>
                        <span>{transport.number}</span>
                      </Tooltip>
                    }
                  </RotatedMarker>
                ))}
                {(this.state.mapMode === "transport") && this.state.transport_routeGeometry && (this.state.transport_routeGeometry.length > 0) &&
                  <Polyline
                    key={"transportRouteGeometry"}
                    color={"#FF0000"}
                    positions={this.getLeafletGeometry(this.state.transport_routeGeometry, 'LineString')}
                  />
                }

                {this.state.foundMarker &&
                  this.mapServices.getPoints(this.state.foundMarker, null, () => this.handleMarkerPress(this.state.foundMarker))
                }
                {this.state.reminder_subscribes.map(item => (
                  <Marker
                    key={item.id}
                    position={[item.place.coordinates[1], item.place.coordinates[0]]}
                    icon={new L.Icon({
                      iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/Reminder_MapMarkerIcon.svg`,
                      iconAnchor: new L.Point(9, 30),
                      iconSize: new L.Point(18, 33),
                    })}
                    onClick={(e) => this.reminderLogic.handleMarkerPress(item)}
                  >
                    <Popup autoPan={false}>{item.name}</Popup>
                  </Marker>
                ))}

                {this.state.progressVisible &&
                  <PreloaderMini />
                }
              </Map>
            </div>
          </div>
        }
      </HashRouter>
    );
  }
}

export default App;




const styles = {

  fullArea: {
    display: "flex"
  },

  leftMenu: {
    flex: "0 0 58px",
    backgroundColor: "#0068A0",
    height: "calc(100vh - 70px)", // 70px -- height of the header
  },

  leftColumn: {
    flex: "340px",
    backgroundColor: "#DBECEC",
    height: "calc(100vh - 70px)", // 70px -- height of the header
    position: "relative"
  },

  rightColumn: {
    flex: "75%",
    backgroundColor: "#FFCCCC",
    height: "calc(100vh - 70px)", // 70px -- height of the header
  },

  firstMenuItemIconPlate: {
    float: "left",
    backgroundColor: "#0068A0",
    marginTop: '10px',
    marginBottom: '28px',
    height: '58px',
    width: '58px',
    cursor: 'pointer'
  },

  menuItemIconPlate: {
    float: "left",
    backgroundColor: "#0068A0",
    marginTop: '5px',
    height: '58px',
    width: '58px',
    cursor: 'pointer'
  },

  menuItemIconPlate_selected: {
    float: "left",
    backgroundColor: "#FFFFFF",
    marginTop: '5px',
    height: '58px',
    width: '58px',
  },

  menuItemIcon: {
    display: "block",
    height: '22px',
    width: '22px',
    marginLeft: "18px",
    marginTop: "18px"
  },

  reminderMessagesScrollBlock: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "140px",
    bottom: "80px",
    overflow: "scroll",
    overflowX: "hidden"
  },

  placeInfoScrollBox: {
    height: 'calc(100vh - 170px)',
    flex: 1,
    overflow: "scroll",
    overflowX: "hidden"
  },

  placeInfoHeaderTextInFull: {
    marginLeft: '27px',
    marginRight: '52px',
    marginTop: '17px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.2px',
  },

  placeInfoSubHeaderText: {
    marginLeft: '27px',
    marginRight: '27px',
    marginTop: '30px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
  },

  placeInfoDistanceText: {
    position: 'absolute',
    right: '15px',
    top: '20px',
    color: '#8C8C8C',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    textAlign: 'right'
  },

  placeInfoDescriptionText: {
    marginLeft: '27px',
    marginRight: '27px',
    marginTop: '19px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
  },

  photoGallery: {
    marginTop: '10px',
    marginLeft: '27px',
    width: '290px',
    height: '120px',
    backgroundColor: '#FFFFFF'
  },


  grayWideSplitterLine: {
    backgroundColor: '#F1F1F1',
    height: '5px',
    width: '100%',
    marginTop: '12px'
  },

  placeInfoExtraSemanticsRow: {
    marginLeft: '27px',
    marginRight: '27px',
    position: "relative"
  },

  placeInfoExtraSemanticsLabel: {
    float: "left",
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '12px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    width: '100px',
  },

  placeInfoExtraSemanticsValue: {
    float: "left",
    marginLeft: '10px',
    marginRight: '0px',
    marginTop: '12px',
    color: '#8C8C8C',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    textAlign: "right",
    width: '120px',
  },

  placeInfoExtraSemanticsValueLink: {
    float: "left",
    marginLeft: '10px',
    marginRight: '0px',
    marginTop: '12px',
    color: '#8C8C8C',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    textAlign: "right",
    width: '120px',
    cursor: 'pointer'
  },

  placeInfoFullRowValue: {
    float: "left",
    marginLeft: '10px',
    marginRight: '0px',
    marginTop: '12px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    textAlign: "left",
    //width: '100px',
  },

  placeInfoFullRowSubText: {
    float: "left",
    marginLeft: '10px',
    marginRight: '0px',
    marginTop: '12px',
    color: '#8C8C8C',
    fontFamily: 'SFProText-Regular',
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '-0.1px',
    textAlign: "left",
    //width: '100px',
  },

  placeInfoFullRowSubHeader: {
    float: "left",
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '12px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.1px',
    textAlign: "left",
    //width: '100px',
  },

  closePlaceButton: {
    position: 'absolute',
    width: "19px",
    height: "19px",
    top: "24px",
    right: "24px",
    cursor: 'pointer'
  },

  nextStepTextButtonWhite: {
    position: 'absolute',
    bottom: "22px",
    right: "13px",
    left: "13px",
    height: '40px',
    backgroundColor: '#DADADA',
    borderRadius: '20px',
    cursor: "pointer",
    textAlign: 'center',
  },

  nextStepTextButtonLabelDark: {
    paddingTop: '0px',
    marginTop: '10px',
    marginBottom: '21px',
    marginLeft: '16px',
    marginRight: '16px',
    textAlign: 'center',
    color: '#005687',
    fontFamily: 'SFProText-Regular',
    fontWeight: "bold",
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.2px',
  },

  nextStepTextButtonDark: {
    position: 'absolute',
    bottom: "22px",
    right: "13px",
    left: "13px",
    height: '40px',
    backgroundColor: '#005687',
    borderRadius: '20px',
    cursor: "pointer",
    textAlign: 'center',
  },

  nextStepTextButtonLabelWhite: {
    paddingTop: '0px',
    marginTop: '10px',
    marginBottom: '11px',
    marginLeft: '16px',
    marginRight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'SFProText-Regular',
    fontWeight: "bold",
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.2px',
  },

  mobileStub: {
    position: "absolute",
    left: 0,
    right: 0,
    top: '110px',
    bottom: 0,
    backgroundColor: '#fff',
  },

  mobileStubHeader: {
    margin: '20px 40px 0 40px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Bold',
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: '-0.2px',
    textAlign: "center"
  },

  mobileStubSubHeader: {
    margin: '20px 40px 0 40px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.2px',
    textAlign: "center"
  },

  mobileStubHint: {
    margin: '40px 40px 0 40px',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '-0.2px',
    textAlign: "center"
  },

  mobileStubLinkBlock:
  {
    margin: '10px 40px 0 40px',
    textAlign: "center",
  },

  mobileStubLink: {
    margin: '0px auto 0px auto',
    width: 'auto',
    color: '#1E1E1E',
    fontFamily: 'SFProText-Regular',
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '-0.2px',
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
    borderBottom: "1px dashed #1E1E1E"
  },

  mobileStubInstallButton: {
    margin: '20px auto 0 auto',
    textAlign: "center",
    cursor: "pointer",
    width: "206px",
    height: "62px"
  },

}
