import React from 'react';

import commonStyles from './../Common.styles';

class ProblemsAddNewStep6ReadyScreen extends React.Component {

    render() {

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>
                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("/ProblemsAddNewStep4Comments_Screen")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>

                            <div style={styles.backBoxOuter}>

                                <div style={styles.backBox}>
                                    <img alt="" style={styles.cityBack1} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_CompleteBack1.svg`} />
                                </div>
                                <div style={styles.backBox2}>
                                    <img alt="" style={styles.cityBack2} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_CompleteBack2.svg`} />

                                </div>

                                <div style={styles.backBox3}>
                                    <div style={styles.greenCircleBox}>
                                        <img alt="" style={styles.greenCircle} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_CompleteGreenCircle.svg`} />
                                    </div>
                                </div>

                                <p style={styles.statusText}>Ваше сообщение успешно отправлено!</p>
                                <p style={styles.progressText}>Оно будет рассмотрено в течение 3 рабочих дней. Наблюдайте за ходом решения вашей проблемы из приложения.</p>

                            </div>
                            <div style={styles.nextButtonsBlock}>
                                <div
                                    onClick={() => this.props.navigateTo("problems", { problems_defaultTab: 'own' })}>
                                    <div style={styles.nextStepTextButton}>
                                        <p style={styles.nextStepTextButtonLabel}>На главную</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


export default ProblemsAddNewStep6ReadyScreen;


const styles = {

    whiteBack: {
        flex: 1,
        backgroundColor: '#FFFFFF'
    },

    backBoxOuter: {        
        paddingTop: "260px",        
    },

    backBox: {    
        position: 'absolute',
        top: '100px',
        left: '0px',
        right: '0px',
        height: '192px',
        width:'100%',
    },

    backBox2: {
        position: 'absolute',
        top: '190px',
        left: '0px',
        right: '0px',
        height: '100px',
        width:'100%',
    },

    backBox3: {
        padding: "1px",
        marginTop: '0px',
        width: '100%',
        height: '105px',
        textAlign: 'center'
    },

    greenCircleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '105px',
        height: '105px',
        backgroundColor: '#FFFFFF',
        borderRadius: '53px'
    },

    greenCircle: {
        width: '105px',
        height: '105px',
    },

    cityBack1: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
    },

    cityBack2: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
    },

    statusText: {
        marginTop: '22px',
        marginLeft: '48px',
        marginRight: '48px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '18px',
        lineHeight: '20px',
        color: '#1E1E1E',
        textAlign: 'center'
    },

    progressText: {
        marginTop: '22px',
        marginLeft: '48px',
        marginRight: '48px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#8C8C8C',
        textAlign: 'center'
    },

    nextButtonsBlock: {
        height: '138px',
        position: 'absolute',
        bottom: '30px',
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF80',
        textAlign: 'center',
        padding: '1px',
    },

    nextStepTextButton: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#005687',
        textAlign: 'center',
        cursor: 'pointer'
    },

    nextStepTextButtonLabel: {
        paddingTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center',
    },


};
