import React from 'react';

export class PreloaderMini extends React.Component {
    render() {
        return (
            <div style={styles.preloaderBox}>
                <div style={styles.preloaderBackground}>
                    <img alt="" style={styles.preloaderImage} src={`${process.env.PUBLIC_URL}/_assets/preloader_clocks_fast.gif`} />
                </div>
            </div >
        );
    }
}

export default PreloaderMini;

const styles = {

    preloaderBox: {
        position: 'absolute',
        top: '23px',
        left: '50%',       
        height: '50px',  
        zIndex: 100000  
    },

    preloaderBackground: {
        width: '48px',
        height: '48px',
        backgroundColor: "#FFFFFF",
        borderRadius: '24px',
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
    },

    preloaderImage: {
        margin: '12px 14px 12px 14px',
        width: '20px',
        height: '24px',
    },
};