import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import commonStyles from './../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';
import PreloaderFull from '../../components/PreloaderFull';


import 'react-tabs/style/react-tabs.css';

class ProblemsHomeScreen extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            ownProblems: null,
            activeCategoriesProblems: null,
            activeCategoriesIndexes: null,
            selectedCategory: null,
            categories: null,
            searchText: null,
            progressVisible: false,
            selectedTabIndex: 0
        };
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this._CommonProblemsRoute = this._CommonProblemsRoute.bind(this);
        this._OwnProblemsRoute = this._OwnProblemsRoute.bind(this);

        if (!this.state.categories) {
            SmartCityApi.problemsCategories()
                .then(response => {
                    if (this._isMounted) {
                        this.setState({
                            categories: response.categories
                        });
                    }
                });
        }

        if ((!this.state.ownProblems) || (this.props.reloadProblemsMessageses)) {
            if (this._isMounted)
                this.setState({ progressVisible: true });
            SmartCityApi.problemsMessages()
                .then(response => {
                    var activeCategoriesProblems = {};
                    var activeCategoriesIndexes = [];
                    response.last.map(item => {
                        if (!activeCategoriesProblems[item.category]) {
                            activeCategoriesProblems[item.category] = [];
                            activeCategoriesIndexes.push(item.category);
                        }
                        activeCategoriesProblems[item.category].push(item);
                        return null;
                    });

                    activeCategoriesIndexes.sort(function (a, b) {
                        if (activeCategoriesProblems[a].length < activeCategoriesProblems[b].length) {
                            return 1;
                        }
                        if (activeCategoriesProblems[a].length > activeCategoriesProblems[b].length) {
                            return -1;
                        }
                        return 0;
                    });

                    var selectedCategory =
                        (this.props.selectedCategory ? this.props.selectedCategory :
                            (activeCategoriesIndexes.length > 0 ? activeCategoriesIndexes[0] : null));

                    if (this._isMounted)
                        this.setState({
                            progressVisible: false,
                            ownProblems: response.own,
                            activeCategoriesProblems: activeCategoriesProblems,
                            activeCategoriesIndexes: activeCategoriesIndexes,
                            selectedCategory: selectedCategory,
                            isAnonym: response.anonym || false
                        });
                }, () => {
                    if (this._isMounted)
                        this.setState({ progressVisible: false });
                });
        }

        let defaultTab = this.props.defaultTab;
        if (defaultTab) {
            if (defaultTab === "common") {
                if (this._isMounted)
                    this.setState({ selectedTabIndex: 0 });
            }
            if (defaultTab === "own") {
                if (this._isMounted)
                    this.setState({ selectedTabIndex: 1 });
            }
        }

    }

    preparedCommonProblemsList = null;

    _CommonProblemsRoute() {

        var preparedActiveCategories = null;
        if (this.state.activeCategoriesIndexes && (this.state.activeCategoriesIndexes.length > 0) && this.state.categories) {
            preparedActiveCategories = [];
            for (let i = 0; i < this.state.activeCategoriesIndexes.length; i++) {
                let category = null;
                this.state.categories.map(item => {
                    if (item.id === this.state.activeCategoriesIndexes[i]) {
                        category = item;
                    }
                    return null;
                });
                preparedActiveCategories.push(
                    {
                        id: category.id,
                        name: category.name,
                        icon: category.icon
                    });
            }
        }

        if (!this.preparedCommonProblemsList) {
            if (this.state.activeCategoriesProblems && this.state.selectedCategory) {
                this.preparedCommonProblemsList = [];
                let preparedProblemsListForMap = [];
                this.state.activeCategoriesProblems[this.state.selectedCategory].map(item => {
                    let name = item.text.replace("<br>", " ").replace("<br/>", " ").replace("  ", " ");
                    if (name.length > 50) {
                        name = name.substring(0, 50) + "...";
                    }

                    let number = '№' + item.id;
                    if (item.lifeCycle["Новое"]) {
                        let date = SmartCityApi.parseDate(item.lifeCycle["Новое"].date);
                        number += ' от ' + SmartCityApi.prettyDateTime(date);
                    }
                    this.preparedCommonProblemsList.push(
                        {
                            id: item.objectId,
                            name: name,
                            icon: (item.images.length > 0 ? item.images[0] : null),
                            status: item.status,
                            place: item.placeLabel,
                            geometry: item.place,
                            number: number
                        });
                    preparedProblemsListForMap.push(item);
                    return null;
                });

                if (this.state.selectedTabIndex === 0) {
                    this.props.updateShowingMarkers(preparedProblemsListForMap);
                }
            }
        }

        return (

            <div style={styles.commonTabProblemsList}>
                <p style={styles.commonTabHeader}>Активные категории</p>
                {preparedActiveCategories &&
                    <div style={styles.commonTabRecentAdditions}>
                        {preparedActiveCategories.map(item => (
                            <div key={item.id} style={styles.commonTabRecentAdditionsBox}
                                onClick={() => {
                                    this.preparedCommonProblemsList = null;
                                    if (this._isMounted)
                                        this.setState({ selectedCategory: item.id })
                                }}>
                                <div>
                                    <img alt="" style={styles.commonTabRecentAdditionsImage} src={SmartCityApi.API_ROOT_URL + item.icon}></img>
                                    <p style={styles.commonTabRecentAdditionsLabel}>{item.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <p style={styles.commonTabHeader}>Последние добавления</p>
                {this.preparedCommonProblemsList && this.preparedCommonProblemsList.map(item => (
                    <div
                        key={item.id}
                        style={styles.commonTabProblemsListItem}
                        onClick={() => {
                            this.props.showProblemMarker(item);
                        }}>
                        {item.icon &&
                            <img alt="" style={styles.commonTabProblemsListItemImage} src={"https://map.samadm.ru/preview?width=75&height=75&changeProportion=true&url=" + item.icon}></img>
                        }
                        {!item.icon &&
                            <div style={styles.commonTabProblemsListItemImageStub}>
                            </div>
                        }
                        <div style={styles.commonTabProblemsListItemTextBlock}>
                            <p style={styles.commonTabProblemsListItemTextBlockName}>{item.name}</p>
                            <div style={styles.commonTabProblemsListItemIconedRow}>
                                <div style={styles.commonTabProblemsListItemTextBlockMiniIcon}>
                                    <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_StatusMiniIcon.png`}></img>
                                </div>
                                <p style={styles.commonTabProblemsListItemTextBlockStatus}>{item.status}</p>
                                <div style={{ clear: "left" }}></div>
                            </div>
                            <div style={styles.commonTabProblemsListItemIconedRow}>
                                <div style={styles.commonTabProblemsListItemTextBlockMiniIcon}>
                                    <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_PlaceMiniIcon.png`}></img>
                                </div>
                                <p style={styles.commonTabProblemsListItemTextBlockPlace}>{item.place}</p>
                                <div style={{ clear: "left" }}></div>
                            </div>
                            <p style={styles.commonTabProblemsListItemTextBlockNumber}>{item.number}</p>
                            <div style={styles.graySplitterLine}></div>
                        </div>
                        <div style={{ clear: "left" }}></div>
                    </div>
                ))}
            </div>
        );
    }


    preparedOwnProblemsList = null;

    _OwnProblemsRoute() {

        if (!this.preparedOwnProblemsList) {

            if (this.state.ownProblems && this.state.selectedCategory) {
                this.preparedOwnProblemsList = [];
                let preparedProblemsListForMap = [];
                this.state.ownProblems.map(item => {
                    let name = item.text.replace("<br>", " ").replace("<br/>", " ").replace("  ", " ");
                    if (name.length > 50) {
                        name = name.substring(0, 50) + "...";
                    }

                    let number = '№' + item.id;
                    if (item.lifeCycle["Новое"]) {
                        let date = SmartCityApi.parseDate(item.lifeCycle["Новое"].date);
                        number += ' от ' + SmartCityApi.prettyDateTime(date);
                    }

                    //let styleName = this.state.categories[item.category].styles[item.status];
                    
                    let searchDescription = (item.id + " " + item.text).toLowerCase();
                    let searchText = (this.state.searchText ? this.state.searchText.toLowerCase() : null);
                    if (((!searchText) || (searchDescription.indexOf(searchText) >= 0)) && (item.place.type === "Point")) {
                        this.preparedOwnProblemsList.push(
                            {
                                id: item.objectId,
                                name: name,
                                icon: (item.images.length > 0 ? item.images[0] : null),
                                status: item.status,
                                place: item.placeLabel,
                                geometry: item.place,
                                number: number
                            });
                        preparedProblemsListForMap.push(item);
                    }
                    return null;
                });

                if (this.state.selectedTabIndex === 1) {
                    this.props.updateShowingMarkers(preparedProblemsListForMap);
                }
            }
        }

        return (
            <div>
                {(this.state.ownProblems) && (this.state.ownProblems.length > 0) &&
                    <div style={styles.searchListItem}>
                        <input
                            type="text"
                            style={styles.searchTextInput}
                            placeholder="найти среди ваших"
                            onChange={(e) => {
                                this.preparedOwnProblemsList = null;
                                if (this._isMounted)
                                    this.setState({ searchText: e.target.value });
                            }
                            }
                        ></input>
                    </div>
                }
                <div style={styles.commonTabProblemsList} >
                    {this.preparedOwnProblemsList && this.preparedOwnProblemsList.map(item => (
                        <div
                            key={item.id}
                            style={styles.commonTabProblemsListItem}
                            onClick={() => {
                                this.props.showProblemMarker(item);
                            }}>
                            {item.icon &&
                                <img alt="" style={styles.commonTabProblemsListItemImage} src={"https://map.samadm.ru/preview?width=75&height=75&changeProportion=true&url=" + item.icon}></img>
                            }
                            {!item.icon &&
                                <div style={styles.commonTabProblemsListItemImageStub}>
                                </div>
                            }
                            <div style={styles.commonTabProblemsListItemTextBlock}>
                                <p style={styles.commonTabProblemsListItemTextBlockName}>{item.name}</p>
                                <div style={styles.commonTabProblemsListItemIconedRow}>
                                    <div style={styles.commonTabProblemsListItemTextBlockMiniIcon}>
                                        <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_StatusMiniIcon.png`}></img>
                                    </div>
                                    <p style={styles.commonTabProblemsListItemTextBlockStatus}>{item.status}</p>
                                    <div style={{ clear: "left" }}></div>
                                </div>
                                <div style={styles.commonTabProblemsListItemIconedRow}>
                                    <div style={styles.commonTabProblemsListItemTextBlockMiniIcon}>
                                        <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_PlaceMiniIcon.png`}></img>
                                    </div>
                                    <p style={styles.commonTabProblemsListItemTextBlockPlace}>{item.place}</p>
                                    <div style={{ clear: "left" }}></div>
                                </div>
                                <p style={styles.commonTabProblemsListItemTextBlockNumber}>{item.number}</p>
                            </div>
                            <div style={{ clear: "left" }}></div>
                        </div>
                    ))}
                    {((!this.preparedOwnProblemsList) || (this.preparedOwnProblemsList.length === 0)) &&
                        <div>
                            {this.state.isAnonym &&
                                <div
                                    onClick={() => window.location.href = "/user/?returnTo=/new/#problems"}>
                                    <p style={styles.emptyListStubMessageText}>Чтобы добавлять свои сообщения, вам нужно авторизоваться. {"\n"} Такие правила, анонимные сообщения не принимаются. {"\n"} Нажмите, чтобы сделать это.</p>
                                    <div style={styles.goToAuthTextButton}>
                                        <p style={styles.goToAuthTextButtonLabel}>Авторизоваться</p>
                                    </div>
                                </div>
                            }
                            {(!this.state.isAnonym) && ((!this.state.ownProblems) || (this.state.ownProblems.length === 0)) &&
                                <p style={styles.emptyListStubMessageText}>Вы еще не добавили ни одного сообщения. Если видите городскую проблему и хотите сообщить о ней - начните с кнопки «Добавить проблему».</p>
                            }
                            {(!this.state.isAnonym) && (this.state.ownProblems) && (this.state.ownProblems.length > 0) &&
                                <p style={styles.emptyListStubMessageText}>Ничего не найдено среди ваших сообщений, уточните запрос.</p>
                            }
                        </div>
                    }

                </div>
            </div >
        );
    }

    onTabsSelect = (index, lastIndex, event) => {
        if (this.state.selectedTabIndex !== index) {
            this.preparedCommonProblemsList = null;
            this.preparedOwnProblemsList = null;
            if (this._isMounted)
                this.setState({ selectedTabIndex: index });
        }
    }

    render() {
        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>

                    <Tabs
                        style={styles.tabViewStyle}
                        onSelect={this.onTabsSelect}
                        selectedIndex={this.state.selectedTabIndex}
                    >
                        <TabList style={styles.tabIndicatorStyle}>
                            <Tab style={styles.tabStyle}>Сообщения в городе</Tab>
                            <Tab style={styles.tabStyle}>Ваши</Tab>
                        </TabList>

                        <div style={styles.tabPanelStyle}>
                            <div style={styles.fullTab}>
                                <TabPanel>
                                    {this._CommonProblemsRoute()}
                                </TabPanel>
                                <TabPanel>
                                    {this._OwnProblemsRoute()}
                                </TabPanel>
                            </div>
                        </div>
                    </Tabs>

                    <div
                        //hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                        onClick={() => {
                            if (this.state.isAnonym) {
                                if (this._isMounted)
                                    this.setState({ selectedTabIndex: 1 });
                            }
                            else {
                                this.props.navigateTo("problems_1");
                            }
                        }}>
                        <div style={styles.addNewProblemButton}>
                            <p style={styles.addNewProblemButtonLabel}>Добавить сообщение</p>
                            <div style={styles.addNewProblemButtonImage}>
                                <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_AddNewProblemButton.svg`}></img>
                            </div>
                            <div style={styles.addNewProblemButtonImagePlus}>
                                <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_AddNewProblemButton2.svg`}></img>
                            </div>
                        </div>
                    </div>

                </div >
                {this.state.progressVisible &&
                    <PreloaderFull />
                }
            </div >
        );
    }
}

export default ProblemsHomeScreen;




const styles = {

    tabViewStyle: {
        backgroundColor: '#DBECEC',
        marginTop: '20px'
    },

    tabPanelStyle: {
        overflow: "hidden",
        height: "calc(100vh - 125px)", // 125px -- sum of heights of the header and the tab section
        display: "flex",
        flexDirection: "column",
        marginTop: "-10px"
    },

    fullTab: {
        flex: "1",
        overflow: "scroll",
        overflowX: "hidden",
        backgroundColor: '#FFFFFF',
    },

    tabStyle: {
        marginLeft: '10px',
        fontFamily: 'SFProText-Regular',
        //backgroundColor: '#FFFFFF',
    },

    tabIndicatorStyle: {
        //backgroundColor: '#1106FF'
    },

    tabLabelSelected: {
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#1106FF',
    },

    tabLabelNotSelected: {
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#1106FF',
        opacity: 0.35
    },


    commonTabHeader: {
        marginLeft: '0px',
        marginRight: '22px',
        marginTop: '22px',
        height: '26px',
        fontFamily: 'SFProText-Regular',
        fontSize: '22px',
        lineHeight: '26px',
        letterSpacing: '-0.1px',
        color: '#060518',
    },

    commonTabRecentAdditions: {
        marginLeft: '0px',
        marginRight: '14px',
        marginTop: '15px',
        whiteSpace: "nowrap",
        width: "100%",
        overflowX: "scroll",
    },

    commonTabRecentAdditionsBox: {
        width: "80px",
        height: "100px",
        margin: "10px",
        display: "inline-block",
        cursor: "pointer"
    },

    commonTabRecentAdditionsImage: {
        marginRight: '10px',
        marginBottom: '0px',
        //backgroundColor: '#FFFFFF',
        borderRadius: '7px',
        resizeMode: 'cover',
        width: '60px',
        height: '60px',
    },

    commonTabRecentAdditionsLabel: {
        fontFamily: 'SFProText-Regular',
        fontSize: '11px',
        lineHeight: '13px',
        letterSpacing: '-0.1px',
        color: '#060518',
        width: '60px',
        height: '40px',
        overflow: "hidden",
        whiteSpace: "normal",
        textAlign: "center"
    },

    commonTabProblemsList: {
        marginLeft: '22px',
        marginRight: '14px',
        marginTop: '0px',
        height: '400px'
    },

    commonTabProblemsListItem: {
        position: "relative",
        cursor: "pointer"
    },

    commonTabProblemsListItemImage: {
        float: "left",
        marginLeft: '0px',
        marginTop: '15px',
        backgroundColor: '#FFFFFF',
        borderRadius: '30px',
        resizeMode: 'cover',
        width: '60px',
        height: '60px',
    },

    commonTabProblemsListItemImageStub: {
        float: "left",
        marginLeft: '0px',
        marginTop: '15px',
        backgroundColor: '#DDDDDD',
        borderRadius: '30px',
        resizeMode: 'cover',
        width: '60px',
        height: '60px',
    },

    commonTabProblemsListItemTextBlock: {
        float: "left",
        marginLeft: '17px',
        width: 'calc(100% - 80px)',
    },

    commonTabProblemsListItemIconedRow: {
        marginTop: '3px',
        position: "relative"
    },

    commonTabProblemsListItemTextBlockMiniIcon: {
        float: "left",
        width: '16px',
        height: '16px',
        alignItems: "center",
        justifyContent: "center",
    },

    commonTabProblemsListItemTextBlockName: {
        marginTop: '20px',
        fontFamily: 'SFProText-Regular',
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
        color: '#1E1E1E',
        width: 'auto'
    },

    commonTabProblemsListItemTextBlockStatus: {
        float: "left",
        marginLeft: '6px',
        marginTop: '0px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        color: '#8C8C8C',
        width: '150px'
    },

    commonTabProblemsListItemTextBlockPlace: {
        float: "left",
        marginLeft: '6px',
        marginTop: '0px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        color: '#1E1E1E',
        width: '150px'
    },

    commonTabProblemsListItemTextBlockNumber: {
        marginLeft: '22px',
        marginTop: '5px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        color: '#8C8C8C',
        width: '150px'
    },

    emptyListStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },

    backButton: {
        position: 'absolute',
        width: '9px',
        height: '16px',
        left: '20px',
        top: '34px',
    },

    addNewProblemButton: {
        position: 'absolute',
        width: '106px',
        height: '128px',
        right: '20px',
        bottom: '43px',
        backgroundColor: '#FFF',
        borderRadius: '28px',
        textAlign: "center",
        cursor: "pointer",
        boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.25)",
    },

    addNewProblemButtonLabel: {
        position: 'absolute',
        // width: '92px',
        left: '7px',
        right: '7px',
        bottom: '-5px',
        color: '#DA1919',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    },

    addNewProblemButtonImage: {
        position: 'absolute',
        width: '57px',
        height: '56px',
        left: '24px',
        top: '20px',
    },

    addNewProblemButtonImagePlus: {
        position: 'absolute',
        width: '57px',
        height: '56px',
        left: '39px',
        top: '33px',
    },


    searchListItem: {
        marginLeft: '26px',
        marginRight: '20px',
        marginTop: '10px',
        marginBottom: '10px',
        height: '40px',
    },

    searchTextInput: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        width: '255px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: "1px solid rgba(36, 46, 66, 0.25)",
        color: '#000000',
        borderRadius: '8px',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    },

    goToAuthTextButton: {
        width: '264px',
        height: '40px',
        marginTop: '0px',
        marginBottom: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '44px',
        backgroundColor: '#242E42',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer'
    },

    goToAuthTextButtonLabel: {
        marginLeft: '30px',
        marginRight: '30px',
        paddingTop: '10px',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center'
    },

    graySplitterLine: {
        marginTop: '20px',
        backgroundColor: '#0000003C',
        height: "1px",
        marginLeft: '0px',
        marginRight: '10px',
    }
};
