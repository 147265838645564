import Hash from './Hash.js';
require('es6-promise').polyfill();
require('isomorphic-fetch');

class TosApi {
    static API_ADDRESS = 'https://tosamara.ru/api/v2/json';
    static CLIENT_ID = 'SmrSmartCity';
    static SECRET = 'u38f9ud93f2h9f';

    static async getTransportsOnRoute(KR_ID, count) {
        return this.request("getTransportsOnRoute", {
            KR_ID: KR_ID,
            COUNT: count
        });
    }

    static async getTransportPosition(hullno) {
        return this.request('getTransportPosition',
            {
                hullno: hullno
            }
        );
    }

    static async getSurroundingTransports(lat, lng, radius, count) {
        return this.request('getSurroundingTransports',
            {
                LATITUDE: lat,
                LONGITUDE: lng,
                RADIUS: radius,
                COUNT: count
            }
        );
    }

    static async getFirstArrivalToStop(KS_ID, count) {
        return this.request("getFirstArrivalToStop", {
            KS_ID: KS_ID,
            COUNT: count
        });
    }

    static async request(method, message) {
        message.method = method;
        message = JSON.stringify(message);
        let authKey = Hash.SHA1(message + this.SECRET);
        return fetch(this.API_ADDRESS, {
            method: 'POST',
            credentials: "same-origin",             
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'clientId=' + this.CLIENT_ID + '&os=android&authKey=' + authKey + '&message=' + message
        }).then((response) => {
            if (!response.ok) throw Error(response.status);
            return response.text();
        });
    }

}

export default TosApi;