import React from 'react';

const styles = {

    layersGroupListItem: {
        float: "none",
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '17px',
        userSelect: "none",
        cursor: 'pointer',
        paddingLeft: "36px",
        position: "relative"
    },

    layersGroupListItemPlusButton: {
        position: "absolute",
        marginLeft: "-36px",
        float: "left",
        width: '34px',
        height: '34px',
        borderRadius: '12px',
        backgroundColor: '#ECEEF8',
    },

    layersGroupListItemSelectedButton: {
        position: "absolute",
        marginLeft: "-36px",
        float: "left",
        width: '34px',
        height: '34px',
        borderRadius: '12px',
        backgroundColor: '#3A4559',
    },

    buttonImage: {
        display: "block",
        height: '20px',
        width: '24px',
        marginLeft: "5px",
        marginTop: "7px"
    },

    layersGroupListItemLabel: {
        float: "left",
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.2px',
        marginLeft: '10px',
        marginRight: '0px',
        marginTop: "8px",
        width: '200px',
    },

    clearBlock: {
        clear: "left"
    }
};

class LayersListElement extends React.Component {
    state = {
        icon: `${process.env.PUBLIC_URL}/_assets/_icons/CityMap_LayersListSelectedIcon.png`,
        style: styles.layersGroupListItemSelectedButton
    }

    componentWillMount() {
        this.updateState(this.props.layerItem);
    }

    updateState(layerItem) {
        this.setState({
            icon: layerItem.selected ? `${process.env.PUBLIC_URL}/_assets/_icons/CityMap_LayersListSelectedIcon.png` : `${process.env.PUBLIC_URL}/_assets/_icons/CityMap_LayersListPlusIcon.png`,
            style: layerItem.selected ? styles.layersGroupListItemSelectedButton : styles.layersGroupListItemPlusButton
        });
    }

    render() {
        return (
            <div
                style={styles.layersGroupListItem}
                onClick={(e) => {
                    e.preventDefault();
                    let layerItem = this.props.layerItem;
                    if (layerItem.read) {
                        layerItem.selected = !layerItem.selected;
                        this.updateState(layerItem);
                        if (this.props.onStatusChange) {
                            this.props.onStatusChange();
                        }
                    }
                }}>
                <div style={this.state.style}>
                    <img alt="" src={this.state.icon} style={styles.buttonImage} />
                </div>
                <p style={styles.layersGroupListItemLabel}>{this.props.layerItem.name}</p>
                <div style={styles.clearBlock}></div>
            </div>
        );
    }
}

export default LayersListElement;