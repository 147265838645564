import React from 'react';
import commonStyles from './../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';
import BaseParams from '../../services/BaseParams';
import LayersListElement from '../../components/LayersListElement';
import TextSearchField from '../../components/TextSearchField';
import TextSearchResults from '../../components/TextSearchResults';
import xml2js from 'xml2js';

class CityMapLayersListScreen extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            layersListContent: [],
            filteredLayersListContent: [],
            searchText: ''
        };
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;

        if (this.props.defaultLayers && this.props.defaultLayers.length > 0) {
            if (this._isMounted)
                this.setState({
                    layersListContent: this.props.defaultLayers,
                    filteredLayersListContent: this.props.defaultLayers
                })
        }
        else {
            SmartCityApi.mapLayers()
                .then(async (response) => {
                    let layersListContent = response.groups;

                    await CityMapLayersListScreen.fixWMSLayersTransparency(layersListContent);

                    if (this._isMounted)
                        this.setState({
                            layersListContent: layersListContent,
                            filteredLayersListContent: layersListContent
                        })
                });
        }
    }

    static fixWMSLayersTransparency = async (groups) => {
        let usedWMS = [];
        for (let group of groups) {
            for (let layer of group.layers) {
                if (layer.type === "WMS") {
                    if ((usedWMS.indexOf(layer.source) < 0) && (layer.source.length > 0)) {
                        usedWMS.push(layer.source);
                    }
                }
            }
        }

        for (let i = 0; i < usedWMS.length; i++) {
            let response = await fetch(BaseParams.GEOPORTAL_URL + '/' + usedWMS[i] + '?request=GetCapabilities&service=wms&r=' + Math.random(), {
                credentials: 'same-origin',
                // mode: "no-cors",
            });
            if (!response.ok) throw Error(response.status);
            let xml = await response.text();
            let json = await xml2js.parseStringPromise(xml);
console.log(xml, json);

            let list = CityMapLayersListScreen.recursiveAggregateWmsLayers(json.WMS_Capabilities.Capability[0].Layer[0], null, 0);

            for (let group of groups) {
                for (let layer of group.layers) {
                    if (layer.type === "WMS") {
                        if (layer.source === usedWMS[i]) {
                            let ids = layer.id.split(",");

                            let transparents = [];
                            for (let j = 0; j < ids.length; j++) {
                                for (let k = 0; k < list.length; k++) {
                                    if (list[k].id === ids[j]) {
                                        transparents.push(list[k].transparent);
                                    }
                                }
                            }

                            let allAreTransparent = true;
                            for (let k = 0; k < transparents.length; k++) {
                                allAreTransparent = allAreTransparent && transparents[k];
                            }

                            layer.transparent = (allAreTransparent ? "true" : "false");
                            layer.format = (allAreTransparent ? "png" : "jpeg");
                        }
                    }
                }
            }
        };


    }


    static recursiveAggregateWmsLayers = (layerNode, parentLayer, order) => {
        var wmsLayersList = [];
        var sublayerNodes = layerNode.Layer;
        if (sublayerNodes && (sublayerNodes.length > 0)) {
            var layer;
            for (var j = 0; j < sublayerNodes.length; j++) {
                var node = sublayerNodes[j];

                layer = {};
                layer.name = node.Title[0];
                layer.transparent = (node.$ ? (node.$.opaque !== '1') : true);
                layer.parentLayer = parentLayer;
                layer.queryable = true; //(node.$.queryable == "1");
                layer.order = order;
                var nameNodes = node.Name;
                layer.id = nameNodes.length > 0 ? nameNodes[0] : '';
                wmsLayersList.push(layer);

                var nestedLayersNodes = node.Layer;
                if (nestedLayersNodes && nestedLayersNodes.length > 0) {
                    layer.grouped = true;
                    var wmsSublayersList = this.recursiveAggregateWmsLayers(node, layer, order + 1);
                    wmsLayersList = wmsLayersList.concat(wmsSublayersList);
                }
            }
        }
        return wmsLayersList;
    }


    onStatusChange = () => {
        if (this.props.updateLayersFunction) {
            this.props.updateLayersFunction(this.state.layersListContent);
        }
    }

    search(text) {
        if (text.length < 2) {
            this.setState({ filteredLayersListContent: this.state.layersListContent });
            return;
        }
        text = text.toLowerCase();
        let filteredResults = []
        for (let group of this.state.layersListContent) {
            let newGroup = null;
            for (let layer of group.layers) {
                let searchValue = layer.name.toLowerCase() + " " + (layer.tags ? layer.tags : "").toLowerCase();
                if (searchValue.indexOf(text) >= 0) {
                    if (!newGroup) {
                        newGroup = {
                            name: group.name,
                            layers: []
                        };
                    }
                    newGroup.layers.push(layer);
                }
            }
            if (newGroup) {
                filteredResults.push(newGroup);
            }
        }
        this.setState({ filteredLayersListContent: filteredResults });
    }

    render() {
        return (
            <div style={commonStyles.commonScreen}>

                <TextSearchField
                    style={commonStyles.textSearchField}
                    placeholder="поиск по адресу"
                    beforeSearch={() => {
                        /*if (this.state.stop || this.state.transport || this.state.routeInfo) {
                            this.setState({
                                stop: null,
                                transport: null,
                                routeInfo: null
                            });
                        }*/
                    }}
                    afterFound={(foundResults) => {
                        if (this._isMounted)
                            this.setState({
                                foundResults: foundResults
                            });
                    }}
                />

                <div style={styles.searchListItem}>
                    <input
                        type="text"
                        style={styles.searchTextInput}
                        placeholder="выбор слоя"
                        onChange={(e) => {
                            this.search(e.target.value);
                        }}
                    ></input>
                </div>

                {this.state.filteredLayersListContent &&
                    <div style={styles.layersListBlock}>
                        {this.state.filteredLayersListContent.map((groupItem, index) => (
                            <div key={index} style={styles.layersGroupBlock}>
                                <p style={styles.layersGroupHeader}>{groupItem.name}</p>
                                {groupItem.layers.map((layerItem, index2) => ((layerItem.read === true) &&
                                    <LayersListElement key={index + 1000 + index2} layerItem={layerItem} onStatusChange={this.onStatusChange} />
                                ))}
                            </div>
                        ))}
                    </div>
                }


                <TextSearchResults
                    style={commonStyles.textSearchResults}
                    foundResults={this.state.foundResults}
                    onItemPress={(item) => {
                        if (this._isMounted)
                            this.setState({
                                foundResults: null
                            });
                        if (this.props.foundMarkerFunction) {
                            this.props.foundMarkerFunction(item);
                        }
                    }} />
            </div >
        );
    }
}

CityMapLayersListScreen.navigationOptions = {
    header: null
};

export default CityMapLayersListScreen;


const styles = {

    searchListItem: {
        marginLeft: '26px',
        marginRight: '20px',
        marginTop: '0px',
        marginBottom: '0px',
        height: '40px',
    },

    searchTextInput: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: "1px solid rgba(36, 46, 66, 0.25)",
        color: '#000000',
        borderRadius: '8px',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    },

    layersListBlock: {
        marginLeft: '26px',
        marginRight: '10px',
        marginTop: '20px',

        flex: "1",
        overflow: "scroll",
        overflowX: "hidden"
    },

    layersGroupBlock: {
        marginBottom: '20px',
        userSelect: "none"
    },

    layersGroupHeader: {
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
        marginBottom: '12px',
        marginRight: '30px',
    },

    layersGroupListItem: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '17px',
    },

    layersGroupListItemPlusButton: {
        width: '34px',
        height: '34px',
        borderRadius: '12px',
        backgroundColor: '#ECEEF8',
        alignItems: "center",
        justifyContent: "center",
    },

    layersGroupListItemSelectedButton: {
        width: '34px',
        height: '34px',
        borderRadius: '12px',
        backgroundColor: '#1106FF',
        alignItems: "center",
        justifyContent: "center",
    },

    layersGroupListItemLabel: {
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.2px',
        marginLeft: '10px',
        marginRight: '30px',
    },

};
