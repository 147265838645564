import React from 'react';

import Hash from "../services/Hash";

export class PhotoGallery extends React.Component {

    componentDidMount() {

    }

    render() {
        if (this.props.uriIsStatic === "mixed") {
            return (
                <div style={this.props.style}>
                    <div style={styles.galleryBlockScroll}>
                        {this.props.images && this.props.images.map(item => (
                            <div key={Hash.MD5(item.imageUrl)} style={styles.galleryBlockImageBox}
                                onClick={() => { window.open(item.imageUrl, '_blank').focus(); }}>
                                {item.isStatic &&
                                    <img alt="" style={styles.galleryBlockImage} src={item.imageUrl}></img>
                                }
                                {!item.isStatic &&
                                    <img alt="" style={styles.galleryBlockImage} src={"https://map.samadm.ru/preview?width=100&height=100&changeProportion=true&url=" + item.imageUrl}></img>
                                }
                                {this.props.removeButtons &&
                                    <div style={styles.galleryBlockImageRemoveButton}>
                                        <div
                                            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                                            onClick={() => this.props.removeClickHandler(item.imageUrl)}>
                                            <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_GalleryRemoveItem.svg`}></img>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                        {this.props.children}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div style={this.props.style}>
                    <div style={styles.galleryBlockScroll}>
                        {this.props.images && this.props.images.map(imageUrl => (
                            <div key={Hash.MD5(imageUrl)} style={styles.galleryBlockImageBox}
                                onClick={() => { window.open(imageUrl, '_blank').focus(); }}>
                                {this.props.uriIsStatic &&
                                    <img alt="" style={styles.galleryBlockImage} src={imageUrl}></img>
                                }
                                {!this.props.uriIsStatic &&
                                    <img alt="" style={styles.galleryBlockImage} src={"https://map.samadm.ru/preview?width=100&height=100&changeProportion=true&url=" + imageUrl}></img>
                                }
                                {this.props.removeButtons &&
                                    <div style={styles.galleryBlockImageRemoveButton}>
                                        <div
                                            //hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                                            onClick={() => this.props.removeClickHandler(imageUrl)}>
                                            <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_GalleryRemoveItem.svg`}></img>
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                        {this.props.children}
                    </div>
                </div>
            );

        }

    }
}

export default PhotoGallery;

const styles = {

    galleryBlockScroll: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        height: '120px',
        whiteSpace: "nowrap",
        //width: "253px",
        overflowX: "scroll",
        overflowY: "hidden ",
    },

    galleryBlockImageBox: {
        display: "inline-block",
        marginRight: '8px',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        width: '100px',
        height: '100px',
        position: "relative",
        cursor: 'pointer'
    },

    galleryBlockImage: {
        //position: 'absolute',
        //left: '0px',
        //top: '0px',
        borderRadius: '8px',
        resizeMode: 'cover',
        width: '100px',
        height: '100px',
    },

    galleryBlockImageRemoveButton: {
        position: 'absolute',
        right: '7px',
        top: '7px',
        width: '17px',
        height: '17px',
        cursor: 'pointer'
    },

};
