import React from 'react';

import commonStyles from './../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';
import PhotoGallery from '../../components/PhotoGallery';

class ProblemsAddNewStep5CheckScreen extends React.Component {

    state = {
        errorMessage: null,
        progressVisible: false
    }

    componentDidMount() {
        this.sendProblemMessage = this.sendProblemMessage.bind(this);

    }

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }


    sendingProblemMessage = false;
    sendProblemMessage() {
        if (this.sendingProblemMessage) {
            return;
        }
        this.sendingProblemMessage = true;

        let category = this.props.category;
        let place = this.props.place;
        let selectedPhotos = this.props.selectedPhotos || [];
        let comment = this.props.comment;
        let contacts = this.props.contacts;
        let geometry = SmartCityApi.geometryToGeoJSON([place], "Point");

        ; (async () => {
            this.setState({ progressVisible: true });
            let imagesUrls = [];

            for (let i = 0; i < selectedPhotos.length; i++) {

                const response = await fetch(SmartCityApi.GEOPORTAL_URL + '/makebase64photo', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    body: selectedPhotos[i]
                });

                if (response.ok) {
                    const uploadedUrl = await response.text();
                    imagesUrls.push(uploadedUrl);
                }
                else {
                    imagesUrls.push("error");
                }
            }

            let creatingObject = {
                layerId: category.layerName,
                styleId: category.styles["Новое"],
                name: place.address,
                text: comment,
                additionalInfo: contacts,
                reestrId: SmartCityApi.deviceId,
                images: imagesUrls,
                links: [],
                geometry: geometry,
                semanticValues: {}
            };

            SmartCityApi.mapCreateObject(creatingObject)
                .then(response => {
                    if (response.objectId) {
                        this.setState({ progressVisible: false });
                        this.props.navigateTo("problems_6");
                    }
                    else {
                        this.setState({ errorMessage: response.errorMessageRu, progressVisible: false });
                    }
                    this.sendingProblemMessage = false;
                });
        })();
    }


    render() {
        let category = this.props.category; /* || {
            "id": 1,
            "name": "Ямы на дорогах",
            "groupName": "Дороги",
            "layerName": "SM_1_2",
            "icon": "/api/v1/static/CategoryIcon_roadHoles.svg",
            "daysForProcessing": 30,
            "styles": {
                "Новое": "problem_road_hole_new",
                "Назначено": "problem_road_hole_work",
                "Исполнено": "problem_road_hole_done",
                "Отклонено": "problem_road_hole_rejected",
                "Промежуточный ответ": "problem_road_hole_leftoff",
                "Продлено": "problem_road_hole_work",
                "Обработка": "problem_road_hole_work",
                "Переназначено": "problem_road_hole_work",
                "Архивное": "problem_road_hole_done",
                "Просрочено": "problem_road_hole_overdue"
            }
        };*/
        let place = this.props.place;// || { latitude: 53.19050471302912, longitude: 50.098207787298, address: "Молодогвардейская ул., 120" };
        let selectedPhotos = this.props.selectedPhotos;// || [];
        let comment = this.props.comment;// || "комментарий";
        let contacts = this.props.contacts;// || "контакты";

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>
                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("problems_4")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>

                            <p style={commonStyles.floatingWindowHeader}>Проверьте содержание</p>


                            <div style={styles.mainField}>


                                {selectedPhotos && (selectedPhotos.length > 0) &&
                                    <div style={styles.galleryBlock}>
                                        <PhotoGallery
                                            style={styles.photoGallery}
                                            photoWidth={'100px'}
                                            photoHeight={'100px'}
                                            images={selectedPhotos}
                                            removeButtons={false}
                                            uriIsStatic={true}
                                        >
                                        </PhotoGallery>
                                    </div>
                                }
                                {(!(selectedPhotos && (selectedPhotos.length > 0))) &&
                                    <div style={styles.galleryBlockStub}>
                                        <div
                                            onClick={() => this.props.navigateTo("problems_3")}>
                                            <div style={styles.galleryBlockStubMessage}>
                                                <p style={styles.galleryBlockStubMessageText}>Для сотрудников очень важно видеть фото, часто это сильно ускоряет устранение проблемы. Пожалуйста, сделайте фото в хорошем освещении.</p>
                                            </div>
                                        </div>
                                    </div>
                                }


                                <div style={styles.headerTextBlock}>
                                    <p style={styles.headerAddressText}>{place.address}</p>
                                    <p style={styles.headerCoordinatesText}>{Math.round(place.latitude * 1000000) / 1000000 + " " + Math.round(place.longitude * 1000000) / 1000000}</p>
                                </div>

                                <div style={styles.graySplitterLine}></div>

                                <div style={styles.fieldInfoBlock}>
                                    <div style={styles.fieldInfoIconCircleComment}>
                                        <img alt="" style={styles.fieldInfoIconInsideCircle} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_InfoCommentIcon.svg`} />
                                    </div>
                                    <p style={styles.fieldInfoText}>{comment}</p>
                                    <div style={{ clear: "left" }}></div>
                                </div>

                                <div style={styles.fieldInfoBlock}>
                                    <div style={styles.fieldInfoIconCircleContacts}>
                                        <img alt="" style={styles.fieldInfoIconInsideCircle} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_RecordAudioIcon_2.svg`} />
                                    </div>
                                    <p style={styles.fieldInfoText}>{contacts}</p>
                                    <div style={{ clear: "left" }}></div>
                                </div>

                                <div style={styles.fieldInfoBlock}>
                                    <div style={styles.fieldInfoIconCircleCategory}>
                                        <img alt="" style={styles.fieldInfoIconInsideCircle} src={SmartCityApi.API_ROOT_URL + category.icon} />
                                    </div>
                                    <p style={styles.fieldInfoText}>{category.name}</p>
                                    <div style={{ clear: "left" }}></div>
                                </div>
                                {/*
    <View style={styles.fieldInfoBlock}>
        <View style={styles.recordAudioBox}>
            <View style={styles.recordAudioLeftIcon}>
                <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_4.svg')} />
            </View>
            <Text style={styles.recordAudioLabel}>00:22</Text>
            <View style={styles.recordAudioRightIconCircle}>
                <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_5.svg')} />
            </View>
        </View>
    </View>
*/}
                                <div style={styles.nextButtonsBlock}>
                                    <div
                                        onClick={() => this.sendProblemMessage()}>
                                        <div style={styles.nextStepTextButton}>
                                            <p style={styles.nextStepTextButtonLabel}>Отправить заявление</p>
                                        </div>
                                    </div>
                                </div>
                                {this.state.errorMessage && this.state.errorMessage.length > 0 &&
                                    <p style={styles.errorMessageText}>{this.state.errorMessage}</p>
                                }
                            </div>
                            {
                                this.state.progressVisible &&
                                <div style={commonStyles.preloaderBox}>
                                    <img alt="" style={commonStyles.preloaderImage} src={`${process.env.PUBLIC_URL}/_assets/preloader.gif`} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


ProblemsAddNewStep5CheckScreen.navigationOptions = {
    header: null
};

export default ProblemsAddNewStep5CheckScreen;


const styles = {

    whiteBack: {
        flex: 1,
        backgroundColor: '#FFFFFF'
    },

    mainField: {
        padding: "1px",
        flex: "1",
        overflow: "hidden",
        backgroundColor: '#DBECEC',
        paddingBottom: "30px"
    },

    headerTextBlock: {
        padding: "1px",
        marginTop: '0px',
        marginLeft: '0px',
        marginRight: '0px',
    },

    headerAddressText: {
        marginTop: '14px',
        marginLeft: '20px',
        marginRight: '10px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '18px',
        lineHeight: '20px',
        color: '#1E1E1E',
    },

    headerCoordinatesText: {
        marginTop: '9px',
        marginLeft: '20px',
        marginRight: '10px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#1E1E1E',
        opacity: 0.65
    },



    galleryBlock: {
        flex: 1,
        textAlign: "center",
        padding: '1px',
        marginBottom: '10px',
    },

    photoGallery: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        height: '110px'
    },

    galleryBlockStub: {
        flex: 1,
        textAlign: "center",
        padding: '1px',
        marginBottom: '60px',
    },

    galleryBlockStubMessage: {
        padding: '1px',
        marginTop: '26px',
        marginLeft: '37px',
        marginRight: '37px',
        marginBottom: '60px',
        width: '220px',
        borderRadius: '29px',
        backgroundColor: '#FFFFFF',
    },

    galleryBlockStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },

    otherFieldsBack: {
        marginTop: '-20px',
        marginLeft: '0px',
        marginRight: '0px',
        width: '320px',
        height: 'auto',
        backgroundColor: '#FFFFFF'
    },

    fieldInfoBlock: {
        marginTop: '0px',
        marginLeft: '0px',
        marginRight: '0px',

    },

    fieldInfoIconCircleComment: {
        float: "left",
        marginTop: '15px',
        marginLeft: '25px',
        width: '34px',
        height: '34px',
        borderRadius: '18px',
        backgroundColor: '#4252FF',
    },

    fieldInfoIconCircleContacts: {
        float: "left",
        marginTop: '15px',
        marginLeft: '25px',
        width: '34px',
        height: '34px',
        borderRadius: '18px',
        backgroundColor: '#FFAAAA',
    },

    fieldInfoIconCircleCategory: {
        float: "left",
        marginTop: '15px',
        marginLeft: '25px',
        width: '34px',
        height: '34px',
        borderRadius: '18px',
        backgroundColor: '#4CE5B1',
    },

    fieldInfoIconInsideCircle: {
        marginTop: '4px',
        marginLeft: '4px',
        width: '26px',
        height: '26px',
    },

    fieldInfoText: {
        float: "left",
        marginTop: '18px',
        marginBottom: '15px',
        marginLeft: '8px',
        marginRight: '20px',
        width: '200px',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.1px',
        color: '#1E1E1E',
    },

    recordAudioBox: {
        marginTop: '15px',
        marginLeft: '25px',
        borderRadius: '27px',
        borderWidth: 1,
        borderColor: '#BDBDBD',
        width: '268px',
        height: '40px',
    },

    recordAudioLabel: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '13px',
        marginRight: '13px',
        width: '180px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        color: '#060518',
    },

    recordAudioRightIconCircle: {
        position: 'absolute',
        right: '11px',
        width: '38px',
        height: '38px',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
    },

    recordAudioLeftIcon: {
        marginLeft: '16px',
        marginTop: '7px',
        marginBottom: '7px',
        width: '26px',
        height: '25px',
    },


    nextButtonsBlock: {
        height: '138px',
        position: 'absolute',
        bottom: '30px',
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF80',
        textAlign: 'center',
        padding: '1px',
    },


    nextStepTextButton: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#005687',
        textAlign: 'center'
    },

    nextStepTextButtonLabel: {
        paddingTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center',
        cursor: 'pointer'
    },

    graySplitterLine: {
        marginTop: '20px',
        backgroundColor: '#0000003C',
        height: "1px",
        marginLeft: '0px',
        marginRight: '10px',
    },

    errorMessageText: {
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#FF0000',
        textAlign: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '0px',
        marginBottom: '10px',
    },

};
