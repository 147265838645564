import React from 'react';
import SmartCityApi from '../services/SmartCityApi';
import CityMapLogic from './../screens/citymap/CityMapLogic';

export class TextSearchField extends React.Component {

    textValue = "";
    requestIndex = 0;

    componentWillMount() {
        this.handleSearchTextInput = this.handleSearchTextInput.bind(this);
    }

    handleSearchTextInput(e) {
        let text = (e.target ? e.target.value : "");
        this.textValue = text;

        if (this.props.beforeSearch) {
            this.props.beforeSearch();
        }

        let currentIndex = ++this.requestIndex;
        if (this.props.searchMethod) {
            this.props.searchMethod(text)
                .then(response => {
                    if (currentIndex < this.requestIndex) return;
                    this.props.afterFound(response);
                });
        }
        else {
            if ((text) && (text.length > 0)) {
                SmartCityApi.universalTextSearch(text, [0, 0], this.props.searchTypes)
                    .then(response => {
                        if (currentIndex < this.requestIndex) return;
                        var foundResults = [];
                        let counter = 1;
                        response.results.map(item => {
                            let mainText = "";
                            let subText = "";
                            let iconRequire = null;
                            if (item.type === "building") {
                                mainText = item.name;
                                subText = item.text;
                                iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundCommonPlaceIcon.svg`;
                            }
                            if (item.type === "street") {
                                mainText = item.name;
                                subText = item.text;
                                iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundCommonPlaceIcon.svg`;
                            }
                            if (item.type === "toponim") {
                                mainText = item.name;
                                subText = item.text;
                                iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/SearchToponym.svg`;
                            }
                            if (item.type === "transportStop") {
                                let parts = item.name.split(" на ");
                                mainText = parts[0];
                                subText = parts[1] || "";
                                iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundBusIcon.svg`;
                            }

                            subText = subText.replace('<br/>', ' ').replace('<br>', ' ');
                            if (this.props.subTextLengthLimit) {
                                if (subText.length > this.props.subTextLengthLimit) {
                                    subText = subText.substring(0, this.props.subTextLengthLimit) + '...';
                                }
                            }

                            let foundMarker = {
                                id: item.id,
                                name: mainText,
                                text: subText,
                                type: item.type,
                                geometry: item.place
                            };
        
                            foundResults.push(foundMarker);

                            counter = counter + 1;

                            return null;
                        });

                        if (this.props.afterFound) {
                            this.props.afterFound(foundResults);
                        }
                    });
            }
            else {
                if (this.props.afterFound) {
                    this.props.afterFound(null);
                }
            }
        }
    }

    render() {
        return (
            <div style={this.props.style}>
                <div style={styles.searchTextInputBlock}>
                    <input
                        type="text"
                        style={styles.searchTextInput}
                        placeholder={this.props.placeholder}
                        onFocus={() => { this.handleSearchTextInput(this.textValue) }}
                        onChange={this.handleSearchTextInput}
                    ></input>
                </div>
            </div >
        );
    }
}

export default TextSearchField;


const styles = {

    searchTextInputBlock: {
        alignItems: "center"
    },

    searchTextInput: {
        width: "100%",
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: "1px solid rgba(36, 46, 66, 0.25)",
        color: '#000000',
        borderRadius: '8px',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    }
};
