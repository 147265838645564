import React from 'react';

import commonStyles from './Common.styles';
import SmartCityApi from '../services/SmartCityApi';
import TextSearchResults from '../components/TextSearchResults';
import CityMapLayersListScreen from './citymap/CityMapLayersListScreen';


class HomeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      some: 1,
      foundResults: null,
      foundRoutesStops: [],
      foundAddresses: [],
    }
  }

  textValue = "";
  requestIndex = 0;
  mapLayers = null;

  handleSearchTextInput = async (event) => {
    let text = (event.target ? event.target.value : "");

    this.textValue = text;
    let currentIndex = ++this.requestIndex;
    text = text.toLowerCase();
    text = text.trim();

    if ((text) && (text.length > 0)) {

      this.props.transportLogic.searchByLocalTransportRefs(text)
        .then(results => {
          results.map((item) => {
            item.key = item.type + item.id;
            return null;
          });
          this.setState({ foundRoutesStops: results });
        });

      SmartCityApi.universalTextSearch(text, [0, 0], ["building", "street", "toponim"])
        .then(response => {
          if (currentIndex < this.requestIndex) return;
          var foundAddresses = [];
          let counter = 1;
          response.results.map(item => {
            let mainText = "";
            let subText = "";
            let iconRequire = null;
            if (item.type === "building") {
              mainText = item.name;
              subText = item.text;
              iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundCommonPlaceIcon.svg`;
            }
            if (item.type === "street") {
              mainText = item.name;
              subText = item.text;
              iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundCommonPlaceIcon.svg`;
            }
            if (item.type === "toponim") {
              mainText = item.name;
              subText = item.text;
              iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/SearchToponym.svg`;
            }
            if (item.type === "transportStop") {
              let parts = item.name.split(" на ");
              mainText = parts[0];
              subText = parts[1] || "";
              iconRequire = `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundBusIcon.svg`;
            }

            subText = subText.replace('<br/>', ' ').replace('<br>', ' ');
            if (this.props.subTextLengthLimit) {
              if (subText.length > this.props.subTextLengthLimit) {
                subText = subText.substring(0, this.props.subTextLengthLimit) + '...';
              }
            }

            foundAddresses.push({
              id: item.id,
              key: item.type + item.id,
              name: mainText,
              text: subText,
              iconRequire: iconRequire,
              type: item.type,
              geometry: item.place
            });
            counter = counter + 1;

            return null;
          });
          this.setState({ foundAddresses: foundAddresses });
        });

      let foundResults = [];

      this.mapLayers = (await SmartCityApi.mapLayers()).groups;

      await CityMapLayersListScreen.fixWMSLayersTransparency(this.mapLayers);

      for (let group of this.mapLayers) {
        for (let layer of group.layers) {
          if (layer.id) {
            let searchValue = layer.name.toLowerCase() + " " + (layer.tags ? layer.tags : "").toLowerCase();
            if (searchValue.indexOf(text) >= 0) {
              foundResults.push({
                id: layer.id,
                key: 'l' + layer.id,
                name: layer.name,
                text: group.name,
                iconRequire: `${process.env.PUBLIC_URL}/_assets/_icons/SearchLayer.svg`,
                type: "layer"
              });
            }
          }
        }
      }

      let problemsCategories = (await SmartCityApi.problemsCategories()).categories;
      for (let problem of problemsCategories) {
        let searchValue = problem.name.toLowerCase();
        if (searchValue.indexOf(text) >= 0) {
          foundResults.push({
            id: problem.id,
            key: 'p' + problem.id,
            name: problem.name,
            text: 'Проблемы',
            iconRequire: `${process.env.PUBLIC_URL}/_assets/_icons/SearchProblemCat.svg`,
            type: "problem"
          });
        }
      }

      this.setState({ foundResults: foundResults });

    }
    else {
      this.setState({
        foundResults: null,
        foundAddresses: [],
        foundRoutesStops: []
      });
    }
  }

  handleSearchItemPress = (item) => {
    switch (item.type) {
      case 'building':
        this.props.navigateTo("citymap", {
          foundMarker: item
        });
        this.props.foundMarkerFunction(item);
        break;

      case 'toponim':
        this.props.navigateTo("citymap", {
          foundMarker: item
        });
        this.props.foundMarkerFunction(item);
        break;
      case 'street':
        this.props.navigateTo("citymap", {
          foundMarker: item
        });
        this.props.foundMarkerFunction(item);
        return;
      case 'problem':
        this.props.navigateTo("problems", {
          problems_selectedCategory: item.id
        });
        break;
      case 'layer':
        for (let group of this.mapLayers) {
          for (let layer of group.layers) {
            if (layer.id === item.id) {
              layer.selected = true;
            }
          }
        }
        if (this.props.updateLayersFunction) {
          this.props.updateLayersFunction(this.mapLayers);
        }
        this.props.navigateTo("citymap", {
          layer: item
        });
        break;
      /*case 'scouting':
        this.props.navigateTo("/ScoutingStep2MainMap_Screen", {
          category: item.category
        });
        break;*/
      case 'transportStop':
        this.props.navigateTo("transport", {});
        this.props.startStopMode(item.stop, true);
        break;
      case 'transportRoute':
        this.props.navigateTo("transport", {});
        this.props.startRouteMode(item.route.KR_ID[0]);
        break;
      default:
        break;
    }
  }


  render() {

    let foundResults = this.state.foundResults;
    if (foundResults) {
      foundResults = foundResults.concat(this.state.foundRoutesStops);
      foundResults = foundResults.concat(this.state.foundAddresses);
    }

    if (!this.props.commonSettings) {
      return null;
    }

    let commonSettings = this.props.commonSettings;

    return (
      <div style={commonStyles.commonScreen}>

        <div style={commonStyles.textSearchField}>
          <input
            type="text"
            style={styles.searchTextInput}
            placeholder={"поиск"}
            onFocus={() => { this.handleSearchTextInput(this.textValue) }}
            onChange={this.handleSearchTextInput}
          ></input>
        </div>

        <div style={styles.graySplitterLine}>
        </div>

        {commonSettings.transport && commonSettings.transport.enabled &&
          <div onClick={(e) => this.props.navigateTo("transport")}>
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_TransportIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.transport.label || 'Общественный транспорт'}</p>
            </div>
          </div>
        }
        {commonSettings.citymap && commonSettings.citymap.enabled &&
          <div onClick={(e) => this.props.navigateTo("citymap")}>
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_CityMapIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.citymap.label || 'Жизнь города'}</p>
            </div>
          </div>
        }
        {(true || (commonSettings.problems && commonSettings.problems.enabled)) &&
          <div onClick={(e) => this.props.navigateTo("problems")}>
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ProblemsIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.problems.label || 'Сообщения о проблемах'}</p>
            </div>
          </div>
        }
        {false && this.state.username && commonSettings.scouting && commonSettings.scouting.enabled &&
          <div
            onClick={(e) => this.props.navigateTo("scouting")} >
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ScoutingIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.scouting.label || 'Инвентаризация'}</p>
            </div>
          </div>
        }
        {commonSettings.reminder && commonSettings.reminder.enabled &&
          <div onClick={(e) => this.props.navigateTo("reminder")}>
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ReminderIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.reminder.label || 'Напоминания'}</p>
            </div>
          </div>
        }
        {commonSettings.voting && commonSettings.voting.enabled &&
          <div onClick={(e) => this.props.navigateTo("voting")}>
            <div style={styles.listItem}>
              <div style={styles.listItemIconPlate}>
                <img alt="" style={styles.listItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_VotingIcon.svg`} />
              </div>
              <p style={styles.listItemText}>{commonSettings.voting.label || 'Рейтинговые голосования'}</p>
            </div>
          </div>
        }

        <div style={styles.graySplitterLine}>
        </div>
        {/*
          <div style={styles.teaserBlock}>
            <img alt="" style={styles.teaserImage} src={`${process.env.PUBLIC_URL}/_assets/_images/Home_Teasers_Elka.png`} />
            <p style={styles.teaserHeader}>И выпал снег!</p>
            <p style={styles.teaserSubHeader}>Контролируйте уборку снега вместе с департаментом благоустройства</p>
          </div>
          */}

        <TextSearchResults
          style={commonStyles.textSearchResults}
          foundResults={foundResults}
          onItemPress={(item) => this.handleSearchItemPress(item)} />


      </div >
    );
  }



}

export default HomeScreen;


const styles = {

  listItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: '15px',
    marginLeft: '26px',
    marginRight: '30px',
    marginTop: '14px',
    height: '49px',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: "hidden",
    paddingLeft: "40px",
    position: "relative"
  },

  listItemIconPlate: {
    position: "absolute",
    marginLeft: "-33px",
    float: "left",
    backgroundColor: "#0068A0",
    //marginLeft: '7px',
    marginTop: '6px',
    marginBottom: '5px',
    borderRadius: '12px',
    height: '38px',
    width: '38px'
  },

  listItemIcon: {
    display: "block",
    height: '22px',
    width: '22px',
    marginLeft: "8px",
    marginTop: "8px"
  },

  listItemText: {
    float: "left",
    marginTop: '16px',
    marginLeft: '18px',
    marginRight: '8px',
    fontFamily: 'SFProText-Regular',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.1px',
    color: '#3A4559',
    maxWidth: "215px",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },

  searchListItem: {
    marginLeft: '26px',
    marginRight: '30px',
    marginTop: '20px',
    marginBottom: '30px',
    height: '40px',
  },

  searchTextInput: {
    height: '40px',
    width: "100%",
    backgroundColor: '#FFFFFF',
    border: "1px solid rgba(36, 46, 66, 0.25)",
    borderRadius: '8px',
    fontFamily: 'SFProText-Regular',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '-0.2px',
    color: '#3A4559',
    textAlign: "center"
  },

  graySplitterLine: {
    marginTop: '20px',
    backgroundColor: '#0000003C',
    height: "1px",
    marginLeft: '26px',
    marginRight: '30px',
  },

  teaserBlock: {
    textAlign: "center"
  },

  teaserImage: {
    marginTop: '40px',
    height: '107px',
    width: '107px'
  },

  teaserHeader: {
    marginTop: '20px',
    marginLeft: '30px',
    marginRight: '30px',
    fontFamily: 'SFProText-Regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.2px',
    color: '#3A4559',
    textAlign: "center"
  },

  teaserSubHeader: {
    marginTop: '20px',
    marginLeft: '30px',
    marginRight: '30px',
    fontFamily: 'SFProText-Regular',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '-0.2px',
    color: '#3A4559',
    textAlign: "center"
  },


};
