import React from 'react';
import commonStyles from '../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';

class VotingHomeScreen extends React.Component {

    state = { frameUrl: null }

    componentWillMount() {
        SmartCityApi.performGetUsername((result) => {
            let frameUrl = 'https://opros.samadm.ru/geo_mobile/?sessionKey=' + result.sessionKey;
            this.setState({
                frameUrl: frameUrl
            });
        });
    }

    render() {

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>
                    {this.state.frameUrl &&
                        <div style={{height: "calc(100vh - 70px)"}} dangerouslySetInnerHTML={{
                            __html: '<iframe src="' + this.state.frameUrl + '" width="100%" height="100%"></iframe>'
                        }} />
                    }
                </div>
            </div>
        );
    }
}

export default VotingHomeScreen;


