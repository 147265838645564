import React from 'react';
import commonStyles from './../Common.styles';
import TextSearchField from '../../components/TextSearchField';
import TextSearchResults from '../../components/TextSearchResults';

class ProblemsAddNewStep2PlaceScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {            
            foundResults: null,
            progressVisible: false
        };
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {
        let category = this.props.category;/* || {
            "id": 1,
            "name": "Ямы на дорогах",
            "groupName": "Дороги",
            "layerName": "SM_1_2",
            "icon": "/api/v1/static/CategoryIcon_roadHoles.svg",
            "daysForProcessing": 30,
            "styles": {
                "Новое": "problem_road_hole_new",
                "Назначено": "problem_road_hole_work",
                "Исполнено": "problem_road_hole_done",
                "Отклонено": "problem_road_hole_rejected",
                "Промежуточный ответ": "problem_road_hole_leftoff",
                "Продлено": "problem_road_hole_work",
                "Обработка": "problem_road_hole_work",
                "Переназначено": "problem_road_hole_work",
                "Архивное": "problem_road_hole_done",
                "Просрочено": "problem_road_hole_overdue"
            }
        };*/

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>

                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("problems_1")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>

                            <p style={commonStyles.floatingWindowHeader}>Выберите место</p>

                            <TextSearchField
                                style={commonStyles.textSearchField}
                                placeholder="поиск по адресу"
                                beforeSearch={() => {
                                }}
                                afterFound={(foundResults) => {
                                    if (this._isMounted)
                                        this.setState({
                                            foundResults: foundResults
                                        });
                                }}
                            />

                            <TextSearchResults
                                style={styles.specialTextSearchResults}
                                foundResults={this.state.foundResults}
                                onItemPress={(item) => {
                                    if (this._isMounted)
                                        this.setState({
                                            foundResults: null
                                        });
                                    if (this.props.foundMarkerFunction) {
                                        this.props.foundMarkerFunction(item);
                                    }
                                }} />

                            <div style={styles.bottomAbsoluteBlock}>
                                <div style={styles.bottomHintBlock}>
                                    <p style={styles.bottomHintText}>{"Укажите место на карте, где вы видите проблему «" + category.name + "»"}</p>
                                </div>
                                {this.props.selectedMarkerAddress &&
                                    <div style={styles.bottomConfirmationBlock}>
                                        <p style={styles.bottomConfirmationText}>{this.props.selectedMarkerAddress}</p>

                                        <div
                                            onClick={() => this.props.navigateTo("problems_3",
                                                {
                                                    problems_place: {
                                                        latitude: this.props.selectedMarker.latitude,
                                                        longitude: this.props.selectedMarker.longitude,
                                                        address: this.props.selectedMarkerAddress
                                                    }
                                                })}>
                                            <div style={styles.bottomConfirmationBlackButton}>
                                                <p style={styles.bottomConfirmationBlackButtonLabel}>Далее</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>



                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

export default ProblemsAddNewStep2PlaceScreen;


const styles = {

    bottomAbsoluteBlock: {
        position: 'absolute',
        left: '16px',
        right: '16px',
        bottom: '50px',
        textAlign: "center"
    },

    bottomHintBlock: {
        textAlign: "center",       
        backgroundColor: '#F7F9FF',
        borderRadius: '8px',
        padding: '20px',   
    },

    bottomHintText: {
        marginTop: '12px',
        marginBottom: '12px',
        marginLeft: '23px',
        marginRight: '23px',
        textAlign: 'center',
        color: '#2D2D2D',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        opacity: 0.7
    },

    bottomConfirmationBlock: {
        padding: '10px',
        marginTop: '10px',
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
    },

    bottomConfirmationText: {
        marginTop: '18px',
        marginBottom: '13px',
        marginLeft: '28px',
        marginRight: '28px',
        textAlign: 'center',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
    },

    bottomConfirmationBlackButton: {        
        height: '40px',
        marginBottom: '13px',
        marginLeft: '13px',
        marginRight: '13px',        
        backgroundColor: '#242E42',
        borderRadius: '20px',
        cursor: "pointer",
        textAlign: 'center',
    },

    bottomConfirmationBlackButtonLabel: {
        paddingTop: '10px',
        marginTop: '10px',
        marginBottom: '11px',
        marginLeft: '16px',
        marginRight: '16px',
        textAlign: 'center',
        color: '#FFFFFF',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.2px',
    },

    specialTextSearchResults: {
        position: 'absolute',
        top: '140px',
        bottom: '0px',        
        left: '10px',
        right: '10px',
    },

};
