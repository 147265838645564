import xml2js from 'xml2js';
import Hash from './Hash.js';
require('es6-promise').polyfill();
require('isomorphic-fetch');

class SmartCityApi {
    static API_URL = "https://map.samadm.ru/api/v2/json";
    static API_ROOT_URL = "https://map.samadm.ru";
    static CLIENT_ID = 'test';
    static SECRET = 'test';
    static GEOPORTAL_URL = "https://map.samadm.ru";
    static STATUS_OK = "statusOk";
    static STATUS_ERROR = "statusError";
    static deviceId = 'NOT_DETECTED';

    static ALL_SEARCH_TYPES = [
        "building",
        "toponim",
        "transportStop",
        "transportRoute",
        "transport",
        "subscription"
    ];

    static _cache = {};

    static async cachedMethod(name, message, preferCache = true) {
        let key = name;
        if (!message) message = {};
        if (Object.keys(message).length > 0) key += '_' + JSON.stringify(message);

        let response = preferCache ? this._cache[key] : null;
        if (!response) {
            response = await this.request(name, message);
            this._cache[key] = response;
        }
        return response;
    }


    static async universalTextSearch(namePart, location = [0.0, 0.0], types = this.ALL_SEARCH_TYPES) {
        return this.request('universalTextSearch',
            {
                namePart: namePart,
                location: location,
                types: types
            });
    }

    static async universalPointSearch(location, types = this.ALL_SEARCH_TYPES) {
        return this.request('universalPointSearch',
            {
                location: location,
                types: types
            });
    }

    static async cacheVariants(preferCache = false) {
        return this.cachedMethod('cacheVariants', {}, preferCache);
    }

    static async commonSettings(preferCache = false) {
        return this.cachedMethod('commonSettings', {}, preferCache);
    }

    static async mapSources(preferCache = true) {
        return this.cachedMethod('mapSources', {}, preferCache);
    }

    static async mapLayers(preferCache = true) {
        return this.cachedMethod('mapLayers', {}, preferCache);
    }

    static async mapStyles(preferCache = true) {
        return this.cachedMethod('mapStyles', {}, preferCache);
    }

    static async mapSemantics(layerId, preferCache = false) {
        return this.cachedMethod('mapSemantics', { layerId: layerId }, preferCache);
    }

    static async mapLayerObjects(layer, preferCache = false) {
        return this.cachedMethod('mapLayerObjects', { layerId: layer.id }, preferCache && !layer.refreshTime);
    }

    static async mapLayerObjects(layer, preferCache = false, lastMapRegion, cachePartialLayer = true, extraSemantics) {
        let area = undefined;
        if (lastMapRegion) {
            area = {
                "epsg": "EPSG:4326",
                "left": lastMapRegion.longitude - lastMapRegion.longitudeDelta,
                "right": lastMapRegion.longitude + lastMapRegion.longitudeDelta,
                "top": lastMapRegion.latitude + lastMapRegion.latitudeDelta,
                "bottom": lastMapRegion.latitude - lastMapRegion.latitudeDelta
            };
            // console.log('mapLayerObjects area', area);
        }
        let result = await this.request('mapLayerObjects', {
            layerId: layer.id,
            area: layer.maxLoadingSquare ? area : undefined,
            extraSemantics: extraSemantics || layer.maxLoadingSquare && (cachePartialLayer ? true : undefined)
        });
        
        return result;
    }



    static async mapQueryObject(objectId, preferCache = false) {
        return this.cachedMethod('mapQueryObject', { objectId: objectId }, preferCache);
    }

    static async mapCreateObject(partialCompletedObject) {
        /*
        {
            layerId: layerId,
            styleId: styleId,
            name: name,
            text: text,
            reestrId: reestrId,
            images: images,
            links: links,
            geometry: geometry,
            semanticValues: semanticValues
        }
        */
        //надо бы чистить кэш от mapLayerObjects и problemsMessages
        return this.request('mapCreateObject', partialCompletedObject);
    }

    static async mapUpdateObject(partialCompletedObject) {
        /*
        {
            objectId: objectId,
            style: style,
            name: name,
            text: text,
            reestrId: reestrId,
            images: images,
            links: links,
            geometry: geometry,
            semanticValues: semanticValues
        }
        */
        //надо бы чистить кэш от mapLayerObjects, problemsMessages и mapQueryObject
        return this.request('mapUpdateObject', partialCompletedObject);
    }

    static async mapDeleteObject(objectId) {
        //надо бы чистить кэш от mapQueryObject
        return this.request('mapDeleteObject', { objectId: objectId });
    }

    static async problemsMessages(list = ["last", "own"], preferCache = false) {
        return this.cachedMethod('problemsMessages', { list: list }, preferCache);
    }

    static async problemsCategories(preferCache = true) {
        return this.cachedMethod('problemsCategories', {}, preferCache);
    }

    static async scoutingCategories(preferCache = true) {
        return this.cachedMethod('scoutingCategories', {}, preferCache);
    }

    static async reminderSubscribes(preferCache = false) {
        return this.cachedMethod('reminderSubscribes', {}, preferCache);
    }

    static async reminderMessages(subscribeId, dateFrom = null, preferCache = false) {
        let params = { subscribeId: subscribeId };
        if (dateFrom) params["dateFrom"] = dateFrom;
        return this.cachedMethod('reminderMessages', params, preferCache);
    }

    static async reminderCreateSubscribe(name, text, type, geometry) {
        return this.request('reminderCreateSubscribe', { name: name, text: text, type: type, geometry: geometry });
    }

    static performEmailAuth(login, password, callback) {

        fetch(this.GEOPORTAL_URL + "/user/empty.do?r=" + Math.random(),
            {
                credentials: "same-origin"
            })
            .then((response) => {
                if (!response.ok) throw Error(response.status);
                return response.text();
            })
            .then((response) => {
                if (response === "") {
                    callback({ status: this.STATUS_OK });
                }
                else {
                    var details = {
                        'j_username': login,
                        'j_password': password
                    };
                    var formBody = [];
                    for (var property in details) {
                        var encodedKey = encodeURIComponent(property);
                        var encodedValue = encodeURIComponent(details[property]);
                        formBody.push(encodedKey + "=" + encodedValue);
                    }
                    fetch(this.GEOPORTAL_URL + "/user/j_security_check",
                        {
                            method: 'POST',
                            credentials: "same-origin",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                            },
                            body: formBody.join("&"),
                        }).then((response) => {
                            if (!response.ok) throw Error(response.status);
                            return response.text();
                        })
                        .then((response) => {
                            if (response === "") {
                                callback({ status: this.STATUS_OK });
                            }
                            else {
                                xml2js.parseString(response, (error, result) => {
                                    console.log(result);

                                    if (error) {
                                        callback({ status: this.STATUS_ERROR, message: "Что-то пошло не так. Попробуйте еще раз." });
                                    }
                                    else {
                                        callback({ status: this.STATUS_ERROR, message: result.result.description[0] });

                                    }
                                });
                            }
                        })
                        .catch(error => callback({ status: this.STATUS_ERROR, message: error }));
                }
            })
            .catch(error => callback({ status: this.STATUS_ERROR, message: error }));
    }

    static performGetUsername(callback) {
        fetch(this.GEOPORTAL_URL + "/auths/user?r=" + Math.random(),
            {
                credentials: "same-origin"
            })
            .then((response) => {
                if (!response.ok) throw Error(response.status);
                return response.text();
            })
            .then((response) => {
                var parser = new xml2js.Parser();
                parser.parseString(response, (error, result) => {
                    if (error) {
                        callback({ status: this.STATUS_ERROR });
                    }
                    else {
                        if (result.result.$.authorized === "true") {
                            callback({
                                status: this.STATUS_OK,
                                authorized: true,
                                username: result.result.$.username,
                                nickname: result.result.$.nickname,
                                userId: result.result.$.userId,
                                sessionKey: result.result.$.sessionKey
                            });
                        }
                        else {
                            callback({
                                status: this.STATUS_OK,
                                authorized: false,
                                sessionKey: result.result.$.sessionKey
                            });
                        }
                    }
                });
            })
            .catch(error => callback({ status: this.STATUS_ERROR, message: error }));
    }

    static performLogout(callback) {

        fetch(this.GEOPORTAL_URL + "/auth/logout/",
            {
                credentials: "same-origin"
            })
            .then((response) => {
                if (!response.ok) throw Error(response.status);
                return response.text();
            })
            .then((response) => {
                callback({ status: this.STATUS_OK });
            })
            .catch(error => callback({ status: this.STATUS_ERROR, message: error }));
    }

    static async request(method, message) {
        if (message == null) message = {};
        message.method = method;
        message.deviceid = this.deviceId;
        //message.USERID = 26;
        message = JSON.stringify(message);
        // console.log(message);
        let authKey = Hash.SHA1(message + this.SECRET);
        return fetch(this.API_URL, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'clientId=' + this.CLIENT_ID + '&authKey=' + authKey + '&message=' + message
        }).then((response) => {
            if (!response.ok) throw Error(response.status);
            return response.text();
        }).then(response => JSON.parse(response));
    }

    static async getConnections(id, types = ['7']) {
        let req = JSON.parse('{"method":"getIncoming","content":{"ID":"","returnTypes":"true","types":["7"],"count":0,"returnIDs":"true","startIndex":0,"returnExternalIDs":"true"},"userAgent":"userAgent"}');
        req.content.ID = id + '';
        req.content.types = types;
        let response = await fetch('https://map.samadm.ru/api/connections',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: 'message=' + JSON.stringify(req)
            })
        console.log(JSON.stringify(req));
        if (!response.ok) throw Error(response.status);
        return response.json();
    }

    //вспомогательные методы

    static parseDate(dateString) {
        var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
        var dateArray = reggie.exec(dateString);
        var dateObject = new Date(
            (+dateArray[1]),
            (+dateArray[2]) - 1, // Careful, month starts at 0!
            (+dateArray[3]),
            (+dateArray[4]),
            (+dateArray[5]),
            (+dateArray[6])
        );
        return dateObject;
    }

    static prettyDate(date) {
        var monthNames = [
            "января", "февраля", "марта",
            "апреля", "мая", "июня", "июля",
            "августа", "сентября", "октября",
            "ноября", "декабря"
        ];
        return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear();
    }

    static prettyDateTime(date) {
        var monthNames = [
            "января", "февраля", "марта",
            "апреля", "мая", "июня", "июля",
            "августа", "сентября", "октября",
            "ноября", "декабря"
        ];
        let minutes = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
        return date.getDate() + " " + monthNames[date.getMonth()] + " " + date.getFullYear() + " " + date.getHours() + ":" + minutes;
    }

    static parseGeometry(geometryStr = '') {
        geometryStr = geometryStr.substring(geometryStr.indexOf('(') + 1, geometryStr.length - 1);
        let tokens = geometryStr.split(' ');
        let result = [];
        for (let i = 0; i < tokens.length; i += 2) {
            result.push(tokens[i + 1], tokens[i]);
        }
        return result;
    }

    static geometryToString(geometry, type = "Point") {
        if (type === "Point") {
            return 'POINT(' + geometry[0].longitude + ' ' + geometry[0].latitude + ')';
        }
        if (type === "LineString") {
            let points = [];
            geometry.map(item => { points.push(item.longitude + ' ' + item.latitude); return null; });
            return 'LINESTRING(' + points.join(", ") + ')';
        }
        if (type === "Polygon") {
            let points = [];
            geometry.map(item => { points.push(item.longitude + ' ' + item.latitude); return null; });
            return 'POLYGON((' + points.join(", ") + '))';
        }
        return '';
    }

    static geometryToGeoJSON(geometry, type = "Point") {
        let result = {type: type, coordinates: []};

        if (type === "Point") {
            let point = geometry[0];
            // console.log(point);
            result.coordinates = [point.longitude, point.latitude];
        }
        if (type === "LineString") {
            for (let point of geometry) {
                result.coordinates.push([point.longitude, point.latitude]);
            }
        }
        if (type === "Polygon") {
            let contour = [];
            for (let point of geometry) {
                contour.push([point.longitude, point.latitude]);
            }
            contour.push([geometry[0].longitude, geometry[0].latitude]);
            result.coordinates.push(contour);
        }

        // console.log(result);

        return result;
    }

    static colorWithOpacity(color, opacity) {
        let opacityInt = Math.round(255 * opacity);
        return color + opacityInt.toString(16);

    }
}

export default SmartCityApi;