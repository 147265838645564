import React from 'react';

import commonStyles from './../Common.styles';
//import * as ImagePicker from 'expo-image-picker';

import PhotoGallery from '../../components/PhotoGallery';

class ProblemsAddNewStep3PhotosScreen extends React.Component {

    state = {
        selectedPhotos: this.props.selectedPhotos
    };

    componentDidMount() {

    }

    removeImageFromGallery = (url) => {
        var lastSelectedPhotos = this.state.selectedPhotos || [];
        var index = null;
        for (var i = 0; i < lastSelectedPhotos.length; i++) {
            if (lastSelectedPhotos[i] === url) {
                index = i;
            }
        }
        if (index != null) {
            lastSelectedPhotos.splice(index, 1);
        }
        this.setState({
            selectedPhotos: lastSelectedPhotos
        });
    }


    handleFileChange = (event) => {
        let files = event.target.files;

        if (files && files[0]) {
            var reader = new FileReader();
            //reader.onloadstart = () => this.setState({ loading: true });

            reader.onload = (event) => {

                console.log(event.target.result);
                
                var lastSelectedPhotos = this.state.selectedPhotos || [];
                lastSelectedPhotos.push(event.target.result);
                this.setState({
                    selectedPhotos: lastSelectedPhotos
                });
            };

            reader.readAsDataURL(files[0]);
        }
    }


    render() {
        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>

                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("problems_2")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>

                            <p style={commonStyles.floatingWindowHeader}>Добавьте фото</p>

                            <div style={styles.centralBlueBack}>
                                <div style={styles.addPhotoButtonsBlock}>

                                    <div>
                                        <input
                                            id="photo-upload"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                            onChange={this.handleFileChange}
                                            style={{ display: "none" }}
                                        />
                                        <label htmlFor="photo-upload">
                                            <div style={styles.addPhotoFromGalleryButton}>
                                                <div >
                                                    <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_AddPhotoFromGalleryIcon.svg`} style={styles.addPhotoFromGalleryButtonIcon} />
                                                </div>
                                                <p style={styles.addPhotoFromGalleryLabel}>Выбрать фотографию с диска</p>
                                            </div>
                                        </label>

                                    </div>
                                </div>

                                {this.state.selectedPhotos && (this.state.selectedPhotos.length > 0) &&
                                    <div style={styles.galleryBlock}>
                                        <PhotoGallery
                                            style={styles.photoGallery}
                                            photoWidth={'100px'}
                                            photoHeight={'100px'}
                                            images={this.state.selectedPhotos}
                                            removeButtons={true}
                                            uriIsStatic={true}
                                            removeClickHandler={(url) => this.removeImageFromGallery(url)}
                                        ></PhotoGallery>
                                    </div>
                                }
                                {(!(this.state.selectedPhotos && (this.state.selectedPhotos.length > 0))) &&
                                    <div style={styles.galleryBlock}>
                                        <div style={styles.galleryBlockStubMessage}>
                                            <p style={styles.galleryBlockStubMessageText}>Для сотрудников очень важно видеть фото, часто это сильно ускоряет устранение проблемы. Пожалуйста, сделайте фото в хорошем освещении.</p>
                                        </div>
                                        <div style={styles.galleryBlockManDrawingBlock}>
                                            <img alt="" style={styles.galleryBlockManDrawing} src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_ManDrawing.svg`} />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={styles.nextButtonsBlock}>
                                <div
                                    onClick={() => this.props.navigateTo("problems_4",
                                        {
                                            problems_selectedPhotos: this.state.selectedPhotos
                                        })}
                                >
                                    <div style={(this.state.selectedPhotos && (this.state.selectedPhotos.length > 0)) ? styles.nextStepTextButton : styles.nextStepTextButtonUnactive}>
                                        <p style={styles.nextStepTextButtonLabel}>Далее</p>
                                    </div>
                                </div>
                                <div
                                    onClick={() => this.props.navigateTo("problems_4",
                                        {
                                            problems_selectedPhotos: null
                                        })}>
                                    <div style={styles.nextStepWithoutPhotosTextButton}>
                                        <p style={styles.nextStepWithoutPhotosTextButtonLabel}>Пропустить</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ProblemsAddNewStep3PhotosScreen;


const styles = {

    centralBlueBack: {
        marginTop: '0px',
        paddingTop: '1px',
        width: "100%",
        background: "#DBECEC",
        boxShadow: "inset 0px 2px 9px rgba(0, 0, 0, 0.16)"
    },

    addPhotoButtonsBlock: {
        marginTop: '40px',
        width: '100%',
        alignItems: 'center'
    },

    addPhotoFromGalleryButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        width: '248px',
        height: '40px',
        borderRadius: '44px',
        backgroundColor: '#005687',
        textAlign: 'center',
        cursor: "pointer"
    },

    addPhotoFromGalleryButtonIcon: {
        float: "left",
        marginLeft: '22px',
        marginTop: '12px',
        width: '16px',
        height: '16px',
        textAlign: 'center'
    },

    addPhotoFromGalleryLabel: {
        float: "left",
        width: '200px',
        marginLeft: '10px',
        marginTop: '14px',
        fontFamily: 'SFProText-Regular',
        fontSize: '11px',
        lineHeight: '13px',
        color: '#FFFFFF',
        textAlign: "left"
    },

    galleryBlock: {
        flex: 1,
        textAlign: "center",
        padding: '1px',
        marginBottom: '60px',
    },

    galleryBlockStubMessage: {
        padding: '1px',
        marginTop: '26px',
        marginLeft: '37px',
        marginRight: '37px',
        marginBottom: '60px',
        borderRadius: '29px',
        backgroundColor: '#FFFFFF',
    },

    galleryBlockStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },

    galleryBlockManDrawingBlock: {
        position: 'absolute',
        left: 0,
        top: '200px',
    },

    galleryBlockManDrawing: {
        width: '147px',
        height: '297px'
    },

    photoGallery: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '57px',
        marginBottom: '20px',
        height: '110px'
    },

    nextButtonsBlock: {
        height: '138px',
        position: 'absolute',
        bottom: '30px',
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF80',
        textAlign: 'center',
        padding: '1px',
    },

    nextStepTextButton: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#005687',
        textAlign: 'center',
        cursor: 'pointer'
    },

    nextStepTextButtonUnactive: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#B6CEDB',
        textAlign: 'center'
    },

    nextStepTextButtonLabel: {
        paddingTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center',
    },

    nextStepWithoutPhotosTextButton: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#FFFFFF',
        boxShadow: "0px 8px 24px rgba(0, 86, 135, 0.202852)",
        textAlign: 'center',
        cursor: 'pointer'
    },

    nextStepWithoutPhotosTextButtonLabel: {
        paddingTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        color: '#1106FF',
        textAlign: 'center',
    },


};
