import SmartCityApi from './../../services/SmartCityApi';
import CityMapLogic from './../citymap/CityMapLogic';

class ReminderLogic {

    app = null;

    constructor(_app) {
        this.app = _app;
    }

    updateShowingSubscriptions = (subscribes) => {
        subscribes.map(item2 => {
            item2.subscripted = true;
            return null;
        });

        this.app.setState({
            reminder_subscribes: subscribes
        });
    }

    handleMarkerPress = (item) => {
        console.log(item);

        if (item.subscripted) {
            this.app.setState({
                cityMapPlaceMessages: null,
                reminder_selectedSubscribe: item
            });
        }
        SmartCityApi.reminderMessages(item.id)
            .then(response => {
                if (response.messages) {
                    response.messages.map(item2 => {
                        item2.preparedDateStart = ((item.dateStart && (item.dateStart.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateStart)) : "неизвестно");
                        item2.preparedDateEnd = ((item.dateEnd && (item.dateEnd.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateEnd)) : "неизвестно");
                        return null;
                    });
                }
                this.app.setState({
                    cityMapPlaceMessages: response.messages
                });
                return null;
            });

    }

    showSubscriptionMarker = (subscription) => {
        console.log(subscription);
        this.app.setState({
            mapPosition: [subscription.place.coordinates[1], subscription.place.coordinates[0]],
            mapZoom: 16
        });
    }

    createSubscription = (object) => {
        SmartCityApi.reminderCreateSubscribe(
            object.name,
            object.text,
            object.type,
            object.geometry
        )
            .then(response => {
                if (response.status === "success") {
                    let newSubscribe = {
                        "id": response.subscribeId,
                        "name": object.name,
                        "text": object.text,
                        "type": object.type,
                        "place": object.geometry,
                        "subscripted": true
                    };
                    let subscribes2 = this.app.state.reminder_subscribes;
                    subscribes2.push(newSubscribe);

                    this.app.setState({
                        reminder_subscribes: subscribes2,
                        reminder_selectedSubscribe: newSubscribe,
                        foundMarker: null
                    });
                }
                else {
                    //ToastAndroid.show(response.errorMessageRu, ToastAndroid.SHORT);
                }
            });
    }

    removeSubscription = (object) => {
        console.log(object);

        SmartCityApi.mapDeleteObject(object.id)
            .then(response => {
                let subscribes2 = this.app.state.reminder_subscribes;
                let index = null;
                for (let i = 0; i < subscribes2.length; i++) {
                    if (subscribes2[i].id === object.id) {
                        index = i;
                    }
                }
                if (index != null) {
                    subscribes2.splice(index, 1);
                }

                let newFoundMarker = {
                    "id": object.id,
                    "name": object.name,
                    "text": object.text,
                    "type": object.type,
                    "geometry": object.place
                };

                let style = this.app.mapStyles['simplepoint'];
                if (style) newFoundMarker.style = style;
                
                newFoundMarker = CityMapLogic.createStyleLeafletIcon(style, newFoundMarker);

                this.app.setState({
                    reminder_subscribes: subscribes2,
                    reminder_selectedSubscribe: null,
                    foundMarker: newFoundMarker
                });
            });
    }

}

export default ReminderLogic;