


class BaseParams {

    static GEOPORTAL_URL = "https://map.samadm.ru";
    static API_VERSION = '2';
    static API_ROOT_URL = BaseParams.GEOPORTAL_URL;
    static API_URL = BaseParams.API_ROOT_URL + "/api/v" + BaseParams.API_VERSION + "/json";
    static TILES_MASS_LOAD_URL = BaseParams.API_ROOT_URL + '/api/v' + BaseParams.API_VERSION + '/massLoad';
    static TRANSPORT_ICON_URL = BaseParams.API_ROOT_URL + '/api/v' + BaseParams.API_VERSION + '/transportIcon';
    static CONNECTIONS_API_URL = BaseParams.API_ROOT_URL + '/api/connections';
    static TILES_TO_MERCATOR_URL = BaseParams.API_ROOT_URL + "/tms/xyzToMercator.php";
    static STYLE_PREVIEW_URL = BaseParams.API_ROOT_URL + '/wfs/styles/legend';
    static STYLE_LEGEND_URL = BaseParams.API_ROOT_URL + '/wfs/styles/legend';
    static USER_EMPTY_URL = BaseParams.GEOPORTAL_URL + "/user/empty.do?r=";
    static PASS_RECOVER_URL = BaseParams.GEOPORTAL_URL + "/auth/passrecover.do";
    static SECURITY_CHECK_URL = BaseParams.GEOPORTAL_URL + "/user/j_security_check";
    static AUTHS_USER_URL = BaseParams.GEOPORTAL_URL + "/auths/user?r=";
    static AUTH_LOGOUT_URL = BaseParams.GEOPORTAL_URL + "/auth/logout/";
    static MAKE_FILE_PHOTO_URL = BaseParams.GEOPORTAL_URL + '/makefilephoto';
    static API_CLIENT_ID = 'test';
    static API_SECRET = 'test';
    static CACHE_ROOT = 'samara_map_cache';
    
    constructor() {
        
    }
}


export default BaseParams;