import xml2js from 'xml2js';

require('es6-promise').polyfill();
require('isomorphic-fetch');

class Classifiers {
    static CLASSIFIERS_URL = "https://tosamara.ru/api/v2/classifiers/";
    static CLASSIFIERS = ['stopsFullDB.xml', 'routes.xml', 'routesAndStopsCorrespondence.xml'];

    static stops = null;
    static routes = null;
    static routesAndStopsCorrespondence = null;

    static async getRouteSpatialInfo(KR_ID) {
        let routes = await this.getRoutesAndStopsCorrespondence();
        let route = null;
        for (let r of routes) {
            if (KR_ID === r.KR_ID[0]) {
                route = r;
                break;
            }
        }
        return route;
    }

    static async getRouteGeometry(KR_ID) {
        let route = await this.getRouteSpatialInfo(KR_ID)
        if (route === null) return [];
        //console.log('geometry=' + route.geometry[0]);
        let geomStrs = route.geometry[0].split(' ');
        //console.log('geomStrs=' + geomStrs);
        let geom = [];

        for (let str of geomStrs) {
            let coords = str.split(',');
            //console.log('coords=' + coords);
            geom.push({
                latitude: parseFloat(coords[0]),
                longitude: parseFloat(coords[1])
            });
        }
        //console.log('geom=' + geom);
        return geom;
    }

    static async getStop(KS_ID) {
        let stops = await this.getStops();
        for (let stop of stops) {
            if (stop.KS_ID[0] === KS_ID) return stop;
        }
        return null;
    }


    static async getStops() {
        if (this.stops == null) {
            //const json = await fetch(`${process.env.PUBLIC_URL}/classifiers/stopsFullDB.xml.json`).then((r) => r.json());
            //this.stops = json.stops.stop;
        
            let xml = await fetch(`${process.env.PUBLIC_URL}/classifiers/stopsFullDB.xml`).then(r => r.text());
            let json = await xml2js.parseStringPromise(xml);
            this.stops = json.stops.stop;
        }
        return this.stops;
    }

    static async getRoutesAndStopsCorrespondence() {
        if (this.routesAndStopsCorrespondence == null) {
            //const json = await fetch(`${process.env.PUBLIC_URL}/classifiers/routesAndStopsCorrespondence.xml.json`).then((r) => r.json());
            //this.routesAndStopsCorrespondence = json.routes.route;

            let xml = await fetch(`${process.env.PUBLIC_URL}/classifiers/routesAndStopsCorrespondence.xml`).then(r => r.text());
            let json = await xml2js.parseStringPromise(xml);
            this.routesAndStopsCorrespondence = json.routes.route;
        }
        return this.routesAndStopsCorrespondence;
    }

    static async getRoutes() {
        if (this.routes == null) {
            //const json = await fetch(`${process.env.PUBLIC_URL}/classifiers/routes.xml.json`).then((r) => r.json());
            //this.routes = json.routes.route;

            let xml = await fetch(`${process.env.PUBLIC_URL}/classifiers/routes.xml`).then(r => r.text());
            let json = await xml2js.parseStringPromise(xml);
            this.routes = json.routes.route;
        }
        return this.routes;
    }

    static shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    static async searchInRegion(region, limit = 9999) {

        let stops = await this.getStops();
        let foundStops = [];
        let maxLat = region.latitude + region.latitudeDelta / 2;
        let minLat = region.latitude - region.latitudeDelta / 2;
        let maxLng = region.longitude + region.longitudeDelta / 2;
        let minLng = region.longitude - region.longitudeDelta / 2;
        for (let stop of stops) {
            if (stop.latitude[0] >= minLat && stop.latitude[0] <= maxLat &&
                stop.longitude[0] >= minLng && stop.longitude[0] <= maxLng) {
                foundStops.push(stop);
            }
        }
        if (foundStops.length > limit) {
            Classifiers.shuffleArray(foundStops);
            foundStops = foundStops.slice(0, limit);
        }
        return foundStops;
    }

    static async search(term) {
        if (!term || term === '') return [];

        term = term.toLocaleLowerCase();
        let stops = await this.getStops();
        let routes = await this.getRoutes();
        let foundRoutes = [];
        for (let route of routes) {
            if (route.number[0].startsWith(term))
                foundRoutes.push(route);
            if (foundRoutes.length > 10) break;
        }
        //throw Error();
        let foundStops = [];
        for (let stop of stops) {
            if (stop.title[0].toLocaleLowerCase().indexOf(term) >= 0)
                foundStops.push(stop);
            if (foundStops.length > 10) break;
        }
        //ToastAndroid.show('found ' + foundStops.length + ' stops', ToastAndroid.SHORT);
        return { stops: foundStops, routes: foundRoutes };

    }


}

export default Classifiers;