import React from 'react';

import commonStyles from './../Common.styles';
import PhotoGallery from '../../components/PhotoGallery';

class ProblemsAddNewStep4CommentsScreen extends React.Component {

    state = {
        comment: null,
        contacts: null
    };

    render() {
        let place = this.props.place; // || { latitude: 53.19050471302912, longitude: 50.098207787298, address: "Молодогвардейская ул., 120" };
        let selectedPhotos = this.props.selectedPhotos;

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>
                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("problems_3")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>

                            <p style={commonStyles.floatingWindowHeader}>Добавьте пояснения</p>

                            <div style={styles.mainField}>

                                <div style={styles.headerTextBlock}>
                                    <div style={styles.headerPlaceIcon}>
                                        <img alt="" src={`${process.env.PUBLIC_URL}/_assets/_icons/Problems_WhitePlaceIcon.svg`} />
                                    </div>
                                    <p style={styles.headerAddressText}>{place.address}</p>
                                    <p style={styles.headerCoordinatesText}>{Math.round(place.latitude * 1000000) / 1000000 + " " + Math.round(place.longitude * 1000000) / 1000000}</p>
                                </div>

                                <div style={styles.galleryWhiteBack}>
                                    {selectedPhotos && (selectedPhotos.length > 0) &&
                                        <div style={styles.galleryBlock}>
                                            <PhotoGallery
                                                style={styles.photoGallery}
                                                photoWidth={'100px'}
                                                photoHeight={'100px'}
                                                images={selectedPhotos}
                                                removeButtons={false}
                                                uriIsStatic={true}
                                            ></PhotoGallery>
                                        </div>
                                    }
                                    {(!(selectedPhotos && (selectedPhotos.length > 0))) &&
                                        <div style={styles.galleryBlockStub}>
                                            <div
                                                onClick={() => this.props.navigateTo("problems_3")}>
                                                <div style={styles.galleryBlockStubMessage}>
                                                    <p style={styles.galleryBlockStubMessageText}>Для сотрудников очень важно видеть фото, часто это сильно ускоряет устранение проблемы. Пожалуйста, сделайте фото в хорошем освещении.</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={styles.otherFieldsBack}>
                                    <div style={styles.fieldBlock}>
                                        <p style={styles.fieldLabel}>Комментарий</p>
                                        <div style={styles.fieldTextInputBox}>
                                            <input
                                                type="text"
                                                style={styles.fieldTextInput}
                                                placeholder={"Комментарий"}
                                                onChange={(e) => this.setState({ comment: e.target.value })}
                                            ></input>
                                        </div>
                                    </div>

                                    <div style={styles.fieldBlock}>
                                        <p style={styles.fieldLabel}>Контактные данные</p>
                                        <div style={styles.fieldTextInputBox}>
                                            <input
                                                type="text"
                                                style={styles.fieldTextInput}
                                                placeholder={"Контактные данные"}
                                                onChange={(e) => this.setState({ contacts: e.target.value })}
                                            ></input>
                                        </div>
                                        <p style={styles.fieldHint}>Заполняя это поле, вы разрешаете Администрации г.о. Самара обрабатывать ваши персональные данные и связываться с вами по теме вашего сообщения.</p>
                                    </div>
                                    {/*
                                        <View>
                                            <View style={styles.fieldBlock}>
                                                <View style={styles.recordAudioBox}>
                                                    <Text style={styles.recordAudioLabel}>Нажмите для записи аудио</Text>
                                                    <View style={styles.recordAudioRightIconCircle}>
                                                        <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_1.svg')} />
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={styles.fieldBlock}>
                                                <View style={styles.recordAudioBox}>
                                                    <View style={styles.recordAudioLeftIcon}>
                                                        <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_3.svg')} />
                                                    </View>
                                                    <Text style={[styles.recordAudioLabel, { color: '#8C8C8C' }]}>Удерживайте кнопку...</Text>
                                                    <View style={[styles.recordAudioRightIconCircle, { backgroundColor: '#1106FF' }]}>
                                                        <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_2.svg')} />
                                                    </View>
                                                </View>
                                            </View>

                                            <View style={styles.fieldBlock}>
                                                <View style={styles.recordAudioBox}>
                                                    <View style={styles.recordAudioLeftIcon}>
                                                        <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_4.svg')} />
                                                    </View>
                                                    <Text style={styles.recordAudioLabel}>00:22</Text>
                                                    <View style={styles.recordAudioRightIconCircle}>
                                                        <Image source={require('./../../assets/icons/Problems_RecordAudioIcon_5.svg')} />
                                                    </View>
                                                </View>
                                            </View>

                                        </View>
                                    */}
                                </div>

                                <div style={styles.nextButtonsBlock}>
                                    <div
                                        onClick={() => this.props.navigateTo("problems_5",
                                            {
                                                problems_comment: this.state.comment,
                                                problems_contacts: this.state.contacts,
                                                problems_audio: null
                                            })}>
                                        <div style={styles.nextStepTextButton}>
                                            <p style={styles.nextStepTextButtonLabel}>Далее</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProblemsAddNewStep4CommentsScreen;


const styles = {

    mainField: {
        padding: "1px",
        flex: "1",
        overflow: "hidden",
        //overflowX: "hidden",  
        backgroundColor: '#DBECEC'              
    },

    headerTextBlock: {
        padding: "1px",
        marginTop: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        backgroundColor: '#005687'
    },

    headerAddressText: {
        marginTop: '14px',
        marginLeft: '60px',
        marginRight: '10px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '18px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },

    headerCoordinatesText: {
        marginTop: '9px',
        marginLeft: '60px',
        marginRight: '10px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#FFFFFF',
        opacity: 0.65
    },

    headerPlaceIcon: {
        position: 'absolute',
        top: '25px',
        left: '30px',
        width: '13px',
        height: '16px'
    },

    galleryWhiteBack: {
        marginTop: '20px',
        marginLeft: '26px',
        marginRight: '26px',
        width: '320px',
        height: '152px',
        borderRadius: '20px',
        backgroundColor: '#FFFFFF'
    },



    galleryBlock: {
        flex: 1,
        textAlign: "center",
        padding: '1px',
        marginBottom: '60px',         
    },

    galleryBlockStub: {
        flex: 1,
        textAlign: "center",
        padding: '1px',
        marginBottom: '60px',       
    },

    galleryBlockStubMessage: {
        padding: '1px',
        marginTop: '26px',
        marginLeft: '37px',
        marginRight: '37px',
        marginBottom: '60px',
        width: '220px',
        borderRadius: '29px',
        backgroundColor: '#FFFFFF',
    },

    galleryBlockStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },

    photoGallery: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        height: '110px'
    },



    otherFieldsBack: {
        marginTop: '-20px',
        marginLeft: '0px',
        marginRight: '0px',
        width: '100%',
        height: 'auto',       
    },

    fieldBlock: {
        marginTop: '35px',
        marginLeft: '26px',
        marginRight: '26px'
    },

    fieldLabel: {
        marginLeft: '5px',
        marginTop: '15px',
        marginBottom: '5px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#1E1E1E',
    },

    fieldTextInputBox: {
        marginTop: '3px',
        alignItems: "center"
    },

    fieldTextInput: {
        width: '100%',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: "1px solid rgba(36, 46, 66, 0.25)",
        color: '#060518',
        borderRadius: '27px',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    },

    fieldHint: {
        marginTop: '6px',
        fontFamily: 'SFProText-Regular',
        fontSize: '9px',
        lineHeight: '12px',
        color: '#8C8C8C',
    },


    recordAudioBox: {
        borderRadius: '27px',
        borderWidth: 1,
        borderColor: '#BDBDBD',
        width: '268px',
        height: '40px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },

    recordAudioLabel: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '13px',
        marginRight: '13px',
        width: '180px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        color: '#060518',
    },

    recordAudioRightIconCircle: {
        position: 'absolute',
        right: '11px',
        width: '38px',
        height: '38px',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
    },

    recordAudioLeftIcon: {
        marginLeft: '16px',
        marginTop: '7px',
        marginBottom: '7px',
        width: '26px',
        height: '25px',
        justifyContent: 'center',
        alignItems: 'center',
    },

    nextButtonsBlock: {
        height: '68px',
        position: 'absolute',
        bottom: '30px',
        left: 0,
        right: 0,
        backgroundColor: '#FFFFFF80',
        textAlign: 'center',
        padding: '1px',
    },

    
    nextStepTextButton: {
        width: '264px',
        height: '40px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        borderRadius: '44px',
        backgroundColor: '#005687',
        textAlign: 'center',
        cursor: "pointer",
    },

    nextStepTextButtonLabel: {
        paddingTop: '10px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center',
    },




};
