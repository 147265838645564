import React from 'react';
import { Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet';
import BaseParams from './BaseParams';
import SmartCityApi from './SmartCityApi';

class MapServices {

    initialRegion = {
        latitude: 53.194578,
        longitude: 50.105626,
        latitudeDelta: 0.1,
        longitudeDelta: 0.1,
        zoomLevel: 13
    };

    static unselectedOpacity = 0.4;

    target = null;
    mapView = null;
    geolocation = null;

    constructor(_app) {
        this.app = _app;
    }

    bindAll(target) {
        this.target = target;

        this.startGeolocation = this.startGeolocation.bind(this);
        this.handleGeolocationGetCurrentPosition = this.handleGeolocationGetCurrentPosition.bind(this);
        this.handleZoomMapPlus = this.handleZoomMapPlus.bind(this);
        this.handleZoomMapMinus = this.handleZoomMapMinus.bind(this);
        this.handleGoToGeolocation = this.handleGoToGeolocation.bind(this);
        this.onRegionChange = this.onRegionChange.bind(this);
        this.onRegionChangeComplete = this.onRegionChangeComplete.bind(this);
    }

    setMapView(map) {
        this.mapView = map;
    }

    startGeolocation(navigator) {
        navigator.geolocation.getCurrentPosition(this.handleGeolocationGetCurrentPosition);
    }

    handleGeolocationGetCurrentPosition(position) {
        this.geolocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };
        if (this.target) {
            this.target.setState({ geolocation: this.geolocation });
        }
    }

    handleZoomMapPlus() {
        if (this.lastMapRegion && this.mapView) {
            const newRegion = {
                latitude: this.lastMapRegion.latitude,
                longitude: this.lastMapRegion.longitude,
                latitudeDelta: this.lastMapRegion.latitudeDelta / 5,
                longitudeDelta: this.lastMapRegion.longitudeDelta / 5
            };

            if (this.mapView.animateToRegion) {
                this.mapView.animateToRegion(newRegion);
            }
            else
                if (this.mapView.getMapRef() && this.mapView.getMapRef().animateToRegion) {
                    this.mapView.getMapRef().animateToRegion(newRegion);
                }
        }
    }

    handleZoomMapMinus() {
        if (this.lastMapRegion && this.mapView) {
            const newRegion = {
                latitude: this.lastMapRegion.latitude,
                longitude: this.lastMapRegion.longitude,
                latitudeDelta: this.lastMapRegion.latitudeDelta * 5,
                longitudeDelta: this.lastMapRegion.longitudeDelta * 5
            };

            if (this.mapView.animateToRegion) {
                this.mapView.animateToRegion(newRegion);
            }
            else
                if (this.mapView.getMapRef() && this.mapView.getMapRef().animateToRegion) {
                    this.mapView.getMapRef().animateToRegion(newRegion);
                }
        }
    }

    handleGoToGeolocation() {
        if (this.geolocation && this.lastMapRegion && this.mapView) {
            const newRegion = {
                latitude: this.geolocation.latitude,
                longitude: this.geolocation.longitude,
                latitudeDelta: this.lastMapRegion.latitudeDelta,
                longitudeDelta: this.lastMapRegion.longitudeDelta
            };
            if (this.mapView.animateToRegion) {
                this.mapView.animateToRegion(newRegion);
            }
            else
                if (this.mapView.getMapRef() && this.mapView.getMapRef().animateToRegion) {
                    this.mapView.getMapRef().animateToRegion(newRegion);
                }
        }
    }

    navigateToPoint(location, verticalDisplace = 0, changeScale = true) {
        if (this.mapView) {
            let latitudeDelta = (changeScale || (!this.lastMapRegion) || (!this.lastMapRegion.latitudeDelta) ? 0.01 : this.lastMapRegion.latitudeDelta);
            let longitudeDelta = (changeScale || (!this.lastMapRegion) || (!this.lastMapRegion.longitudeDelta) ? 0.01 : this.lastMapRegion.longitudeDelta);
            const newRegion = {
                latitude: location.latitude + verticalDisplace * latitudeDelta,
                longitude: location.longitude,
                latitudeDelta: latitudeDelta,
                longitudeDelta: longitudeDelta
            };
            if (this.mapView.animateToRegion) {
                this.mapView.animateToRegion(newRegion);
            }
            else
                if (this.mapView.getMapRef() && this.mapView.getMapRef().animateToRegion) {
                    this.mapView.getMapRef().animateToRegion(newRegion);
                }
        }

    }

    onRegionChange(region) {
        this.lastMapRegion = region;

        this.app.cityMapLogic.updateViewArea();
    };

    onRegionChangeComplete(region) {
        this.lastMapRegion = region;
    };


    getScaleValue = (zoomIndex) => {
        let oneIndex = 17;
        let oneValue = 2500;
        return oneValue * Math.pow(2, oneIndex - zoomIndex);
    }

    getPoints = (item, placeInfo = null, onPressHandler = null) => {
        let points = [];
        if (item.geometry.type.toLowerCase() === 'multipoint') {
            points = item.geometry.coordinates;
        } else {
            points.push(item.geometry.coordinates);
        }
        let pointMarkers = [];
        let scaleValue = this.getScaleValue(this.app.state.mapZoom);
        let defaultMaxLabelVisible = 10000;
        for (let i = 0; i < points.length; i++) {
            pointMarkers.push(<Marker
                key={'m' + item.id + '_' + i}
                position={[points[i][1], points[i][0]]}
                opacity={((!placeInfo) || (item.id === placeInfo.id)) ? 1 : MapServices.unselectedOpacity}
                //title={item.name}
                //description={item.text}
                //stopPropagation={true}
                //zIndex={4 + MapServices.maxWmsLayers}
                icon={item.leafletIcon}
                onClick={onPressHandler}>
                <Popup autoPan={false}>
                    <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                </Popup>
                {item.label && (scaleValue <= (item.style.maxLabelVisible || defaultMaxLabelVisible)) &&
                    <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent
                        className={'marker-label'}>
                        {item.label}
                    </Tooltip>}
            </Marker>);
        }
        return pointMarkers;
    }

    getLines = (item, placeInfo = null, onPressHandler = null) => {
        let lines;
        if (item.geometry.type.toLowerCase() === 'multiline') {
            lines = item.geometry.coordinates;
        } else {
            lines = [item.geometry.coordinates];
        }
        let lineMarkers = [];
        if (!((item.geometry.type.toLowerCase() === 'multiline') || (item.geometry.type.toLowerCase() === 'linestring'))) {
            return lineMarkers;
        }

        let scaleValue = this.getScaleValue(this.app.state.mapZoom);
        let defaultMaxLabelVisible = 10000;
        for (let i = 0; i < lines.length; i++) {
            let coordinates = [];
            for (let j = 0; j < lines[i].length; j++) {
                if (lines[i][j][1] && lines[i][j][0]) {
                    coordinates.push([lines[i][j][1], lines[i][j][0]]);
                }
            }

            lineMarkers.push(<Polyline
                key={'l' + item.id + '_' + i}
                positions={coordinates}
                strokeColor={SmartCityApi.colorWithOpacity(item.style.line.color, ((!placeInfo) || (item.id === placeInfo.id)) ? 1 : MapServices.unselectedOpacity)}
                strokeWidth={6/*item.style.line.width*/}
                onClick={onPressHandler}>
                <Popup autoPan={false}>
                    <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                </Popup>
                {item.label && (scaleValue <= (item.style.maxLabelVisible || defaultMaxLabelVisible)) &&
                    <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent
                        className={'marker-label'}>
                        {item.label}
                    </Tooltip>}
            </Polyline>);

        }

        return lineMarkers;
    }

    getPolygons = (item, placeInfo = null, onPressHandler = null) => {
        let polygons;
        if (item.geometry.type.toLowerCase() === 'multipolygon') {
            polygons = item.geometry.coordinates;
        } else {
            polygons = [item.geometry.coordinates];
        }

        if (!item.holes) {
            item.holes = [];
        }

        let polygonMarkers = [];
        if (!((item.geometry.type.toLowerCase() === 'polygon') || (item.geometry.type.toLowerCase() === 'multipolygon'))) {
            return polygonMarkers;
        }

        let scaleValue = this.getScaleValue(this.app.state.mapZoom);
        let defaultMaxLabelVisible = 10000;
        for (let index = 0; index < polygons.length; index++) {
            let contour = polygons[index];

            if (contour) {
                let holes = null;
                if (contour.length > 1) {
                    holes = [];
                    for (let i = 1; i < contour.length; i++) {
                        let coordinates = [];
                        for (let j = 0; j < contour[i].length; j++) {
                            coordinates.push([contour[i][j][1], contour[i][j][0]]);
                        }
                        holes.push(coordinates);
                    }
                }

                let coords = ((contour[0][0] instanceof Number) || (contour[0].latitude) ? contour : contour[0]);
                let coordinates = [];
                for (let i = 0; i < coords.length; i++) {
                    coordinates.push([coords[i][1], coords[i][0]]);
                }

                if (holes) {
                    let contourWithHoles = [];
                    contourWithHoles.push(coordinates);
                    for (let i = 0; i < holes.length; i++) {
                        contourWithHoles.push(holes[i]);
                    }
                    coordinates = contourWithHoles;
                }

                polygonMarkers.push(<Polygon
                    key={'p' + item.id + '_' + index}
                    positions={coordinates}
                    strokeColor={SmartCityApi.colorWithOpacity(item.style.line.color, ((!placeInfo) || (item.id === placeInfo.id)) ? 1 : MapServices.unselectedOpacity)}
                    strokeWidth={item.style.line.width}
                    fillColor={SmartCityApi.colorWithOpacity(item.style.fill.color, ((!placeInfo) || (item.id === placeInfo.id)) ? 1 : MapServices.unselectedOpacity)}
                    onClick={onPressHandler}>
                    <Popup autoPan={false}>
                        <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                    </Popup>
                    {item.label && (scaleValue <= (item.style.maxLabelVisible || defaultMaxLabelVisible)) &&
                        <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent
                            className={'marker-label'}>
                            {item.label}
                        </Tooltip>}
                </Polygon>);

            }
        }

        return polygonMarkers;
    }
}

export default MapServices;