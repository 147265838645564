import Classifiers from './../../services/Classifiers';
import TosApi from './../../services/TosApi';
import L from 'leaflet';


/*

Базовый режим (ничего не выбрано)
 - слой остановок виден целиком
 - в окрестности середины подгружен транспорт
 - клик в остановку:
   - переход в режим остановки
   - открывание пузыря без промотки карты
 - клик в транспорт:
   - переход в режим транспорта
   - открывание пузыря без промотки карты
Обновление - при инициализации, при каждом сдвиге карты, по таймеру.


Режим остановки
 - показана форма с подробностями и прогнозами прибытия
 - клик в прогноз:
   - переход в режим транспорта
   - перемотка карты на кликнутый транспорт
 - закрытие формы:
   - переход в базовый режим
Обновление - по таймеру.


Режим транспорта
 - показана линия маршрута
 - показаны только остановки вдоль маршрута
 - показаны транспорты на маршруте (обязательно тот транспорт, по которому кликнули, на нем позиционирование)
 - показана форма с подробностями о транспорте и прогнозами на остановки
 - клик в остановку:
   - переход в режим остановки
   - открывание пузыря без промотки карты
 - клик в транспорт:
   - переход в режим транспорта
   - открывание пузыря без промотки карты
 - закрытие формы:
   - переход в базовый режим
Обновление - по таймеру.

*/



class TransportLogic {

    app = null;
    lastRegion = null;

    constructor(_app) {
        this.app = _app;

        setInterval(this.reloadTransportsInViewport, TransportLogic.TRANSPORTS_REFRESH_INTERVAL_MS);
        setInterval(this.reloadSelectedStopForecasts, TransportLogic.FORECASTS_REFRESH_INTERVAL_MS);
    }

    static BASE_MODE = "base";
    static STOP_MODE = "stop";
    static TRANSPORT_MODE = "transport";

    static SEARCH_RADIUS = 2000;
    static TRANSPORTS_REFRESH_INTERVAL_MS = 5000;
    static FORECASTS_REFRESH_INTERVAL_MS = 20000;
    static MAX_STOP_FORECASTS = 100;
    static MAX_TRANSPORTS_ON_ROUTE = 100;
    static MAX_STOPS_IN_MAP = 100;
    static MAX_SURRONDING_TRANSPORTS = 5000;
    static SINGLE_STOP_ZOOM = 18;
    static SELECTED_ICON_SCALE = 1.5;
    static BASE_TRANSPORT_STATE = {
        transport_submode: TransportLogic.BASE_MODE,
        transport_selectedStop: null,
        transport_stopForecasts: [],
        transport_selectedTransport: null,
        transport_transportForecasts: [],
        transport_routeGeometry: [],
        foundResults: null,
        foundMarker: null
    }


    reloadTransportsInViewport = (region = null) => {
        if (this.app.state.mapMode === "transport") {
            if ((this.app.state.transport_submode === TransportLogic.BASE_MODE) || (this.app.state.transport_submode === TransportLogic.STOP_MODE)) {

                if (!region) {
                    region = this.lastRegion;
                }

                if (region) {
                    this.app.setState({ progressVisible: true });

                    /*let latMeters = region.latitudeDelta * GeoTools.LATITUDE_TO_M / 2;
                    let lngMeters = region.longitudeDelta * GeoTools.longitudeToMeter(region.latitude) / 2;
                    if (latMeters > TransportLogic.SEARCH_RADIUS) latMeters = TransportLogic.SEARCH_RADIUS;
                    if (lngMeters > TransportLogic.SEARCH_RADIUS) lngMeters = TransportLogic.SEARCH_RADIUS;
                    Math.max(latMeters, lngMeters)
*/

                    TosApi.getSurroundingTransports(region.latitude, region.longitude, TransportLogic.SEARCH_RADIUS, TransportLogic.MAX_SURRONDING_TRANSPORTS)
                        .then(response => JSON.parse(response))
                        .then(response => {
                            if (response) {
                                let transports = response.transports || [];
                                for (let transport of transports) {
                                    if (transport.type === 'Трамвай') {
                                        transport.leafletIcon = new L.Icon({
                                            iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_tram` + ((transport.unattached === "true") ? "_gray" : "") + `.png`,
                                            iconAnchor: new L.Point(9, 16),
                                            iconSize: new L.Point(18, 32),
                                        });
                                    }
                                    else
                                        if (transport.type === 'Троллейбус') {
                                            transport.leafletIcon = new L.Icon({
                                                iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_trol` + ((transport.unattached === "true") ? "_gray" : "") + `.png`,
                                                iconAnchor: new L.Point(9, 16),
                                                iconSize: new L.Point(18, 32),
                                            });
                                        }
                                        else {
                                            transport.leafletIcon = new L.Icon({
                                                iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_bus` + ((transport.unattached === "true") ? "_gray" : "") + `.png`,
                                                iconAnchor: new L.Point(9, 16),
                                                iconSize: new L.Point(18, 32),
                                            });
                                        }
                                }

                                this.app.setState({
                                    transport_transports: transports,
                                    progressVisible: false
                                });
                            }
                        });
                }
            }
            if (this.app.state.transport_submode === TransportLogic.TRANSPORT_MODE) {
                if (this.app.state.transport_selectedTransport) {
                    this.loadTransportsOnRoute(this.app.state.transport_selectedTransport.KR_ID, false);
                }
            }
        }
    }

    reloadStopsInViewport = (region) => {
        if (region) {
            Classifiers.searchInRegion(region, TransportLogic.MAX_STOPS_IN_MAP)
                .then((stops) => {
                    this.app.setState({
                        transport_stops: this.stopsToMarkers(stops)
                    });
                });
        }
    }

    reloadSelectedStopForecasts = () => {
        if (this.app.state.mapMode === "transport") {
            if (this.app.state.transport_submode === TransportLogic.STOP_MODE) {
                if (this.app.state.transport_selectedStop) {
                    this.loadForecasts(this.app.state.transport_selectedStop);
                }
            }
        }
    }

    viewportChanged = (region) => {
        if (region) {
            this.lastRegion = region;

            if (this.app.state.mapMode === "transport") {
                if ((this.app.state.transport_submode === TransportLogic.BASE_MODE) || (this.app.state.transport_submode === TransportLogic.STOP_MODE)) {

                    this.reloadStopsInViewport(region);
                    this.reloadTransportsInViewport(region);
                }
            }
        }
    }

    checkForViewportChanged = (event) => {
        if (!event) {
            if (this.app.state.mapMode === "transport") {
                this.viewportChanged(this.lastRegion || this.app.mapServices.initialRegion);
            }
        }
        else {

            this.app.setState({
                mapZoom: event.zoom
            });

            let delta = Math.pow(0.5, event.zoom - 3);
            let region = {
                latitude: event.center[0],
                longitude: event.center[1],
                latitudeDelta: 90 * delta,
                longitudeDelta: 180 * delta
            }
            this.lastRegion = region;

            let regionForMapServices = {
                latitude: event.center[0],
                longitude: event.center[1],
                latitudeDelta: 90 * delta/2,
                longitudeDelta: 180 * delta/2
            }

            this.app.mapServices.onRegionChange(regionForMapServices);

            if (this.app.state.mapMode === "transport") {
                this.viewportChanged(region);
            }
        }
    }


    stopsToMarkers(stops) {
        let usedKSID = {};
        let filteredStops = [];
        for (let stop of stops) {
            if (!usedKSID[stop.KS_ID]) {
                stop.location = {
                    latitude: parseFloat(stop.latitude[0]),
                    longitude: parseFloat(stop.longitude[0])
                }
                stop.id = `${stop.KS_ID}`

                let baseIconSize = 20;
                if ((this.app.state.mapZoom || this.app.mapServices.initialRegion.zoomLevel) <= 13) {
                    baseIconSize = 10;
                }
                else
                    if ((this.app.state.mapZoom || this.app.mapServices.initialRegion.zoomLevel) <= 15) {
                        baseIconSize = 15;
                    }
                let selectedBaseIconSize = baseIconSize * TransportLogic.SELECTED_ICON_SCALE;
                stop.leafletIcon = new L.Icon({
                    iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/Transport_MapBusIcon.png`,
                    iconAnchor: new L.Point(baseIconSize / 2, baseIconSize / 2),
                    iconSize: new L.Point(baseIconSize, baseIconSize),
                });
                stop.leafletIconSelected = new L.Icon({
                    iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/Transport_MapBusIconSelected.png`,
                    iconAnchor: new L.Point(selectedBaseIconSize / 2, selectedBaseIconSize / 2),
                    iconSize: new L.Point(selectedBaseIconSize, selectedBaseIconSize),
                });
                filteredStops.push(stop);
            }
            usedKSID[stop.KS_ID] = true;
        }
        return filteredStops;
    }


    startBaseMode = () => {
        let newState = Object.assign({}, TransportLogic.BASE_TRANSPORT_STATE);
        newState.transport_submode = TransportLogic.BASE_MODE;

        this.app.setState(newState,
            () => {
                this.reloadStopsInViewport(this.lastRegion);
                this.reloadTransportsInViewport(this.lastRegion);
            });
    }


    startStopMode = (stop, moveMap = false) => {
        let newState = Object.assign({}, TransportLogic.BASE_TRANSPORT_STATE);
        newState.transport_submode = TransportLogic.STOP_MODE;
        newState.transport_selectedStop = stop;

        this.app.setState(newState,
            () => {
                if (stop) {
                    this.loadForecasts(stop);

                    if (moveMap) {
                        let latitude = (stop.location ? stop.location.latitude : parseFloat(stop.latitude[0]));
                        let longitude = (stop.location ? stop.location.longitude : parseFloat(stop.longitude[0]));

                        this.app.setState({
                            mapPosition: [latitude, longitude],
                            mapZoom: TransportLogic.SINGLE_STOP_ZOOM
                        });

                        let delta = Math.pow(0.5, TransportLogic.SINGLE_STOP_ZOOM - 3);
                        let region = {
                            latitude: latitude,
                            longitude: longitude,
                            latitudeDelta: 90 * delta,
                            longitudeDelta: 180 * delta
                        }
                        this.lastRegion = region;

                        this.reloadStopsInViewport(region);
                        this.reloadTransportsInViewport(region);
                    }
                    else {
                        this.reloadStopsInViewport(this.lastRegion);
                    }
                }
            });
    }


    loadForecasts = (stop) => {
        this.app.setState({ progressVisible: true });
        TosApi.getFirstArrivalToStop(stop.KS_ID[0], TransportLogic.MAX_STOP_FORECASTS)
            .then((response) => JSON.parse(response))
            .then((response) => {
                let stopForecasts = [];
                for (let forecast of response.arrival) {
                    stopForecasts.push({
                        id: forecast.hullNo,
                        plateColor: (forecast.type === 'Трамвай') ? "#BA0000" :
                            (forecast.type === 'Троллейбус') ? "#006AB5" :
                                "#D46600",
                        iconRequire: `${process.env.PUBLIC_URL}/_assets/_icons/Transport_Bus.svg`,
                        forecast: forecast
                    })
                }
                this.app.setState({
                    transport_stopForecasts: stopForecasts,
                    progressVisible: false
                });
            });
    }


    startTransportMode = (transport, moveMap = false) => {
        let newState = Object.assign({}, TransportLogic.BASE_TRANSPORT_STATE);
        newState.transport_submode = TransportLogic.TRANSPORT_MODE;
        newState.transport_selectedTransport = transport;

        this.app.setState(newState,
            () => {
                this.drawRoute(transport.KR_ID);
                this.loadTransportsOnRoute(transport.KR_ID, moveMap);
                this.loadTransportForecasts(transport.hullNo);
            });
    }

    startRouteMode = (KR_ID) => {
        let newState = Object.assign({}, TransportLogic.BASE_TRANSPORT_STATE);
        newState.transport_submode = TransportLogic.TRANSPORT_MODE;

        this.app.setState(newState,
            () => {
                this.drawRoute(KR_ID);
                this.loadTransportsOnRoute(KR_ID, false);
            });
    }


    loadTransportForecasts = (hullno) => {
        let transportForecasts = [];
        this.app.setState({ progressVisible: true });
        TosApi.getTransportPosition(hullno)
            .then((response) => JSON.parse(response))
            .then((response) => {
                let i = 0;
                if (response.nextStops) {
                    for (let forecast of response.nextStops) {
                        transportForecasts.push({
                            id: i++,
                            forecast: Math.round(forecast.time / 60) + " мин"
                        })
                    }
                    let requests = response.nextStops.map((forecast) => Classifiers.getStop(forecast.KS_ID));
                    return Promise.all(requests);
                }
            })
            .then((stops) => {
                for (var i = 0; i < transportForecasts.length; i++) {
                    let element = transportForecasts[i];
                    element.stop = stops[i];
                }
                this.app.setState({
                    transport_transportForecasts: transportForecasts,
                    progressVisible: false
                });
            });
    }


    drawRoute = (KR_ID) => {
        if (KR_ID > 0) {
            Classifiers.getRouteGeometry(KR_ID)
                .then((geom) => {
                    this.app.setState({
                        transport_routeGeometry: geom
                    });
                });
            Classifiers.getRouteSpatialInfo(KR_ID)
                .then((data) => {
                    if (data && data.stop) {
                        let requests = data.stop.map((stop) => Classifiers.getStop(stop.KS_ID[0]));
                        return Promise.all(requests);
                    }
                    else {
                        return null;
                    }
                })
                .then((stops) => {
                    if (stops) {
                        this.app.setState({
                            transport_stops: this.stopsToMarkers(stops)
                        });
                    }
                })
        }
        else {
            this.app.setState({
                transport_routeGeometry: [],
                transport_stops: []
            });
        }
    }

    loadTransportsOnRoute = (KR_ID, moveMap) => {
        if (KR_ID > 0) {
            this.app.setState({ progressVisible: true });
            TosApi.getTransportsOnRoute(KR_ID, TransportLogic.MAX_TRANSPORTS_ON_ROUTE)
                .then(response => JSON.parse(response))
                .then(response => {
                    if (response) {
                        let transports = response.transports;
                        let filteredTransports = [];
                        let clickedTransportOnMap = null;
                        let usedHullNo = {};
                        for (let transport of transports) {
                            if (!usedHullNo[transport.hullNo]) {

                                if (transport.type === 'Трамвай') {
                                    transport.leafletIcon = new L.Icon({
                                        iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_tram.png`,
                                        iconAnchor: new L.Point(9, 16),
                                        iconSize: new L.Point(18, 32),
                                    });
                                }
                                else
                                    if (transport.type === 'Троллейбус') {
                                        transport.leafletIcon = new L.Icon({
                                            iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_trol.png`,
                                            iconAnchor: new L.Point(9, 16),
                                            iconSize: new L.Point(18, 32),
                                        });
                                    }
                                    else {
                                        transport.leafletIcon = new L.Icon({
                                            iconUrl: `${process.env.PUBLIC_URL}/_assets/_icons/transport/moving_bus.png`,
                                            iconAnchor: new L.Point(9, 16),
                                            iconSize: new L.Point(18, 32),
                                        });
                                    }
                                if (this.app.state.transport_selectedTransport) {
                                    if (this.app.state.transport_selectedTransport.hullNo === transport.hullNo) {
                                        transport.leafletIcon.options.iconSize.x *= TransportLogic.SELECTED_ICON_SCALE;
                                        transport.leafletIcon.options.iconSize.y *= TransportLogic.SELECTED_ICON_SCALE;
                                        transport.leafletIcon.options.iconAnchor.x *= TransportLogic.SELECTED_ICON_SCALE;
                                        transport.leafletIcon.options.iconAnchor.y *= TransportLogic.SELECTED_ICON_SCALE;
                                        clickedTransportOnMap = transport;
                                    }
                                }

                                filteredTransports.push(transport);
                            }
                            usedHullNo[transport.hullNo] = true;
                        }
                        if (this.app.state.transport_selectedTransport) {
                            if (!clickedTransportOnMap) {
                                filteredTransports.push(this.app.state.transport_selectedTransport);
                            }

                            if (moveMap) {
                                if (clickedTransportOnMap) {
                                    this.app.setState({
                                        mapPosition: [clickedTransportOnMap.latitude, clickedTransportOnMap.longitude]
                                    });
                                }
                            }
                        }
                        this.app.setState({
                            transport_transports: filteredTransports,
                            progressVisible: false
                        });
                    }
                });
        }
    }

    async searchByLocalTransportRefs(text) {
        if ((!text) || (text.length === 0))
            return null;

        let results = await Classifiers.search(text);

        let found = [];
        results.routes && results.routes.forEach(route => {
            found.push({
                id: found.length,
                key: "transportRoute" + found.length,
                name: route.number[0] + ' → ' + route.direction[0],
                text: route.transportType[0],
                iconRequire: `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundBusIcon.svg`,
                route: route,
                geometry: null,
                type: "transportRoute"
            });
        });

        results.stops && results.stops.forEach(stop => {
            found.push({
                id: found.length,
                key: "transportStop" + found.length,
                name: stop.title[0],
                text: stop.adjacentStreet[0] + ' ' + stop.direction[0],
                iconRequire: `${process.env.PUBLIC_URL}/_assets/_icons/Transport_foundBusIcon.svg`,
                stop: stop,
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(stop.latitude[0]), parseFloat(stop.longitude[0])]
                },
                type: "transportStop"
            });
        });
        return found;
    }
}

export default TransportLogic;