import React from 'react';

import commonStyles from './../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';
import TextSearchField from '../../components/TextSearchField';
import TextSearchResults from '../../components/TextSearchResults';


class ReminderSubscriptionsListScreen extends React.Component {

    state = {
        searchText: null
    };

    componentWillMount() {

        SmartCityApi.reminderSubscribes()
            .then(response => {

                this.props.updateShowingSubscriptions(response.subscribes);

                this.setState({
                    subscribes: response.subscribes,
                    isAnonym: response.anonym || false
                });
            });

    }


    render() {

        var preparedSubscribes = null;
        if (this.state.subscribes) {
            preparedSubscribes = [];
            let searchText = (this.state.searchText ? this.state.searchText.toLowerCase() : null);

            this.state.subscribes.map(item => {
                let searchDescription = (item.name + " " + item.text).toLowerCase();
                if ((!searchText) || (searchDescription.indexOf(searchText) >= 0)) {
                    preparedSubscribes.push(item);
                }
                return null;
            });
        }

        return (

            <div style={commonStyles.commonScreen}>

                <TextSearchField
                    style={commonStyles.textSearchField}
                    placeholder="поиск по адресу"
                    searchTypes={["building", "toponim"]}
                    beforeSearch={() => {
                        /*if (this.state.stop || this.state.transport || this.state.routeInfo) {
                            this.setState({
                                stop: null,
                                transport: null,
                                routeInfo: null
                            });
                        }*/
                    }}
                    afterFound={(foundResults) => {
                        //if (this._isMounted)
                        this.setState({
                            foundResults: foundResults
                        });
                    }}
                />

                <div style={styles.searchListItem}>
                    <input
                        type="text"
                        style={styles.searchTextInput}
                        placeholder="поиск среди подписок"
                        onChange={(e) => {
                            this.setState({ searchText: e.target.value })
                        }}
                    ></input>
                </div>

                {(preparedSubscribes) && (preparedSubscribes.length > 0) &&
                    <div style={styles.placeInfoContainer} >
                        {preparedSubscribes && preparedSubscribes.map(item => (

                            <div key={item.id}
                                onClick={() => {
                                    this.props.showSubscriptionMarker(item);
                                }
                                }>

                                <div key={item.id} style={styles.placeInfoBack}>
                                    <div style={styles.placeInfoTypeBlock}>
                                        <p style={styles.placeInfoTypeText}>{(item.type === "building" ? "Здание" :
                                            (item.type === "transportStop" ? "Остановка транспорта" :
                                                "иной объект"
                                            )
                                        )}</p>
                                    </div>
                                    <p style={styles.placeInfoHeaderText}>{item.name}</p>
                                    <p style={styles.placeInfoDistanceText}></p>
                                    <p style={styles.placeInfoDescriptionText}>{item.text}</p>
                                    <div style={styles.graySplitterLine}></div>
                                </div>
                            </div>
                        ))}
                    </div>
                }


                {((!preparedSubscribes) || (preparedSubscribes.length === 0)) &&
                    <div style={styles.placeInfoContainerEmptyList} >
                        {this.state.isAnonym &&
                            <div
                                onClick={() => window.location.href = "/user/?returnTo=/new/#reminder"}>
                                <p style={styles.emptyListStubMessageText}>Чтобы подписаться на уведомления о событиях, вам нужно авторизоваться. {"\n"} Такие правила, анонимные подписки не принимаются.</p>
                                <div style={styles.goToAuthTextButton}>
                                    <p style={styles.goToAuthTextButtonLabel}>Авторизоваться</p>
                                </div>
                            </div>
                        }
                        {(!this.state.isAnonym) && ((!this.state.subscribes) || (this.state.subscribes.length === 0)) &&
                            <p style={styles.emptyListStubMessageText}>Вы еще не добавили ни одной подписки. Найдите место в городе по адресу или двойным кликом на карту, и подпишитесь на него.</p>
                        }
                        {(!this.state.isAnonym) && (this.state.subscribes) && (this.state.subscribes.length > 0) &&
                            <p style={styles.emptyListStubMessageText}>Ничего не найдено среди ваших подписок, уточните запрос.</p>
                        }
                    </div>
                }

                <TextSearchResults
                    style={commonStyles.textSearchResults}
                    foundResults={this.state.foundResults}
                    onItemPress={(item) => {
                        this.setState({
                            foundResults: null
                        });
                        if (this.props.foundMarkerFunction) {
                            this.props.foundMarkerFunction(item);
                        }
                    }} />
            </div>
        );


    }
}

ReminderSubscriptionsListScreen.navigationOptions = {
    header: null
};

export default ReminderSubscriptionsListScreen;


const styles = {


    graySplitterLine: {
        backgroundColor: '#E4E4E4',
        height: '1px',
        width: '100%',
        marginTop: '12px'
    },

    searchListItem: {
        marginLeft: '26px',
        marginRight: '20px',
        marginTop: '20px',
        marginBottom: '0px',
        height: '40px',
    },

    searchTextInput: {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        width: '100%',
        height: '40px',
        backgroundColor: '#FFFFFF',
        border: "1px solid rgba(36, 46, 66, 0.25)",
        color: '#000000',
        borderRadius: '8px',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
        textAlign: "center"
    },

    placeInfoContainer: {
        flex: 1,
        marginLeft: '22px',
        marginRight: '14px',
        marginTop: '25px',
        overflow: "scroll",
        overflowX: "hidden"
    },

    placeInfoContainerEmptyList: {
        flex: 1,
        marginLeft: '22px',
        marginRight: '14px',
        marginTop: '25px'       
    },

    placeInfoBack: {
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '10px',
        alignItems: "flex-start",
        cursor: 'pointer'
    },

    placeInfoTypeBlock: {
        marginLeft: '27px',
        marginRight: '27px',
        backgroundColor: '#3DD0FF',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: "center",
    },
    placeInfoTypeText: {
        marginLeft: '12px',
        marginRight: '12px',
        marginTop: '3px',
        marginBottom: '3px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px'
    },


    placeInfoHeaderText: {
        marginLeft: '27px',
        marginRight: '27px',
        marginTop: '14px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontWeight: "bold",
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
    },

    placeInfoDistanceText: {
        position: 'absolute',
        right: '15px',
        top: '20px',
        color: '#8C8C8C',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        textAlign: 'right'
    },

    placeInfoDescriptionText: {
        marginLeft: '27px',
        marginRight: '27px',
        marginTop: '19px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
    },

    emptyListStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },

    goToAuthTextButton: {
        width: '264px',
        height: '40px',
        marginTop: '0px',
        marginBottom: '10px',
        marginLeft:'auto',
        marginRight:'auto',
        borderRadius: '44px',
        backgroundColor: '#242E42',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer'
    },

    goToAuthTextButtonLabel: {
        marginLeft: '30px',
        marginRight: '30px',
        paddingTop: '10px',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#FFFFFF',
        textAlign: 'center'
    },




};
