import React from 'react';

import commonStyles from './../Common.styles';
import SmartCityApi from '../../services/SmartCityApi';

class ProblemsAddNewStep1CategoriesScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: null
        };

        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        Promise.all([SmartCityApi.problemsCategories(), SmartCityApi.mapLayers()])
            .then(response => {
                let categories = response[0].categories;

                let createRights = {};
                let mapLayersGroups = response[1].groups;
                mapLayersGroups.push({
                    "name": "hiddenLayers",
                    "layers": response[1].hiddenLayers
                });

                for (let group of mapLayersGroups) {
                    for (let layer of group.layers) {
                        createRights[layer.id] = layer.create;
                    }
                }
                for (let category of categories) {
                    category.createRight = createRights[category.layerName] || false;
                }

                if (this._isMounted)
                    this.setState({ categories: categories });
            });
    }

    render() {

        return (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>
                    <div style={commonStyles.fullFloatingWhiteBack}>
                        <div
                            onClick={(e) => this.props.navigateTo("problems")}>
                            <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <p style={commonStyles.floatingWindowHeader}>Выберите категорию</p>

                            <div style={styles.categoriesListBlock}>
                                {this.state.categories && this.state.categories.map((item, index) => (
                                    <div key={item.id}>
                                        {item.createRight &&
                                            <div
                                                onClick={() => this.props.navigateTo("problems_2", { problems_category: item })}>
                                                <div style={styles.categoriesListItem}>
                                                    <div style={styles.categoriesListItemImageBox}>
                                                        <img alt="" style={styles.categoriesListItemImage} src={SmartCityApi.API_ROOT_URL + item.icon} />
                                                    </div>
                                                    <p style={styles.categoriesListItemLabel}>{item.name}</p>
                                                </div>
                                            </div>
                                        }
                                        {!item.createRight &&
                                            <div style={{ opacity: 0.5 }}>
                                                <div style={styles.categoriesListItem_inactive}>
                                                    <div style={styles.categoriesListItemImageBox}>
                                                        <img alt="" style={styles.categoriesListItemImage} src={SmartCityApi.API_ROOT_URL + item.icon} />
                                                    </div>
                                                    <p style={styles.categoriesListItemLabel}>{item.name}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ProblemsAddNewStep1CategoriesScreen;


const styles = {

    categoriesListBlock: {
        marginTop: '0px',
        marginLeft: '15px',
        marginRight: '15px',
        width: '300px',
        flex: "1",
        overflow: "scroll",
        overflowX: "hidden",
        overflowY: "hidden",
    },

    categoriesListItem: {
        float: "left",
        margin: '2px',
        width: '90px',
        height: '110px',
        backgroundColor: '#FFFFFF',
        textAlign: "center",
        cursor: "pointer"
    },

    categoriesListItem_inactive: {
        float: "left",
        margin: '2px',
        width: '90px',
        height: '110px',
        backgroundColor: '#FFFFFF',
        textAlign: "center"
    },

    categoriesListItemImageBox: {
        width: '34px',
        height: '34px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
    },

    categoriesListItemImage: {
        width: '30px',
        height: '30px',
    },

    categoriesListItemLabel: {
        marginTop: '3px',
        marginLeft: '5px',
        marginRight: '5px',
        textAlign: 'center',
        wordWrap: "break-word",
        width: '80px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '11px',
        lineHeight: '13px',
        letterSpacing: '-0.1px',
        backgroundColor: '#FFFFFF',
    },

};
