import SmartCityApi from './../../services/SmartCityApi';
import L from 'leaflet';
import BaseParams from './../../services/BaseParams';
import CityMapLayersListScreen from './CityMapLayersListScreen';

class CityMapLogic {

    app = null;
    layers = null;

    constructor(_app, _mapServices) {
        this.app = _app;
        this.mapServices = _mapServices;

        SmartCityApi.mapStyles()
            .then(response => {
                this.mapStyles = response.styles;
            });
    }


    updateLayers = (layers) => {
        this.layers = layers;
        this.loadLayers(layers)
            .then(response => {
                this.drawLayers(response)
            });
    }

    async updateViewArea() {
        if (!this.layersForLoad || !this.layersResponse)
        {
            return;
        }
        if (this.layersForLoad.length != this.layersResponse.length)
        {
            return;
        }
        
        let newLayersResponse = [];
        let somethingReloaded = false;
        for (let i = 0; i < this.layersForLoad.length; i++) {
            if (this.layersForLoad[i].maxLoadingSquare) {
                newLayersResponse.push(await this.loadLayer(this.layersForLoad[i]));
                somethingReloaded = true;
            }
            else {
                newLayersResponse.push(this.layersResponse[i]);
            }
        }
        if (somethingReloaded) {
            this.drawLayers(newLayersResponse);
        }
    }

    loadLayer(layer) {
        return SmartCityApi.mapLayerObjects(
            layer,
            !layer.refreshTime && !layer.maxLoadingSquare,
            layer.maxLoadingSquare ? this.mapServices.lastMapRegion : undefined)
            .then(response => {
                return response;
            });
    }

    loadLayers = (layers) => {
        this.app.setState({
            points: [],
            lines: [],
            polygons: [],
            progressVisible: true
        });

        let requests = [];
        let layersForLoad = [];
        let wmsLayers = [];
        for (let groupItem of layers) {
            for (let layerItem of groupItem.layers) {
                if (layerItem.selected) {
                    if (layerItem.type === "WMS") {
                        wmsLayers.push(layerItem);
                    }
                    else {
                        requests.push(
                            this.loadLayer(layerItem)
                        );
                        layersForLoad.push(layerItem);
                    }
                }
            }
        }

        this.app.setState({
            wmsLayers: wmsLayers
        });
        this.layersForLoad = layersForLoad;
        return Promise.all(requests);
    }

    static createStyleLeafletIcon(style, object) {
        if ((style) && (style.type === "Point")) {
            if (!style.leafletIcon) {
                if (style.icon.needPlate) {
                    style.leafletIcon = new L.Icon({
                        iconUrl: BaseParams.STYLE_PREVIEW_URL + '?styleName=' + style.id,
                        iconAnchor: new L.Point(12, 30),
                        iconSize: new L.Point(27, 32)
                    });
                }
                else {
                    style.leafletIcon = new L.Icon({
                        iconUrl: style.icon.url,
                        iconAnchor: new L.Point(-style.icon.left, -style.icon.top),
                        iconSize: new L.Point(style.icon.width, style.icon.height),
                    });
                }
            }
            object.leafletIcon = style.leafletIcon;
        }

        return object;
    }

    drawLayers = (layersResponse) => {
        this.layersResponse = layersResponse;

        let points = [];
        let lines = [];
        let polygons = [];
        for (let layer of layersResponse) {
            for (let obj of layer.objects) {
                let style = this.mapStyles[obj.style];
                obj = CityMapLogic.createStyleLeafletIcon(style, obj);

                obj.style = style;

                let MAX_NAME_LENGTH = 50;
                let name = obj.name;
                if (name.length > MAX_NAME_LENGTH) {
                    name = name.substring(0, MAX_NAME_LENGTH) + '...';
                }
                obj.label = name;

                if (obj.style) {
                    if (obj.style.type === 'Point') {
                        points.push(obj);
                    } else if (obj.style.type === 'Polygon') {
                        polygons.push(obj);
                    } else if (obj.style.type === 'LineString') {
                        lines.push(obj);
                    }
                }
            }
        }

        console.log(points.length, lines.length, polygons.length);
        
        this.app.setState({
            points: points,
            lines: lines,
            polygons: polygons,
            progressVisible: false
        });
    }

    turnDefaultLayers = (turnedLayers) => {
        SmartCityApi.mapLayers()
            .then(async response => {
                this.layers = response.groups;

                await CityMapLayersListScreen.fixWMSLayersTransparency(this.layers);

                for (let group of this.layers) {
                    for (let layer of group.layers) {
                        if (layer.id === "SM_26_98") {
                            layer.selected = true;
                        }
                    }
                }
                this.updateLayers(this.layers);
            });
    }

    coordinateSearch = (point, types) => {
        this.app.setState({ progressVisible: true });
        SmartCityApi.universalPointSearch(point, types)
            .then(response => {
                if (response.results && (response.results.length > 0)) {
                    let mainText = "";
                    let subText = "";

                    let marker = response.results[0];

                    if (marker.type === "building") {
                        mainText = marker.name;
                        subText = marker.text;
                    }
                    if (marker.type === "transportStop") {
                        let parts = marker.name.split(" на ");
                        mainText = parts[0];
                        subText = parts[1] || "";
                    }
                    subText = subText.replace('<br/>', ' ').replace('<br>', ' ');

                    let foundMarker = {
                        id: marker.id,
                        name: mainText,
                        text: subText,
                        type: marker.type,
                        geometry: marker.place
                    };

                    let style = this.app.mapStyles['simplepoint'];
                    if (style) foundMarker.style = style;
                    if (!foundMarker.style) return;

                    foundMarker = CityMapLogic.createStyleLeafletIcon(style, foundMarker);

                    this.app.setState({
                        foundMarker: foundMarker,
                        progressVisible: false,
                        mapPosition: [foundMarker.geometry.coordinates[1], foundMarker.geometry.coordinates[0]]
                    });
                    this.markerPress(foundMarker);
                }
                else {
                    this.app.setState({ foundMarker: null, progressVisible: false });
                }
            });
    }

    markerPress = async (item) => {

        this.app.setState({
            cityMapPlaceInfo: ((this.app.state.menuSector === "citymap") || (!this.app.state.menuSector) ? item : null),
            cityMapPlaceMessages: null,
            progressVisible: true
        });

        SmartCityApi.mapQueryObject(item.id)
            .then(response => {
                let object = response.object;
                object.style = this.mapStyles[object.style];
                if (object.semanticValues) {
                    SmartCityApi.mapSemantics(object.layerId)
                        .then(response => {
                            object.fields = response.fields;
                            object.refBooks = response.refBooks || [];
                            this.app.setState({
                                cityMapPlaceInfo: object
                            });
                        });
                }
                else {
                    this.app.setState({
                        cityMapPlaceInfo: object,
                        progressVisible: false
                    });
                }
            });

        if ((item) && (item.type === "building")) {

            let localInfoServicesResponse = await SmartCityApi.commonSettings();
            let localInfoServices = localInfoServicesResponse.settings.localInfoServices;

            let universalPointSearchTypes = [];
            for (let i = 0; i < localInfoServices.length; i++) {
                if (localInfoServices[i].service === "universalPointSearch") {
                    universalPointSearchTypes.push(localInfoServices[i].type);
                }
            }
            if (universalPointSearchTypes.length > 0) {
                let point = item.geometry.coordinates;
                while (point && point[0][0]) {
                    point = point[0];
                }
                let response = await SmartCityApi.universalPointSearch(point, universalPointSearchTypes);

                for (let obj of response.results) {
                    obj.text = obj.text.replace(this.tags, '\n');

                    for (let i = 0; i < localInfoServices.length; i++) {
                        if ((localInfoServices[i].service === "universalPointSearch") && (obj.type === localInfoServices[i].type)) {
                            if (!localInfoServices[i].results) {
                                localInfoServices[i].results = [];
                            }
                            localInfoServices[i].results.push(obj);
                        }
                    }
                }

                this.app.setState({
                    localInfoServices: localInfoServices
                });
            }

            let connectionsTypes = [];
            for (let i = 0; i < localInfoServices.length; i++) {
                if (localInfoServices[i].service === "connections") {
                    connectionsTypes.push(localInfoServices[i].type);
                }
            }
            if (connectionsTypes.length > 0) {
                let response = await SmartCityApi.getConnections(item.id, connectionsTypes);
                let objRequests = response.content.connections.map(conn => SmartCityApi.mapQueryObject(conn.id));
                let returnedTypes = response.content.connections.map(conn => conn.type);
                let objResponses = await Promise.all(objRequests);

                for (let j = 0; j < objResponses.length; j++) {
                    let obj = objResponses[j];
                    if (obj.status === 'error') continue;

                    for (let i = 0; i < localInfoServices.length; i++) {
                        if ((localInfoServices[i].service === "connections") && (returnedTypes[j].toString() === localInfoServices[i].type)) {
                            if (!localInfoServices[i].results) {
                                localInfoServices[i].results = [];
                            }
                            obj.object.text = obj.object.text.replace(this.tags, '\n');
                            localInfoServices[i].results.push(obj.object);
                        }
                    }
                }

                this.app.setState({
                    localInfoServices: localInfoServices
                });
            }

            for (let i = 0; i < localInfoServices.length; i++) {
                if (localInfoServices[i].service === "reminderMessages") {
                    let response = await SmartCityApi.reminderMessages(item.id);

                    response.messages.map(item => {
                        item.preparedDateStart = ((item.dateStart && (item.dateStart.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateStart)) : "неизвестно");
                        item.preparedDateEnd = ((item.dateEnd && (item.dateEnd.length > 0)) ? SmartCityApi.prettyDateTime(SmartCityApi.parseDate(item.dateEnd)) : "неизвестно");
                    });

                    localInfoServices[i].results = response.messages;
                }
                this.app.setState({
                    localInfoServices: localInfoServices
                });
            }
        }


    }

}

export default CityMapLogic;