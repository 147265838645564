
export default
    {

        commonScreen: {
            //flex: 1,
            backgroundColor: '#DBECEC',
            overflow: "hidden",
            height: "calc(100vh - 70px)", // 70px -- height of the header
            display: "flex",
            flexDirection: "column"
        },

        screenLayer: {
            position: 'absolute',
            left: 0,
            top: 0,
            overflow: 'hidden',
            height: 'calc(100vh - 70px)', // 70px -- height of the header
            width: '100%'
        },

        whiteBack: {
            flex: 1,
            backgroundColor: '#FAFAFA'
        },

        map: {
            flex: 1,
        },

        miniMap: {
            width: '100%',
            height: '200px'
        },

        fullFloatingWhiteBack: {
            position: 'absolute',
            bottom: '0px',
            top: '10px',
            left: '0px',
            right: '0px',
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            overflow: "hidden",
            //overflowX: "hidden"
        },

        closeFloatingWindowButton: {
            position: 'absolute',
            width: "19px",
            height: "19px",
            top: "24px",
            right: "24px",
            cursor: 'pointer'
        },

        floatingWindowHeader: {
            marginLeft: '19px',
            marginRight: '19px',
            marginTop: '59px',
            height: '20px',
            fontFamily: 'SFProText-Regular',
            fontWeight: "bold",
            fontSize: '18px',
            lineHeight: '20px',
            letterSpacing: '-0.2px',
            color: '#1E1E1E',
        },


        centerMapButtonsBlock: {
            position: 'absolute',
            //top: ((Dimensions.get('window').height / Dimensions.get('window').width * 320 - 100) / 2) + 'px',
            right: '0px',
            height: '100px',
            alignItems: "flex-end",
            justifyContent: "center"
        },

        bottomMapButtonsBlock: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '65px',
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            paddingLeft: '10px',
            paddingRight: '10px'
        },

        backButton: {
            position: 'absolute',
            top: '47px',
            left: '22px',
            width: '9px',
            height: '16px',
        },

        headerBox: {
            position: 'absolute',
            top: '45px',
            left: '60px',
            right: '60px',
            height: '20px',
            alignItems: "center",
            justifyContent: "center",
        },

        extraHeaderBox: {
            right: '10px',
        },

        header: {
            fontFamily: 'sf-pro-text-bold',
            fontSize: '18px',
            lineHeight: '20px',
            letterSpacing: '-0.2px',
            color: '#000000',
            textAlign: 'center'
        },

        subHeader: {
            marginLeft: '10px',
            marginRight: '10px',
            height: '13px',
            fontFamily: 'sf-pro-text-regular',
            fontSize: '11px',
            lineHeight: '13px',
            letterSpacing: '-0.1px',
            color: '#000000',
            textAlign: 'center'
        },

        topRightListButton: {
            position: 'absolute',
            top: '35px',
            right: '25px',
            width: '28px',
            height: '33px',
        },

        textSearchField: {
            marginLeft: '26px',
            marginRight: '20px',
            marginTop: '20px',
            marginBottom: '30px',
            height: '40px',
        },

        textSearchResults: {
            position: 'absolute',
            top: '70px',
            bottom: '0px',
            left: '10px',
            right: '10px',
            overflow: "hidden"
        },

        scaleIconButton: {
            marginRight: '20px',
            marginBottom: '10px',
            width: '44px',
            height: '44px',
        },

        bottomMenuIconButton: {
            marginRight: '10px',
            marginBottom: '20px',
            width: '44px',
            height: '44px',
        },

        preloaderBox: {
            position: 'absolute',
            top: '20px',
            left: '60px',
            right: '60px',
            height: '50px',
            justifyContent: "center",
            alignItems: "center",
        },

        preloaderImage: {
            width: '50px',
            height: '50px',
        },

    };
