import React from 'react';

export class PreloaderFull extends React.Component {
    render() {
        return (
            <div style={styles.preloaderBox}>
                <img alt="" style={styles.preloaderImage} src={`${process.env.PUBLIC_URL}/_assets/preloader_clocks_fast.gif`} />
                <p style={styles.preloaderLabel}>Пару мгновений...</p>
            </div>
        );
    }
}

export default PreloaderFull;

const styles = {

    preloaderBox: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        textAlign: 'center',
        backgroundColor: '#FFFFFFAA',
    },

    preloaderImage: {
        marginTop: '50%',
        width: '48px',
        height: '52px',
    },

    preloaderLabel: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        fontFamily: 'SFProText-Bold',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        color: '#000000',
        textAlign: "center"
    },
};