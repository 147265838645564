import React from 'react';

import commonStyles from './../Common.styles';
import TextSearchField from '../../components/TextSearchField';
import TextSearchResults from '../../components/TextSearchResults';
import TransportLogic from './TransportLogic';

class TransportHomeScreen extends React.Component {

    state = {
        foundResults: null
    }

    _isMounted = false;

    componentDidMount() {

        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSearchItemPress(item) {
        if (item.route) {
            this.setState({ foundResults: null, }, () => {
                this.props.startRouteMode(item.route.KR_ID[0]);
            })
        }
        if (item.stop) {
            this.setState({ foundResults: null, }, () => {
                this.props.startStopMode(item.stop, true);
            })
        }
    }


    render() {

        //var start = new Date().getTime();

        var retResult = (
            <div style={commonStyles.commonScreen}>
                <div style={commonStyles.screenLayer}>

                    <TextSearchField
                        style={commonStyles.textSearchField}
                        numberOfLines={1}
                        placeholder="поиск"
                        beforeSearch={() => {
                            if (this.state.stop || this.state.transport || this.state.routeInfo) {
                                this.setState({
                                    stop: null,
                                    transport: null,
                                    routeInfo: null
                                });
                            }
                        }}
                        searchMethod={this.props.transportLogic.searchByLocalTransportRefs}
                        afterFound={(foundResults) => {                           
                            if (this._isMounted)
                                this.setState({ foundResults: foundResults });
                        }}
                    />

                    <TextSearchResults
                        style={commonStyles.textSearchResults}
                        foundResults={this.state.foundResults}
                        onItemPress={(item) => {
                            this.handleSearchItemPress(item);
                        }} />

                    <div style={styles.teaserBlock}>
                        <img alt="" style={styles.teaserImage} src={`${process.env.PUBLIC_URL}/_assets/_images/Transport_Teasers_Man.png`} />
                        <p style={styles.teaserHeader}>Выберите остановку или транспортное средство</p>
                        <p style={styles.teaserSubHeader}>Наблюдение за движением городского общественного транспорта в реальном времени.</p>
                        <p style={styles.teaserSubHeader}>Как в Прибывалке.</p>
                    </div>


                    {(this.props.submode === TransportLogic.STOP_MODE) &&

                        <div style={commonStyles.fullFloatingWhiteBack}>
                            <div
                                onClick={(e) => this.props.startBaseMode()}>
                                <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <p style={styles.stopInfoHeaderText}>{"Остановка «" + this.props.stop.title[0] + "»"}</p>
                                <div style={styles.stopInfoSubTextBox}>
                                    <p style={styles.stopInfoSubText}>{this.props.stop.adjacentStreet[0] + ' ' + this.props.stop.direction[0]}</p>
                                    {/*<p style={styles.stopInfoSubText}>Автобусная остановка</p>
                                     <p style={styles.stopInfoSubText}>{distance}</p>*/}
                                </div>
                                {/*<p style={styles.stopInfoSubText}>15 маршрутов</p>*/}
                                {(this.props.stopForecasts) && (this.props.stopForecasts.length > 0) &&
                                    <div style={styles.stopInfoForecastList}>
                                        {this.props.stopForecasts.map(item => (
                                            <div key={item.id} style={styles.stopInfoForecastListItem}
                                                onClick={() => {
                                                    this.props.startTransportMode(item.forecast, true);
                                                }}>
                                                <div style={styles.stopInfoForecastListItemBlackPlate}>
                                                    <p style={styles.stopInfoForecastListItemNumber}>{item.forecast.type + " " + item.forecast.number}</p>
                                                    <div style={styles.stopInfoForecastListItemCircle}>
                                                        <div style={{ backgroundColor: item.plateColor, borderRadius: '16px', height: '18px', width: '18px', }}>
                                                            <img alt="" style={styles.stopInfoForecastListItemIcon} src={item.iconRequire} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p style={styles.stopInfoForecastListItemText}>{"через " + item.forecast.time + ' мин'}</p>

                                                <div style={{ clear: "left" }}></div>
                                            </div>
                                        ))}
                                    </div>
                                }
                                {(this.props.stopForecasts) && (this.props.stopForecasts.length === 0) &&
                                    <p style={styles.stopInfoForecastEmptyMessage}>
                                        {'В ближайшее время транспорта не ожидается'}
                                    </p>
                                }
                            </div>
                        </div>
                    }


                    {(this.props.submode === TransportLogic.TRANSPORT_MODE) && (this.props.transport) &&
                        <div style={commonStyles.fullFloatingWhiteBack}>
                            <div
                                onClick={(e) => this.props.startBaseMode()}>
                                <img alt="" style={commonStyles.closeFloatingWindowButton} src={`${process.env.PUBLIC_URL}/_assets/_icons/Home_ClosePlaceInfoIcon.svg`} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <p style={styles.transportInfoHeaderText}>{this.props.transport.type + " " + (this.props.transport.modelTitle || "")}</p>
                                {false && <div style={styles.transportInfoNumberBlock}>
                                    <p style={styles.transportInfoNumberBlockText}>{this.props.transport.stateNumber}</p>
                                </div>}
                                {(this.props.transport.KR_ID > 0) &&
                                    <div style={styles.transportInfoRouteListHeaderBox}>
                                        <p style={styles.transportInfoRouteListHeaderText}>Далее по маршруту </p>

                                        <div style={styles.stopInfoForecastListItemBlackPlate}>
                                            <p style={styles.stopInfoForecastListItemNumber}>{this.props.transport.number}</p>
                                            <div style={styles.stopInfoForecastListItemCircle}>
                                                <div style={{
                                                    backgroundColor: (this.props.transport.type === 'Трамвай') ? "#BA0000" :
                                                        (this.props.transport.type === 'Троллейбус') ? "#006AB5" :
                                                            "#D46600", borderRadius: '16px', height: '18px', width: '18px',
                                                }}>
                                                    <img alt="" style={styles.stopInfoForecastListItemIcon} src={`${process.env.PUBLIC_URL}/_assets/_icons/Transport_Bus.svg`} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ clear: "left" }}></div>
                                    </div>
                                }
                                {(this.props.transport.KR_ID <= 0) &&
                                    <div style={styles.transportInfoRouteListHeaderBox}>
                                        <p style={styles.transportInfoRouteListHeaderText}>В настоящее время не на маршруте.</p>
                                    </div>
                                }
                                <div style={styles.transportInfoStopsList}>
                                    {this.props.transportForecasts.map(item => (
                                        <div key={item.id} onClick={() => { this.props.startStopMode(item.stop, true); }}>
                                            <div style={styles.transportInfoStopsListItem}>
                                                <div style={styles.transportInfoStopsListBlackPoint}></div>
                                                <p style={styles.transportInfoStopsListName}>{(item.stop ? item.stop.title[0] : "остановка")}</p>
                                                <p style={styles.transportInfoStopsListTime}>{item.forecast}</p>
                                            </div>
                                            <div style={{ clear: "left" }}></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </div >

        );

        //var end = new Date().getTime();
        //var time = end - start;
        //console.log('render time: ' + time);

        return retResult;
    }
}

TransportHomeScreen.navigationOptions = {
    header: null
};

export default TransportHomeScreen;

const styles = {

    extraHeaderBox: {
        right: '10px'
    },

    stopInfoBack: {
        position: 'absolute',
        bottom: '-40px',
        left: '15px',
        right: '15px',
        height: '350px',
        backgroundColor: '#FFFFFF',
        borderRadius: '30px',
    },

    stopInfoHeaderText: {
        marginLeft: '27px',
        marginRight: '14px',
        marginTop: '59px',
        marginBottom: '6px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
    },

    stopInfoSubTextBox: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },

    stopInfoSubText: {
        marginLeft: '27px',
        marginRight: '14px',
        marginTop: '4px',
        marginBottom: '0px',
        color: '#8C8C8C',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
    },

    stopInfoForecastList: {
        marginLeft: '27px',
        marginRight: '14px',
        marginTop: '20px',
        marginBottom: '50px',
        flex: "1",
        overflow: "auto",
        overflowX: "hidden"
    },

    stopInfoForecastEmptyMessage: {
        marginLeft: '27px',
        marginRight: '14px',
        marginTop: '30px',
        marginBottom: '20px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'left',
        opacity: 0.7
    },

    stopInfoForecastListItem: {
        height: '33px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '3px',
        marginBottom: '0px',
        cursor: "pointer",
    },

    stopInfoForecastListItemBlackPlate: {
        float: "left",
        backgroundColor: '#000000',
        borderRadius: '16px',
        height: '16px',
        width: 'auto',
    },

    stopInfoForecastListItemNumber: {
        float: "left",
        color: '#FFFFFF',
        fontFamily: 'SFProText-Regular',
        fontSize: '12px',
        lineHeight: '15px',
        letterSpacing: '-0.1px',
        marginLeft: '7px',
        marginRight: '6px',
        marginTop: '2px',
        marginBottom: '4px',
    },

    stopInfoForecastListItemCircle: {
        float: "left",
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        height: '22px',
        width: '22px',
        border: "2px solid #FFFFFF",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
        marginRight: '-1px',
        marginTop: '-3px',
    },

    stopInfoForecastListItemIcon: {
        height: '11px',
        width: '11px',
        marginLeft: '3px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '3px',
    },

    stopInfoForecastListItemText: {
        float: "left",
        color: '#8C8C8C',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        marginLeft: '7px',
        marginTop: '0px',
    },





    transportInfoHeaderText: {
        marginLeft: '21px',
        marginRight: '20px',
        marginTop: '59px',
        marginBottom: '16px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.1px',
    },

    transportInfoRouteListHeaderBox: {
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
    },

    transportInfoRouteListHeaderText: {
        float: "left",
        marginLeft: '21px',
        marginRight: '20px',
        marginTop: '0px',
        marginBottom: '0px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.1px',
    },

    transportInfoNumberBlock: {
        width: '148px',
        height: '45px',
        paddingTop: '1px',
        marginTop: '5px',
        marginBottom: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderWidth: '1.5px',
        borderColor: '#8C8C8C',
        backgroundColor: '#EDEDFF',
        borderRadius: '5.18px',
        textAlign: "center",
    },

    transportInfoNumberBlockText: {
        paddingTop: '1px',
        marginLeft: '12px',
        marginRight: '12px',
        marginTop: '12px',
        //marginBottom: '12px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '2.146px',
        textAlign: "center",
    },

    transportInfoStopsList: {
        marginLeft: '21px',
        marginRight: '21px',
        marginTop: '5px',
        marginBottom: '50px',
        height: '70px',
    },

    transportInfoStopsListItem:
    {
        height: 'auto',
        marginBottom: '10px',
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        cursor: "pointer",
    },

    transportInfoStopsListBlackPoint: {
        float: "left",
        width: '11px',
        height: '11px',
        marginTop: '13px',
        marginLeft: '0px',
        marginRight: '13px',
        borderWidth: '0px',
        backgroundColor: '#1E1E1E',
        borderRadius: '6px'
    },

    transportInfoStopsListName: {
        float: "left",
        width: '140px',
        marginTop: '10px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
    },

    transportInfoStopsListTime: {
        float: "left",
        width: '64px',
        marginTop: '12px',
        color: '#8C8C8C',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '17px',
        letterSpacing: '-0.1px',
        textAlign: 'right'
    },

    routeInfoBack: {
        position: 'absolute',
        bottom: '-40px',
        left: '15px',
        right: '15px',
        height: '350px',
        backgroundColor: '#FFFFFF',
        borderRadius: '30px',
        alignItems: "center",
    },

    routeInfoHeaderText: {
        marginLeft: '21px',
        marginRight: '20px',
        marginTop: '17px',
        marginBottom: '6px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '18px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
    },

    routeInfoStopsList: {
        marginLeft: '21px',
        marginRight: '21px',
        marginTop: '5px',
        marginBottom: '50px',
        height: '70px',
    },

    routeInfoStopsListItem:
    {
        height: 'auto',
        marginBottom: '10px',
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },

    routeInfoStopsListBlackPoint: {
        width: '11px',
        height: '11px',
        marginTop: '13px',
        marginLeft: '0px',
        marginRight: '13px',
        borderWidth: '0px',
        backgroundColor: '#1E1E1E',
        borderRadius: '6px'
    },

    routeInfoStopsListName: {
        width: '220px',
        marginTop: '10px',
        color: '#1E1E1E',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
    },

    markerBusStop: {
        width: '20px',
        height: '20px',

    },

    clusterContainer: {
        width: 30,
        height: 30,
        padding: 6,
        borderWidth: 1,
        borderRadius: 15,
        alignItems: 'center',
        borderColor: '#65bc46',
        justifyContent: 'center',
        backgroundColor: 'white',
    },

    clusterText: {
        fontSize: 13,
        color: '#65bc46',
        fontWeight: '500',
        textAlign: 'center',
    },


    teaserBlock: {
        textAlign: "center"
    },

    teaserImage: {
        marginTop: '40px',
        height: '107px',
        width: '107px'
    },

    teaserHeader: {
        marginTop: '20px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontSize: '22px',
        lineHeight: '26px',
        letterSpacing: '-0.2px',
        color: '#3A4559',
        textAlign: "center"
    },

    teaserSubHeader: {
        marginTop: '20px',
        marginLeft: '30px',
        marginRight: '30px',
        fontFamily: 'SFProText-Regular',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.2px',
        color: '#3A4559',
        textAlign: "center"
    },
};
