
import React from 'react';

export class TextSearchResults extends React.Component {

    render() {

        if (!this.props.foundResults) {
            return null;
        }

        if (this.props.foundResults.length === 0) {
            return (
                <div style={this.props.style}>
                    <div style={styles.foundListBack} >
                        <p>&nbsp;</p>
                        <p style={styles.emptyListStubMessageText}>Ничего не найдено. Уточните поисковый запрос.</p>
                    </div>
                </div>
            );
        }

        return (
            <div style={this.props.style}>
                <div style={styles.foundListBack}>
                    {this.props.foundResults.map(item => (
                        <div key={item.key}
                            onClick={() => this.props.onItemPress(item)}>
                            <div key={item.key} style={styles.foundListItem}>

                                <div style={styles.foundListItemIcon}>
                                    <img alt="" src={item.iconRequire} />
                                </div>
                                <p style={styles.foundListItemMainText}>{item.name}</p>
                                <div style={{ clear: "left" }} />

                                <p style={styles.foundListItemSubText}>{item.text}</p>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default TextSearchResults;


const styles = {

    foundListBack: {
        minHeight: '150px',
        backgroundColor: '#FAFAFA',
        borderRadius: '20px',
    },

    foundListItem: {
        marginLeft: '22px',
        marginRight: '22px',
        marginTop: '10px',
        marginBottom: '0px',
        width: '250px',
        cursor: "pointer",
    },

    foundListItemIcon: {
        float: "left",
        width: '18px',
        height: '18px',
        margin: '12px',
    },

    foundListItemMainText: {
        float: "left",
        width: "200px",
        marginTop: '12px',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '0px',
        color: '#000000',
        fontFamily: 'SFProText-Regular',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.2px'
    },

    foundListItemSubText: {
        float: "auto",
        color: '#8C8C8C',
        fontFamily: 'SFProText-Regular',
        fontSize: '11px',
        lineHeight: '13px',
        letterSpacing: '-0.1px',
        marginTop: '0px',
        marginLeft: '40px',
        marginRight: '0px',
        marginBottom: '0px',
        width: '220px',
    },

    emptyListStubMessageText: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '17px',
        marginRight: '17px',
        fontFamily: 'SFProText-Regular',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#2D2D2D',
        textAlign: 'center',
        opacity: 0.7
    },


};
